import { useEffect, useState, useReducer, useContext } from "react";
import "../css/mapBody.css";
import { GoogleMap, useLoadScript, Marker, MarkerClusterer } from "@react-google-maps/api";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import EVIcon from "../../../assets/EV_Sample.svg";
import MerchantIcon from "../../../assets/Merchant_Sample.svg";
import ClustererIcon from "../../../assets/MarkerClusterer.png";
import GetNearbyMerchants from "../../../functions/GetNearbyMerchants";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";

import SelectMerchant from "../../../functions/SelectMerchant";
import GetCurrentLocation from "../../../functions/GetCurrentLocation";

import { Box, Chip, Typography } from "@mui/material";

import { renderTheme } from "../../../App";

const MapBody = (props) => {
	const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyB03iyW7pYkkVLBIAj_n0oVbJDGTEi38sM" });
	const [AccessToken, setAccessToken] = useState();
	const [UserPosition, setUserPosition] = useState();
	const [MapCenter, setMapCenter] = useState();
	const [MerchantList, setMerchantList] = useState();
	const [IsLoaded, setIsLoaded] = useState(false);

	const { toggleActiveFilters, ActiveFilters } = useContext(renderTheme);

	useEffect(() => {
		setUserPosition(props.onUserPosition);
		setMapCenter(props.onUserPosition);
	}, []);

	useEffect(() => {
		if (props.onFilteredData !== undefined) {
			setMerchantList(props.onFilteredData);
		} else {
			if (UserPosition !== undefined) {
				if (props.onIfLoggedIn) {
					console.log("[Is_Logged_In]", props.onIfLoggedIn);
					const saved_refreshToken = localStorage.getItem("refresh_token");
					if (saved_refreshToken !== null) {
						let refreshToken = DecryptData("refresh_token");
						let request = TokenCheck(refreshToken);
						request.then((data) => {
							if (data.status === "PASSED") {
								let savedToken = localStorage.getItem("access_token");

								if (savedToken !== null) {
									let reqToken;
									if (data.action === "REFRESHED") {
										reqToken = data.access_token;
									} else {
										reqToken = DecryptData("access_token");
									}
									setAccessToken(reqToken);
									console.log("[ACCESS TOKEN] ====================> ", reqToken);
									setIsLoaded(false);
									if (props.onWhatModal === "modal_loading") {
										props.onModalVisibility(false, "");
									}
								}
							}
						});
					}
				} else {
					let request = GetNearbyMerchants(
						"Basic " + process.env.REACT_APP_API_KEY,
						UserPosition.lng,
						UserPosition.lat,
						"guest_user"
					);
					if (!IsLoaded) {
						props.onModalVisibility(true, "modal_loading");
					}

					request.then((res) => {
						setMerchantList(null);
						if (res.errors === undefined) {
							setIsLoaded(true);
							setMerchantList(res.data.locations);
							props.onModalVisibility(false, "");
						} else {
							console.log("Got some error/s.");
						}
					});
				}
			}
		}
	}, [UserPosition, props.onFilteredData]);

	useEffect(() => {
		if (AccessToken !== undefined) {
			console.log(props.onWhatActiveCategory);
			if (props.onWhatActiveCategory !== undefined || props.onCategoryMerchantList !== "") {
				console.log(props.onCategoryMerchantList);
				setMerchantList(props.onCategoryMerchantList);
			} else {
				let request = GetNearbyMerchants(
					"Bearer " + AccessToken,
					props.onUserPosition.lng,
					props.onUserPosition.lat,
					"registered_user"
				);

				if (!IsLoaded) {
					props.onModalVisibility(true, "modal_loading");
				}

				request.then((res) => {
					setMerchantList(null);
					if (res.errors === undefined) {
						setIsLoaded(true);
						setMerchantList(res.data.location_with_favorites);
						props.onModalVisibility(false, "");
					} else {
						console.log("Got some error/s.");
					}
				});
			}
		}
	}, [AccessToken, props.onCategoryMerchantList]);

	useEffect(() => {
		console.log(MerchantList);
	}, [MerchantList]);

	const toggleFilterSelection = () => {
		props.onModalVisibility(true, "modal_choosefilter");
	};

	function toggleMarker(e, position, location_id = undefined) {
		console.log(position, location_id);
		if (UserPosition === position) {
			let posLat = position.lat + 0.0001;
			let posLng = position.lng + 0.0001;
			setMapCenter({ lat: posLat, lng: posLng });
		} else {
			setMapCenter(position);
		}
		if (location_id !== undefined) {
			let reqLocation = GetCurrentLocation();
			reqLocation.then((data_reqLocation) => {
				const saved_refreshToken = localStorage.getItem("refresh_token");
				if (saved_refreshToken !== null) {
					let refreshToken = DecryptData("refresh_token");

					let TCRequest = TokenCheck(refreshToken);
					TCRequest.then((data) => {
						if (data.status === "PASSED") {
							let savedToken = localStorage.getItem("access_token");
							if (savedToken !== null) {
								let reqToken;
								if (data.action === "REFRESHED") {
									reqToken = data.access_token;
								} else {
									reqToken = DecryptData("access_token");
								}
								let request = SelectMerchant(
									reqToken,
									location_id,
									data_reqLocation.latitude,
									data_reqLocation.longitude
								);
								request.then((data1) => {
									props.onMerchantSelection({
										name: data1.data.location_with_favorite.name,
										address: data1.data.location_with_favorite.address,
										distanceAway: data1.data.location_with_favorite.distance.toFixed(2) + "km away",
										stations: data1.data.location_with_favorite.evses,
										facilities: data1.data.location_with_favorite.facilities,
										parking_types: data1.data.location_with_favorite.parking_types,
										parking_restrictions: data1.data.location_with_favorite.parking_restrictions,
										merchantID: data1.data.location_with_favorite.id,
										favorite: data1.data.location_with_favorite.favorite,
										address_lat: data1.data.location_with_favorite.address_lat,
										address_lng: data1.data.location_with_favorite.address_lng,
									});
									props.onModalVisibility(true, "modal_loading");
									setTimeout(() => {
										props.onModalVisibility(true, "modal_merchant");
									}, 1000);
								});
							}
						}
					});
				}
			});
		}
	}

	useEffect(() => {
		if (props.onMerchantMarker !== 0) {
			toggleMarker(undefined, props.onMerchantMarker);
		}
	}, [props.onMerchantMarker]);

	if (!isLoaded) return <div style={{ backgroundColor: "#E5E3DF", height: "100%" }}>Loading...</div>;

	return (
		<div id='home_mapbody'>
			<button
				className='btn btn-user-location'
				title='Your Location'
				data-marker={"user_marker"}
				onClick={(event) => {
					toggleMarker(event, UserPosition);
				}}>
				<MyLocationIcon style={{ pointerEvents: "none" }} />
			</button>
			{ActiveFilters !== undefined && (
				<Box
					onClick={toggleFilterSelection}
					sx={{
						position: "absolute",
						left: 0,
						zIndex: 10,
						padding: "0.5% 1% .5% 1%",
						backgroundColor: "#1a6fff",
						width: "100%",
						display: "flex",
						alignItems: "center",
						gap: { xs: 0, lg: 2 },
						padding: { xs: "8px" },
					}}>
					<Typography sx={{ color: "white" }}>Charger Locations Filtered by:</Typography>
					{/* POWER TYPES */}
					{ActiveFilters.power_types.length > 0 && (
						<Chip
							sx={{ width: "max-content", color: "white" }}
							label={
								<>
									<Typography
										variant='caption'
										sx={{ fontWeight: "bold" }}>
										Power Types:&nbsp;
									</Typography>
									<Typography variant='caption'>
										{ActiveFilters.power_types.map((type) => type.replace(/_/g, " ")).join(", ")}
									</Typography>
								</>
							}
							variant='outlined'
						/>
					)}
					{/* CONNECTOR TYPES */}
					{ActiveFilters.connector_types.length > 0 && (
						<Chip
							sx={{ width: "max-content", color: "white" }}
							label={
								<>
									<Typography
										variant='caption'
										sx={{ fontWeight: "bold" }}>
										Connector Types:&nbsp;
									</Typography>
									<Typography variant='caption'>
										{ActiveFilters.connector_types.map((type) => type.replace(/_/g, " ")).join(", ")}
									</Typography>
								</>
							}
							variant='outlined'
						/>
					)}
					{/* FACILITIES */}
					{ActiveFilters.facilities.length > 0 && (
						<Chip
							sx={{ width: "max-content" }}
							label={
								<>
									<Typography
										variant='caption'
										sx={{ fontWeight: "bold" }}>
										Facilities:&nbsp;
									</Typography>
									<Typography variant='caption'>
										{ActiveFilters.facilities.map((type) => type.replace(/_/g, " ")).join(", ")}
									</Typography>
								</>
							}
							variant='outlined'
						/>
					)}
				</Box>
			)}

			<Map
				onCenterPosition={MapCenter}
				onToggleMarker={toggleMarker}
				onUserMarkerVisibility={props.onUserMarker}
			/>
		</div>
	);

	// 14.559999022307489, 121.02645128031159
	function Map(props) {
		const mapOptions = {
			fullscreenControl: false,
			disableDefaultUI: true,
			gestureHandling: "greedy",
			clickableIcons: false,
		};

		let map = (
			<GoogleMap
				zoom={16}
				center={props.onCenterPosition}
				mapContainerClassName='map-container'
				options={mapOptions}>
				{props.onUserMarkerVisibility !== undefined && (
					<Marker
						position={UserPosition}
						label={{ text: "You", color: "white", className: "marker-user-label" }}
						onClick={(event) => props.onToggleMarker(event, UserPosition)}
						icon={EVIcon}
						title='This is you.'
					/>
				)}
				<MarkerClusterer
					averageCenter
					enableRetinaIcons
					styles={[{ height: 75, url: ClustererIcon, width: 60, textSize: 16, anchorText: [-25, 16.75] }]}>
					{(clusterer) =>
						MerchantList?.map((data) => {
							return (
								<Marker
									onClick={(event) =>
										props.onToggleMarker(
											event,
											{ lat: Number(data.address_lat), lng: Number(data.address_lng) },
											data.id
										)
									}
									clusterer={clusterer}
									position={{ lat: Number(data.address_lat), lng: Number(data.address_lng) }}
									icon={MerchantIcon}
									title={data.name}
									label={{ text: data.name, color: "white", className: "marker-location-label" }}
								/>
							);
						})
					}
				</MarkerClusterer>
			</GoogleMap>
		);

		return map;
	}
};

export default MapBody;
