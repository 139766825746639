import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App'; // Import the App component
import TransactionManager from './pages/PaymentMethod/TransactionManager';
import './index.css';

if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Router>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route path="/webview" element={<App />} /> {/* Reuse the App component */}
      <Route path="/:userType/:userID/:token/:transactID" element={<TransactionManager />} />
    </Routes>
  </Router>
  // </React.StrictMode>
);
