const GetFavMerchants = async (AccessToken, Longitude, Latitude) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);

    var graphql = JSON.stringify({
        query: `query Favorite_locations {\r\n    favorite_locations(lat: ${Latitude}, lng: ${Longitude}) {\r\n        id\r\n        name\r\n        address\r\n        address_lat\r\n        address_lng\r\n        city\r\n        date_created\r\n        date_modified\r\n        distance\r\n        favorite\r\n        evses {\r\n            uid\r\n            evse_id\r\n            serial_number\r\n            meter_type\r\n            status\r\n            cpo_location_id\r\n            current_ws_connection_id\r\n            server_id\r\n            date_created\r\n            connectors {\r\n                id\r\n                evse_uid\r\n                connector_id\r\n                standard\r\n                format\r\n                max_voltage\r\n                max_amperage\r\n                max_electric_power\r\n                status\r\n                date_created\r\n                date_modified\r\n            }\r\n        }\r\n    }\r\n}\r\n`,
        variables: {}
    })
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
            requestOptions
        );

        return response.json();
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default GetFavMerchants;