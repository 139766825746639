const GetCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            console.log("HERE1!");
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log("HERE2!");
                    resolve({ latitude, longitude });

                },
                (error) => {
                    console.log("HERE3!");
                    console.error("Geolocation error:", error.message);
                    reject(error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: Infinity
                }
            );
        } else {
            const error = new Error("Geolocation is not supported by this browser.");
            console.error(error.message);
            reject(error);
        }
    });
};

export default GetCurrentLocation;
