const SetAsFav = async (AccessToken, MerchantID) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);
    console.log(MerchantID)
    var graphql = JSON.stringify({
        query: `mutation AddToFavoriteLocation {\r\n    addToFavoriteLocation(cpo_location_id: "${MerchantID}") {\r\n        user_id\r\n        cpo_location_id\r\n         status\r\n   }\r\n}\r\n`,
        variables: {}
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
            requestOptions
        );

        return response.json();
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default SetAsFav;