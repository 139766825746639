import { useState, useEffect, useContext, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RectangleIcon from "@mui/icons-material/Rectangle";
import CircleIcon from "@mui/icons-material/Circle";
import BoltIcon from "@mui/icons-material/Bolt";

import GetTimeslots from "../../../../functions/GetTimeslots";
import CheckTimeslot from "../../../../functions/CheckTimeslot";
import TokenCheck from "../../../../functions/TokenCheck";
import DecryptData from "../../../../functions/DecryptData";
import ActivateCharger from "../../../../functions/ActivateCharger";
import GetCurrentLocation from "../../../../functions/GetCurrentLocation";
import GetReservationList from "../../../../functions/GetReservationList";
import CancelExpiredReservations from "../../../../functions/CancelExpiredReservations";
import { renderTheme } from "../../../../App";

import "../../css/modal.css";
import { set } from "lodash";
import { Box, Button } from "@mui/material";

const StationListAccordion = (props) => {
	const [expanded, setExpanded] = useState(false);
	const [StationList, setStationList] = useState();

	const [ConnectorTypes, setConnectorTypes] = useState();
	const [SlotList, setSlotList] = useState();

	const [IsChargersOnline, setIsChargerOnline] = useState(false);
	const [IsChargersOffline, setIsChargerOffline] = useState(false);
	const [InquireRemark, setInquireRemark] = useState("");
	const [Timeslot_RemainingTime, setTimeslot_RemainingTime] = useState();
	const [ReservationOwnership, setReservationOwnership] = useState();
	const [SelectedEVSE, setSelectedEVSE] = useState();

	// RENDER THEME
	const { RenderType, toggleStatusChange, setChargingReservationDetails, isMobile } = useContext(renderTheme);

	const expandTimeout = useRef(null);
	// let expandTimeout = null;

	useEffect(() => {
		if (props.onStationList) {
			setStationList(props.onStationList);
		}
	}, [props.onStationList]);

	useEffect(() => {
		if (StationList !== undefined) {
			const availableCharger = StationList.filter((data) => {
				return isOnline(data.status);
			});

			const notAvailableCharger = StationList.filter((data) => {
				return isOffline(data.status);
			});

			if (availableCharger.length > 0) {
				setIsChargerOnline(true);
			} else {
				setIsChargerOnline(false);
			}

			console.log(notAvailableCharger.length);
			if (notAvailableCharger.length > 0) {
				setIsChargerOffline(true);
			} else {
				setIsChargerOffline(false);
			}

			console.log(StationList.connectors);
			console.log("STATION LIST:", StationList);
		}
	}, [StationList]);

	useEffect(() => {
		if (SlotList !== null && SlotList !== undefined && Object.keys(SlotList).length > 0) {
			console.log("[Slot List]:", SlotList);
		}
	}, [SlotList]);

	useEffect(() => {
		console.log("[EXPAND TIMEOUT]:", expandTimeout.current);
	}, [expandTimeout.current]);

	const isOnline = (charger) => {
		return charger === "ONLINE";
	};

	const isOffline = (charger) => {
		return charger === "OFFLINE";
	};

	function isUnderAnHourLeft(specifiedTime, specifiedDate, startTime) {
		// Get current date and time
		const currentTime = new Date();

		// Parse the specified date and time strings
		const [specifiedYear, specifiedMonth, specifiedDay] = specifiedDate.split("-").map(Number);
		let [specifiedHours, specifiedMinutes, specifiedSeconds] = specifiedTime.split(":").map(Number);
		const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);

		if (specifiedHours < startHours) {
			specifiedHours += 24;
		}
		// Create a date object for the specified date and time
		const specifiedTimeObj = new Date(
			specifiedYear,
			specifiedMonth - 1,
			specifiedDay,
			specifiedHours,
			specifiedMinutes,
			specifiedSeconds
		);

		// Calculate the difference in milliseconds between the current time and the specified time
		const timeDifference = specifiedTimeObj - currentTime;

		// Convert the time difference from milliseconds to minutes
		const minutesDifference = timeDifference / (1000 * 60);

		// Check if the difference is less than 60 minutes
		return minutesDifference < 60;
	}

	const getCurrentTimeWithSeconds = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		const seconds = now.getSeconds().toString().padStart(2, "0");

		return `${hours}:${minutes}:${seconds}`;
	};

	const GetCurrentDate = () => {
		const dateObject = new Date();

		// Get date components
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
		const day = dateObject.getDate().toString().padStart(2, "0");

		// Formatted date
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	const toggleChargerStart = (merchantID, chargerID, connectorID, type, requestToken) => {
		if (type === "inquire") {
			let currentDate = GetCurrentDate();
			let currentTime = getCurrentTimeWithSeconds();

			let reqLocation = GetCurrentLocation();

			reqLocation.then((data_reqLocation) => {
				let request = ActivateCharger(
					requestToken,
					merchantID,
					chargerID,
					type,
					data_reqLocation.latitude,
					data_reqLocation.longitude,
					currentTime,
					currentDate,
					connectorID
				);
				request.then((res) => {
					setInquireRemark(res.data.remarks);
				});
			});
		} else {
			const saved_refreshToken = localStorage.getItem("refresh_token");
			if (saved_refreshToken !== null) {
				let refreshToken = DecryptData("refresh_token");
				let request = TokenCheck(refreshToken);
				request.then((data) => {
					if (data.status === "PASSED") {
						let savedToken = localStorage.getItem("access_token");

						if (savedToken !== null) {
							let reqToken;
							if (data.action === "REFRESHED") {
								reqToken = data.access_token;
							} else {
								reqToken = DecryptData("access_token");
							}
							let currentDate = GetCurrentDate();
							let currentTime = getCurrentTimeWithSeconds();

							let reqLocation = GetCurrentLocation();

							reqLocation.then((data_reqLocation) => {
								let request = ActivateCharger(
									reqToken,
									merchantID,
									chargerID,
									type,
									data_reqLocation.latitude,
									data_reqLocation.longitude,
									currentTime,
									currentDate,
									connectorID
								);
								request.then((res) => {
									if (type === "activate") {
										console.log("ACTIVATING >>>>>>", res.data.remarks);
										if (res.data.remarks === "SUCCESS") {
											toggleStatusChange("USER_CHARGING");
										}
										setExpanded(false);
										handleChange(data.evse_code, chargerID, merchantID);
									}
									setInquireRemark(res.data.remarks);
								});
							});
						}
					}
				});
			}
		}
	};

	const CheckCancelExpired = () => {
		let request = CancelExpiredReservations();
		request.then((res) => {
			console.log(res);
		});
	};

	const groupDataByConnectorType = (data) => {
		const groupedData = {};

		// Iterate over each array index
		data.forEach((item) => {
			// Extract connector_type from each item
			const connectorType = item.connector_type;

			// If the connector_type is not already a key in groupedData, initialize it as an empty array
			if (!groupedData[connectorType]) {
				groupedData[connectorType] = [];
			}

			// Push the item into the corresponding array based on connector_type
			groupedData[connectorType].push(item);
		});

		return groupedData;
	};

	const fetchAllTimeslots = async (merchantID, evc_id, connectors = undefined) => {
		// Create an array of promises for each combination of merchantID and evcID
		const promises = [];

		const saved_refreshToken = localStorage.getItem("refresh_token");

		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			let request = TokenCheck(refreshToken);
			const result = await request.then(async (data) => {
				let reqToken;
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");

					if (savedToken !== null) {
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}
					}

					if (connectors !== undefined) {
						connectors.map((data) => {
							if (data.connector_id !== null) {
								promises.push(GetTimeslots(merchantID, evc_id, data.connector_id));
								toggleChargerStart(merchantID, evc_id, data.connector_id, "inquire", reqToken);
							}
						});
					}

					let request1 = GetReservationList(reqToken);
					setChargingReservationDetails();
					request1.then((res) => {
						console.log(res);
						setChargingReservationDetails(res.data);
					});

					try {
						setSlotList("isLoading");
						// Wait for all promises to resolve
						const results = await Promise.all(promises);
						console.log("All timeslots fetched:", results);
						return results;
					} catch (error) {
						console.error("Error fetching timeslots:", error);
						throw error; // Re-throw the error to handle it in the caller
					}
				}
			});

			return result;
		}
	};

	const handleChange =
		(panel, evc_id, merchantID, connectors) =>
		(
			event,
			isExpanded,
			ref_panel = undefined,
			ref_evc_id = undefined,
			ref_merchantID = undefined,
			ref_ev_connectors = undefined
		) => {
			if (expandTimeout.current !== null) {
				clearTimeout(expandTimeout.current);
				expandTimeout.current = null;
			}

			CheckCancelExpired();
			if (isExpanded) {
				const groupedData = {};
				const request = fetchAllTimeslots(
					merchantID !== undefined ? merchantID : ref_merchantID !== undefined ? ref_merchantID : props.onMerchantID,
					ref_evc_id !== undefined ? ref_evc_id : evc_id,
					ref_ev_connectors !== undefined ? ref_ev_connectors : connectors
				);
				console.log(request);
				request.then((data) => {
					let responseData = data;
					console.log("[RESPONSE DATA]:", responseData);
					for (const key in responseData) {
						if (responseData.hasOwnProperty(key)) {
							groupedData[key] = groupDataByConnectorType(responseData[key].data);
						}
					}
					console.log(groupedData);

					setSlotList();
					setSlotList(groupedData);
				});
			} else {
				setSlotList();
			}
			if (ref_panel !== undefined) {
				setExpanded(ref_panel);
			} else {
				setExpanded(isExpanded ? panel : false);
			}
			if (expandTimeout.current !== null) {
				clearTimeout(expandTimeout.current);
				expandTimeout.current = null;
			}

			expandTimeout.current = setTimeout(() => {
				setExpanded(false);
				clearTimeout(expandTimeout.current);
				expandTimeout.current = null;
			}, 60000);
		};

	const toggleExpanded = (data) => {
		setExpanded(data);
	};

	const handleChargeListItemClick = (itemDetails, action = undefined, EVConnectors) => {
		if (itemDetails.status === "ONLINE") {
			if (expandTimeout.current !== null) {
				clearTimeout(expandTimeout.current);
				expandTimeout.current = null;
			}

			props.onSubModalVisibility(
				true,
				"submodal_res_confirm",
				{ itemDetails, EVConnectors },
				undefined,
				handleChange,
				toggleExpanded,
				props.onMerchantID
			);
		} else if (itemDetails.status === "RESERVED") {
			if (expandTimeout.current) {
				clearTimeout(expandTimeout.current);
				expandTimeout.current = null;
			}
			const saved_refreshToken = localStorage.getItem("refresh_token");
			if (saved_refreshToken !== null) {
				let refreshToken = DecryptData("refresh_token");
				let request = TokenCheck(refreshToken);
				request.then((data) => {
					if (data.status === "PASSED") {
						let savedToken = localStorage.getItem("access_token");
						if (savedToken !== null) {
							let reqToken;
							if (data.action === "REFRESHED") {
								reqToken = data.access_token;
							} else {
								reqToken = DecryptData("access_token");
							}
							let checkRequest = CheckTimeslot(
								reqToken,
								itemDetails.chargerID,
								itemDetails.timeslotID,
								itemDetails.connectorID
							);
							checkRequest.then((res) => {
								console.log("RES:", res);
								setReservationOwnership(res.data);
								action();
								if (res.data.STATUS === "OWNED_RESERVATION") {
									let reservationDetails = {
										reservationID: res.data.reservation_id,
										grace_period: res.data.grace_period,
									};
									props.onSubModalVisibility(
										true,
										"submodal_res_confirm",
										{
											itemDetails,
											EVConnectors,
										},
										{ reservationDetails },
										handleChange,
										toggleExpanded,
										props.onMerchantID
									);
								}
							});
						}
					}
				});
			}
		}
	};

	const toTime = (time) => {
		let whatTime = time.replaceAll(":00", "");
		let ap = whatTime >= 12 ? "PM" : "AM";
		let hours = whatTime % 12;

		hours = hours ? hours : 12;
		hours = hours.toString().padStart(2, "");
		let mergeTime = hours + " " + ap;
		return mergeTime;
	};

	// TIME | DATE | TYPE
	const ChargingListItem = (props) => {
		const [SlotStatus, setSlotStatus] = useState();
		const [SlotTime, setSlotTime] = useState();
		const [SlotDate, setSlotDate] = useState();
		const [StatusClass, setStatusClass] = useState();
		const [open, setOpen] = useState(false);

		const handleTooltipClose = () => {
			setOpen(false);
		};

		const handleTooltipOpen = () => {
			setOpen(true);
		};

		useEffect(() => {
			if (props.itemDetails) {
				let slot_Status = "slot-details-time-date " + props.itemDetails.status.toLowerCase().replace(" ", "-");
				const slot_Time = toTime(props.itemDetails.start) + " - " + toTime(props.itemDetails.end);
				const currentDate = new Date(props.itemDetails.date);
				// const slotDate = props.itemDetails.date;

				// Get month and day
				const month = currentDate.toLocaleString("default", { month: "long" });
				const day = currentDate.getDate();

				if (
					props.itemDetails.status === "ONLINE" &&
					isUnderAnHourLeft(props.itemDetails.end, props.itemDetails.date, props.itemDetails.start)
				) {
					slot_Status = "slot-details-time-date blocked";
					setSlotStatus("BLOCKED");
				} else {
					setSlotStatus(props.itemDetails.status);
				}
				setStatusClass(slot_Status);
				setSlotTime(slot_Time);
				setSlotDate(month + " " + day);
			}
			console.log(props.itemDetails);
		}, [props.itemDetails]);

		// useEffect(() => {
		// 	if (open) {
		// 		setTimeout(() => {
		// 			handleTooltipClose();
		// 		}, 3000);
		// 	}
		// }, [open]);

		return (
			<Tooltip
				title={
					SlotStatus === "CHARGING"
						? "This charger/timeslot is currently in use."
						: SlotStatus === "BLOCKED"
						? "Current timeslot has less than an hour remaining. Either use the 'Charge Now' option or reserve the next available timeslot."
						: ReservationOwnership === "NOT_OWNED_RESERVATION"
						? "Someone already reserved this timeslot."
						: ""
				}
				placement='bottom'
				disableFocusListener
				disableHoverListener
				disableTouchListener
				onClose={handleTooltipClose}
				open={open}
				arrow>
				<span>
					<ListItem
						className={SlotStatus === "BLOCKED" ? "charging-slot-item mb-1 blocked" : "charging-slot-item mb-1 "}
						onClick={() => {
							if (SlotStatus === "OFFLINE" || SlotStatus === "BLOCKED") {
								return;
							} else {
								handleChargeListItemClick(props.itemDetails, handleTooltipOpen, props.EVConnectors);
							}
						}}
						sx={{
							borderLeft:
								SlotStatus === "CHARGING"
									? "solid 8px #1a6fff"
									: SlotStatus === "RESERVED"
									? "solid 8px orange"
									: SlotStatus === "OFFLINE"
									? "solid 8px gray"
									: SlotStatus === "BLOCKED"
									? "solid 8px #121212"
									: "solid 8px green",
						}}
						onMouseLeave={handleTooltipClose}
						onMouseOver={SlotStatus === "CHARGING" || SlotStatus === "BLOCKED" ? handleTooltipOpen : handleTooltipClose}
						// onMouseOver={SlotStatus === "CHARGING" && setOpen(true)}
						disabled={SlotStatus === "ONLINE" || SlotStatus === "RESERVED" ? false : true}>
						{SlotStatus === "BLOCKED" ? (
							<div style={{ overflow: "hidden" }}>
								<span class='moving-text'>
									Current timeslot has less than an hour remaining. Either use the 'Charge Now' option or reserve the
									next available timeslot.
								</span>
							</div>
						) : (
							<div className={StatusClass}>
								<div className='slot-schedule col-7 text-nowrap'>
									<span>{SlotDate}</span>
									<span>
										,&nbsp;
										{props.itemDetails.timeslotType === "Open Timeslot" ? <strong>OPEN TIMESLOT</strong> : SlotTime}
									</span>
								</div>
								{/* <span
									className='slot-status'
									style={{ overflow: "hidden", width: "100%", marginLeft: "8px" }}>
									<span class='moving-text'>{SlotStatus}</span>
								</span> */}

								<ArrowRightIcon />
							</div>
						)}
					</ListItem>
				</span>
			</Tooltip>
		);
	};

	const ChargeNowItem = ({ data }) => {
		const [open, setOpen] = useState(false);

		const handleTooltipClose = () => {
			setOpen(false);
		};

		const handleTooltipOpen = () => {
			setOpen(true);
		};

		return (
			<Tooltip
				disableFocusListener
				disableHoverListener
				disableTouchListener
				onClose={handleTooltipClose}
				open={open}
				title={
					InquireRemark !== "CHARGER_AVAILABLE" && "Check your balance or make sure that your location is accurate."
				}>
				<span
					style={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					onMouseLeave={handleTooltipClose}
					onMouseOver={InquireRemark !== "CHARGER_AVAILABLE" ? handleTooltipOpen : handleTooltipClose}>
					<Button
						disabled={InquireRemark === "CHARGER_AVAILABLE" ? false : true}
						variant='contained'
						// fullWidth
						sx={{
							// borderRadius: "0 0 4px 4px",
							width: "75%",
							// marginTop: "8px",
							// marginBottom: "4px",
							borderRadius: "16px",
						}}
						onClick={
							() => props.onModalVisibility(true, "modal_qrscanner")
							// 	props.onSubModalVisibility(true, "submodal_charge_now", {
							// 	EVConnectors: data.connectors,
							// 	LocationID: data.cpo_location_id,
							// })
						}
						endIcon={<ElectricBoltIcon />}>
						Charge Now
					</Button>
				</span>
			</Tooltip>
		);
	};

	return (
		<div id='station_list'>
			<div className='py-2'>
				<span className='slot-list-header'>Choose from Available Charger/s:</span>
				<div
					className='d-flex mt-2 mb-1 flex-wrap gap-2'
					style={{
						boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
						justifyContent: !isMobile && "center",
						padding: "8px",
					}}>
					<div className='text-center d-flex align-items-center'>
						<RectangleIcon
							sx={{ color: "green" }}
							fontSize='8px'
						/>
						<span>Online</span>
					</div>
					<div className='text-center d-flex align-items-center'>
						<RectangleIcon
							sx={{ color: "#1a6fff" }}
							fontSize='8px'
						/>
						<span>Charging</span>
					</div>
					<div className='text-center d-flex align-items-center'>
						<RectangleIcon
							sx={{ color: "orange" }}
							fontSize='8px'
						/>
						<span>Reserved</span>
					</div>
					<div className='text-center d-flex align-items-center'>
						<RectangleIcon
							sx={{ color: "gray" }}
							fontSize='8px'
						/>
						<span>Offline</span>
					</div>
				</div>
				<hr className='hr-line' />
				{StationList !== undefined &&
					StationList.map((data, index) => {
						const uniqueStandards = new Set();

						// Iterate over the array of objects
						data.connectors.forEach((item) => {
							// Extract the value of the "standard" key and add it to the Set
							uniqueStandards.add(item.standard);
						});

						// Convert the Set to an array to obtain the unique values
						const uniqueStandardsArray = Array.from(uniqueStandards);
						const displayConnectorType = uniqueStandardsArray.join(", ");
						console.log(uniqueStandardsArray);

						return (
							<Accordion
								sx={{ width: "100%", marginBottom: "4px" }}
								expanded={expanded === data.evse_code}
								onChange={handleChange(data.evse_code, data.uid, data.cpo_location_id, data.connectors)}>
								<AccordionSummary
									sx={{
										borderLeft: data.status === "ONLINE" ? "solid 12px green" : "solid 12px gray",
										// borderRight: data.status === "ONLINE" ? "solid 4px green" : "solid 4px gray",
										padding: "unset!important",
										paddingRight: "8px!important",
										boxShadow:
											"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
									}}
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1bh-content'
									id='panel1bh-header'>
									<div className='triangle'>
										<div class='content'>{index + 1}.</div>
									</div>
									<div className='slot-accordion-item-summary'>
										<div className='item-summary-level'>
											<Typography
												className='text-nowrap fw-bolder fs-5'
												sx={{ width: "33%", flexShrink: 0 }}>
												{data.evse_code}
											</Typography>
											{/* <Typography
														className='fst-italic'
														sx={{ color: "text.secondary" }}>
														{data.available_timeslots}
													</Typography> */}
										</div>
										{/* <Typography className='fw-light text-end station-charge-price'>
													<span>
														<span className='text-decoration-line-through text-secondary fs-6'>&#8369;500</span>&nbsp;
														<span className='fs-5 fw-bold'>&#8369;450</span>
													</span>
												</Typography> */}
										<div className='item-summary-details gap-1 flex-wrap'>
											<Typography
												className='fw-light'
												style={{ whiteSpace: "nowrap" }}>
												<Chip
													label={data.meter_type}
													size='small'
												/>
											</Typography>
											<Typography
												className='fw-light'
												style={{ whiteSpace: "nowrap" }}>
												<Chip
													label={displayConnectorType.replace("_", " ")}
													size='small'
												/>
												{/* {displayConnectorType},&nbsp; */}
											</Typography>
											<Typography className='fw-light'>
												<Chip
													label='7 KW-H'
													size='small'
												/>
											</Typography>
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails
									className='border'
									sx={{ marginTop: "8px", padding: "8px", borderRadius: "16px" }}>
									<List
										component='nav'
										aria-label='mailbox folders'>
										{expanded === data.evse_code &&
										SlotList !== null &&
										SlotList !== undefined &&
										SlotList !== "isLoading" &&
										Object.keys(SlotList).length > 0 ? (
											Object.entries(SlotList).map(([connectorType, innerObject], index) => {
												const indexToLetter = (index) => {
													// Convert index to letter using ASCII code
													return String.fromCharCode(65 + index);
												};
												return (
													// Iterate over each key-value pair in the innerObject
													Object.entries(innerObject).map(([key, values]) => {
														// Loop through the values and display their contents
														const list = (
															<Box
																className='mb-2'
																sx={{
																	boxShadow: " 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);",
																	padding: "8px",
																	borderRadius: "8px",
																}}>
																<Box
																	className='d-flex gap-3'
																	sx={{ transform: "translate(10px, 10px)!important" }}>
																	<div className='d-flex justify-content-center align-items-center mb-2'>
																		<RectangleIcon
																			sx={{ position: "absolute" }}
																			fontSize='large'
																		/>
																		<span
																			className='fw-bold'
																			style={{ color: "white", zIndex: 2 }}>
																			{indexToLetter(index)}
																		</span>
																	</div>
																	<span className='fw-bold'>{key.replace("_", " ")}</span>
																</Box>
																<Box>
																	<Typography
																		variant='subtitle2'
																		sx={{ textAlign: "center", marginY: "8px", fontWeight: "bold" }}>
																		Choose a timeslot below:
																	</Typography>
																	{values.map((res, currentIndex, array) => {
																		const nextIndex = currentIndex + 1;
																		const nextItem = nextIndex < array.length ? array[nextIndex] : array[0];
																		console.log("[X]:", res);
																		return (
																			<ChargingListItem
																				disabled
																				itemDetails={{
																					date: res.date,
																					start: res.start,
																					end: res.end,
																					status: res.slot_status,
																					slotName: data.evse_code,
																					chargerID: res.evse_uid,
																					locationID: data.cpo_location_id,
																					timeslotID: res.timeslot_id,
																					connectorType: res.connector_type,
																					connectorID: res.connector_id,
																					powerType: res.power_type,
																					nextTimeslotID: nextItem ? nextItem.timeslot_id : null,
																					nextTimeslotDate: nextItem ? nextItem.date : null,
																					timeslotType: res.description,
																				}}
																				EVConnectors={data.connectors}
																			/>
																		);
																	})}
																	<Typography
																		className='or-separator'
																		variant='subtitle2'
																		sx={{ textAlign: "center", marginY: "8px", fontWeight: "bold" }}>
																		OR
																	</Typography>
																	<div
																		style={{
																			marginBottom: "8px",
																		}}>
																		{SlotList !== null &&
																			SlotList !== undefined &&
																			SlotList !== "isLoading" &&
																			Object.keys(SlotList).length > 0 && <ChargeNowItem data={data} />}
																	</div>
																</Box>
															</Box>
														);
														return list;
													})
												);
											})
										) : SlotList === "isLoading" ? (
											<>
												<LinearProgress />
											</>
										) : (
											<span className='d-flex justify-content-center'>No timeslot available.</span>
										)}
									</List>
								</AccordionDetails>
							</Accordion>
						);
					})}
			</div>
		</div>
	);
};

export default StationListAccordion;
