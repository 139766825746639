import EncryptData from "./EncryptData";
import DecryptData from "./DecryptData";
import RefreshToken from "./RefreshToken";

const TokenCheck = async (refreshToken) => {
    // console.log(refreshToken);
    let tokenExpiration = DecryptData('token_expiration');
    let timeExpiry = new Date(tokenExpiration);
    let timeCurrent = new Date(Date.now());
    console.log("[IS_SENT!]")
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
            type: "WEBAPP_LOGS",
            message: "[CURRENT]:" + timeCurrent + ", [EXPIRATION]:" + timeExpiry,
        }))
    }
    console.log("[CURRENT]:", timeCurrent, ", [EXPIRATION]:", timeExpiry)
    if (timeCurrent > timeExpiry) {
        console.log("[Is_AccessToken_Expired]: Yep.")
        let request = await RefreshToken(refreshToken);
        console.log("REQUEST1 ======================> ", request)
        if (request === undefined) {
            return "FAILED";
        } else {
            // "Success"
            console.log("REQUEST2 ======================> ", request)
            localStorage.clear();
            const accessTokenExpiration = new Date(request.data.access_expires_in * 1000);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: "TOKEN_REFRESH",
                    refresh_token: request.data.refresh_token,
                    access_token: request.data.access_token,
                    token_expiration: accessTokenExpiration
                }));
            }
            EncryptData("token_expiration", accessTokenExpiration);
            EncryptData("access_token", request.data.access_token);
            EncryptData("refresh_token", request.data.refresh_token);
            console.log("[ACCESS_TOKEN]: ", request.data.access_token)
            return {
                status: "PASSED",
                action: "REFRESHED",
                access_token: request.data.access_token
            };

        }
    } else {
        let access_token = DecryptData('access_token')
        console.log("[Is_AccessToken_Expired]: Nope.")
        // console.log("[ACCESS_TOKEN]: ", access_token)
        return {
            status: "PASSED",
            action: "CHECK_ONLY",
        };
    }
}

export default TokenCheck;