function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const CancelExpiredReservations = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    let currentDateTime = getCurrentDateTime();

    const raw = JSON.stringify({
        "datetime": currentDateTime
    });

    const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/cancel-expired-reservations`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized/Invalid Token.");
            } else if (response.status === 404) {
                return response.json();
            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            return response.json();
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default CancelExpiredReservations;