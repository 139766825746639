const TryCancelReservation = async (accessToken, reservationID, evcID, timeslotID, connectorID) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + accessToken);

    var raw = JSON.stringify({
        "reservation_id": reservationID,
        "evc_id": evcID,
        "timeslot_id": timeslotID,
        "connector_id": connectorID,
    });

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/cancel`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized/Invalid Token.");
            } else if (response.status === 404) {
                return response.json();
            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            return response.json();
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default TryCancelReservation;