import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { PatternFormat } from "react-number-format";
import "../css/submodal.css";
import CurrencyInput from "react-currency-input-field";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DangerousIcon from "@mui/icons-material/Dangerous";

import TokenCheck from "../../../functions/TokenCheck";
import DecryptData from "../../../functions/DecryptData";
import TryCheckOut from "../../../functions/TryCheckout";
import { renderTheme } from "../../../App";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalPaymentMethod = (props) => {
	const [PaymentMethod, setPaymentMethod] = useState("gcash");
	const [PaymentAmount, setPaymentAmount] = useState();
	const [DateMonth, setDateMonth] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [PaymentStep, setPaymentStep] = useState("step1_insert");
	const [IsPaymentAmountValid, setIsPaymentAmountValid] = useState(false);

	// RENDER THEME
	const { PaymentResult, togglePaymentResult } = useContext(renderTheme);

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			togglePaymentResult();
			props.onSubModalClose(false, undefined);
		}, 250);
	};

	const toggleMonthChange = (e) => {
		if (e.target.value > 12) {
			e.target.value = 0 + e.key;
		}
	};

	const togglePaymentMethod = (e) => {
		let payment_method = e.target.value;
		if (payment_method === "Debit/Credit Card") {
			setPaymentMethod("debit_credit");
		} else if (payment_method === "Gcash") {
			setPaymentMethod("gcash");
		} else {
			setPaymentMethod("maya");
		}
	};

	const handleAmountChange = (event) => {
		// Get the input value from the event
		const value = event.target.value;

		// Use a regular expression to remove any non-digit characters
		const newValue = value.replace(/\D/g, "");

		// Update the state with the cleaned numeric value
		setPaymentAmount(newValue);
	};

	const handleInputBlur = (e) => {
		let amount = Number(PaymentAmount);
		if (PaymentAmount > 0) {
			setPaymentAmount(amount.toFixed(2));
		}
	};

	const toggleTopUp = () => {
		const saved_refreshToken = localStorage.getItem("refresh_token");
		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			let request = TokenCheck(refreshToken);
			request.then((data) => {
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");

					if (savedToken !== null) {
						let reqToken;
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}
						setPaymentStep("step2_initialization");
						let checkoutReq = TryCheckOut(reqToken, PaymentMethod, Number(PaymentAmount));
						checkoutReq.then((res) => {
							const url = res.data.checkout_url;
							setTimeout(() => {
								window.location.href = url;
							}, 1000);
						});
					}
				}
			});
		}
	};

	const ToggleTryAgain = () => {
		setPaymentMethod("gcash");
		setPaymentAmount();
		setPaymentStep("step1_insert");
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	useEffect(() => {
		if (PaymentAmount !== undefined) {
			if (PaymentAmount < 100) {
				setIsPaymentAmountValid(false);
			} else {
				setIsPaymentAmountValid(true);
			}
		}
	}, [PaymentAmount]);

	useEffect(() => {
		console.log(PaymentMethod.toLowerCase());
	}, [PaymentMethod]);

	useEffect(() => {
		if (PaymentResult !== undefined) {
			if (PaymentResult === "paid") {
				setPaymentStep("step3_success");
			} else {
				setPaymentStep("step3_failed");
			}
		}
	}, [PaymentResult]);

	return (
		<div id='submodal_payment_method'>
			<motion.div
				className='submodal-card card-payment-method'
				initial={{ scale: 0, translateY: "-2.5%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					<span className='header-blank'>&nbsp;</span>
					<span>Top Up</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				{PaymentStep === "step1_insert" ? (
					<>
						<div
							style={{
								padding: "2%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<span style={{ fontWeight: "bold" }}>Select payment method to use:</span>
							<select
								className='form-select'
								style={{ width: "max-content" }}
								onChange={togglePaymentMethod}>
								<option>Gcash</option>
								<option>Maya</option>
								<option>Debit/Credit Card</option>
							</select>
						</div>
						{PaymentMethod === "debit_credit" && (
							<div style={{ padding: "2%" }}>
								<span style={{ fontWeight: "bold" }}>Enter card details:</span>
								{/* <div className='w-100 text-center card-details-header'>
							<span className='header-blank'>&nbsp;</span>
							<span>Card Details</span>
							<span className='header-close'>
								<CloseIcon
									className='submodal-close-btn'
									onClick={toggleSubModalClose}
								/>
							</span>
						</div> */}
								<div className='card-details-form'>
									<div className='col-12 mb-2'>
										<label className='card-payment-label'>Card Number</label>
										<PatternFormat
											format='#### #### #### ####'
											className='form-control'
											placeholder='#### #### #### ####'
										/>
									</div>
									<div className='card-payment-input-details gap-2 mb-2'>
										<div className='payment-input-details-date gap-2'>
											<div className='col-3'>
												<label className='card-payment-label'>Exp Month</label>
												<PatternFormat
													format='##'
													value={DateMonth}
													className='form-control'
													placeholder='MM'
													onKeyUp={(event) => toggleMonthChange(event)}
													onInput={(e) => setDateMonth(e.target.value)}
												/>
											</div>
											<div className='col-4'>
												<label className='card-payment-label'>Exp Year</label>
												<PatternFormat
													format='####'
													className='form-control'
													placeholder='YYYY'
												/>
											</div>
										</div>
										<div>
											<label className='card-payment-label'>CVC/CVV</label>
											<PatternFormat
												format='####'
												className='form-control'
												placeholder='XXXX'
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className='submodal-form gap-2'>
							<div style={{ width: "100%" }}>
								<span style={{ fontWeight: "bold" }}>Amount:</span>
							</div>

							<CurrencyInput
								id='txt_topup_value'
								name='input-name'
								placeholder='100.00'
								decimalsLimit={2}
								className='form-control'
								value={PaymentAmount}
								onChange={handleAmountChange}
								onBlur={handleInputBlur}
							/>
							<Typography variant='caption'>Note: minimum top-up transaction is ₱100.00</Typography>

							<Button
								disabled={!IsPaymentAmountValid}
								variant='contained'
								onClick={toggleTopUp}>
								Top-up
							</Button>
						</div>
					</>
				) : PaymentStep === "step2_initialization" ? (
					<>
						<div className='payment-transact-initialization'>
							<Typography sx={{ textAlign: "center" }}>Initiating Payment Transaction...</Typography>
							<CircularProgress sx={{ marginY: "16px" }} />
							<Typography sx={{ textAlign: "center" }}>
								Please Wait While We Redirect You to Complete the Payment Process.
							</Typography>
						</div>
					</>
				) : (
					<>
						{PaymentStep === "step3_success" ? (
							<div className='payment-transact-initialization'>
								<AddTaskIcon sx={{ fontSize: "48px", color: "green", marginBottom: "16px" }} />
								<Typography sx={{ textAlign: "center" }}>
									Your Top-up is Successful! Your account has been successfully credited with the requested amount.
								</Typography>
							</div>
						) : (
							<div className='payment-transact-initialization'>
								<DangerousIcon sx={{ fontSize: "48px", color: "red", marginBottom: "16px" }} />
								<Typography sx={{ textAlign: "center" }}>
									Your Top-up has Failed! Unfortunately, we were unable to process your transaction at this time. Please
									try again later or contact customer support for assistance.
								</Typography>
								<Button
									onClick={ToggleTryAgain}
									variant='contained'
									sx={{ marginTop: "32px" }}>
									Try Again
								</Button>
							</div>
						)}
					</>
				)}
			</motion.div>
		</div>
	);
};

export default SubModalPaymentMethod;
