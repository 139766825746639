import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../css/submodal.css";

import DecryptData from "../../../functions/DecryptData";
import TryLogout from "../../../functions/TryLogout";
import TokenCheck from "../../../functions/TokenCheck";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalLogout = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};

	const toggleLogout = (event) => {
		props.onSubModalClose(false, undefined);
		props.onModalVisibility(true, "modal_loading");

		let refreshToken = DecryptData("refresh_token");
		let request = TokenCheck(refreshToken);
		request.then((res) => {
			let reqToken;
			if (res.action === "REFRESHED") {
				reqToken = res.access_token;
			} else {
				reqToken = DecryptData("access_token");
			}
			if (res.status === "PASSED") {
				let response = TryLogout(reqToken, props.onModalVisibility);
				response.then((data) => {
					if (data.message === "Logged out successfully") {
						props.onIsLoggedIn(event, false);
						localStorage.clear();
						props.onModalVisibility(false, "");
						props.onAlertVisibility(true, "Logged out!", "error");
						setTimeout(() => {
							window.location.reload(false);
						}, 1500);
					}
				});
			}
		});
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	return (
		<div id='submodal_logout'>
			<motion.div
				className='submodal-card card-logout'
				initial={{ scale: 0, translateY: "-15%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					<span className='header-blank'>&nbsp;</span>
					<span>Logout</span>
					<span className='header-blank'>&nbsp;</span>
					{/* <span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span> */}
				</div>
				<div className='submodal-content'>
					<div className='submodal-description p-3'>
						<span className='sm-description-content'>Are you sure you want to log out?</span>
					</div>
					<div className='logout-button-group'>
						<Button
							variant='text'
							onClick={toggleSubModalClose}>
							Cancel
						</Button>
						<Button
							variant='contained'
							color='warning'
							onClick={toggleLogout}>
							Log out
						</Button>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalLogout;
