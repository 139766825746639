const TryReserve = async (AccessToken, ReservationData) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + AccessToken);

    var raw = JSON.stringify({
        "evc_id": ReservationData.evc_id,
        "connector_id": ReservationData.connector_id,
        "timeslot_id": ReservationData.timeslot_id,
        "timeslot_date": ReservationData.timeslot_date,
        "next_timeslot_id": ReservationData.next_timeslot_id,
        "next_timeslot_date": ReservationData.next_timeslot_date,
        "date": ReservationData.date,
        "current_time": ReservationData.current_time,
        "want_to_book": ReservationData.want_to_book
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/reserve`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized/Invalid Token.");
            } else if (response.status === 400) {
                return response.json();
            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            return response.json();
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default TryReserve;