import { useEffect, useState } from "react";
import "../../css/modal.css";

const OptionItem = (props) => {
	const [ItemSelected, setItemSelected] = useState(false);

	useEffect(() => {
		if (props.onItemCode === "ALL") {
			if (props.onIfAllIsSelected) {
				setItemSelected(true);
			} else {
				setItemSelected(false);
			}
		} else {
			if (props.onOptionItemSelection) {
				setItemSelected(true);
			} else {
				setItemSelected(false);
			}
		}
	}, [props]);

	const toggleItemSelect = (e) => {
		if (e.target.getAttribute("data-value") === "ALL") {
			if (ItemSelected === true) {
				setItemSelected(false);
				props.onSetSelected("ALL", "UNSELECT");
			} else {
				setItemSelected(true);
				props.onSetSelected("ALL", "SELECT");
			}
		} else {
			if (ItemSelected) {
				setItemSelected(false);
				props.onToggleAll(false);
				props.onSetSelected(props.onItemCode, "UNSELECT");
			} else {
				setItemSelected(true);
				props.onSetSelected(props.onItemCode, "SELECT");
			}
		}
		// props.onFilterSelected()
	};

	return (
		<span
			className={ItemSelected ? "option-item selected" : "option-item"}
			data-value={props.onItemCode}
			onClick={(e) => toggleItemSelect(e)}>
			{props.onOptionItemName}
		</span>
	);
};

export default OptionItem;
