import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./TransactionManager.css";
import { Typography, CircularProgress } from "@mui/material";

const TransactionManager = () => {
	const { userType, userID, token, transactID } = useParams();

	useEffect(() => {
		console.log("[User Type]:", userType);
		console.log("[User ID]:", userID);
		console.log("[Token]:", token);
		console.log("[Transaction ID]:", transactID);
		// const otherWindow = window.opener; // Reference to the window that opened the current window
		// const data = {
		// 	head: "gcash_transact_result",
		// 	body: {
		// 		token,
		// 		user_id,
		// 		topup_id,
		// 	},
		// };
		// console.log(token, user_id, topup_id);
		// // Send the message to the opening tab
		// otherWindow.postMessage(data, "*");
		// window.close();
	}, []);

	return (
		<div className="main">
			<Typography
				variant='subtitle1'
				sx={{ textAlign: "center", marginBottom: "16px" }}>
				Validating Payment....
			</Typography>
			<CircularProgress
				color='inherit'
				size={30}
			/>
		</div>
	);
};

export default TransactionManager;
