import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRateIcon from "@mui/icons-material/StarRate";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import CloseIcon from "@mui/icons-material/Close";
import "../css/submodal.css";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalReviewList = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [SelectedSort, setSelectedSort] = useState("All");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};

	const toggleSetSelectedSort = (typeOfSort) => {
		setSelectedSort(typeOfSort);
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	return (
		<div id='submodal_reviewlist'>
			<motion.div
				className='submodal-card card-reviewlist'
				initial={{ scale: 0, translateY: "-7.5%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					<span className='header-blank'>&nbsp;</span>
					<span>User Reviews</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				<div className='reviews-menu'>
					<span
						className={SelectedSort === "All" ? "menu-item-all selected" : "menu-item-all"}
						onClick={() => {
							toggleSetSelectedSort("All");
						}}>
						All
					</span>
					<span
						className={SelectedSort === "5" ? "menu-item star-group selected" : "menu-item star-group"}
						onClick={() => {
							toggleSetSelectedSort("5");
						}}>
						<StarRateIcon />
						<StarRateIcon />
						<StarRateIcon />
						<StarRateIcon />
						<StarRateIcon />
					</span>
					<span
						className={SelectedSort === "4" ? "menu-item star-group selected" : "menu-item star-group"}
						onClick={() => {
							toggleSetSelectedSort("4");
						}}>
						<StarRateIcon />
						<StarRateIcon />
						<StarRateIcon />
						<StarRateIcon />
						<StarOutlineIcon />
					</span>
					<span
						className={SelectedSort === "3" ? "menu-item star-group selected" : "menu-item star-group"}
						onClick={() => {
							toggleSetSelectedSort("3");
						}}>
						<StarRateIcon />
						<StarRateIcon />
						<StarRateIcon />
						<StarOutlineIcon />
						<StarOutlineIcon />
					</span>
					<span
						className={SelectedSort === "2" ? "menu-item star-group selected" : "menu-item star-group"}
						onClick={() => {
							toggleSetSelectedSort("2");
						}}>
						<StarRateIcon />
						<StarRateIcon />
						<StarOutlineIcon />
						<StarOutlineIcon />
						<StarOutlineIcon />
					</span>
					<span
						className={SelectedSort === "1" ? "menu-item star-group selected" : "menu-item star-group"}
						onClick={() => {
							toggleSetSelectedSort("1");
						}}>
						<StarRateIcon />
						<StarOutlineIcon />
						<StarOutlineIcon />
						<StarOutlineIcon />
						<StarOutlineIcon />
					</span>
				</div>
				<div className='reviews-body'>
					<Accordion
						expanded={expanded === "panel1"}
						onChange={handleChange("panel1")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'>
							<Typography>J**** D**** C****</Typography>
							<Typography>
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarOutlineIcon sx={{ fontSize: "1rem" }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
								blandit leo lobortis eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "panel2"}
						onChange={handleChange("panel2")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'>
							<Typography>C***** P*****</Typography>
							<Typography>
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarOutlineIcon sx={{ fontSize: "1rem" }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
								blandit leo lobortis eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "panel3"}
						onChange={handleChange("panel3")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'>
							<Typography>J***** D*****</Typography>
							<Typography>
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarOutlineIcon sx={{ fontSize: "1rem" }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
								blandit leo lobortis eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "panel4"}
						onChange={handleChange("panel4")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'>
							<Typography>Ta***** S*****</Typography>
							<Typography>
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarOutlineIcon sx={{ fontSize: "1rem" }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
								blandit leo lobortis eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "panel5"}
						onChange={handleChange("panel5")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'>
							<Typography>Jo***** P****</Typography>
							<Typography>
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarOutlineIcon sx={{ fontSize: "1rem" }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
								blandit leo lobortis eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "panel6"}
						onChange={handleChange("panel6")}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel2a-content'
							id='panel2a-header'>
							<Typography>Ko***** P*****</Typography>
							<Typography>
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarRateIcon sx={{ fontSize: "1rem" }} />
								<StarOutlineIcon sx={{ fontSize: "1rem" }} />
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
								blandit leo lobortis eget.
							</Typography>
						</AccordionDetails>
					</Accordion>
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalReviewList;
