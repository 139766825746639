import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, useRef } from "react";
import "./css/messagealert.css";
import { motion } from "framer-motion";

const variants = {
	open: { opacity: 1, scale: 1 },
	closed: { opacity: 0, scale: 0.5 },
};

const MessageAlert = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [MapError, setMapError] = useState(false);
	const AlertTimeout = useRef(null);

	const toggleAlertClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onAlertVisibility(false, "");
		}, 250);
	};

	useEffect(() => {
		setIsOpen(true);
		if (
			!(
				props.alertDetails.message ===
				"Can't find your exact location. Try restarting your network or/then refresh the page."
			) &&
			!(props.alertDetails.message === "Loading map error. Try restarting your network or/then refresh the page.")
		) {
			if (AlertTimeout.current !== null) {
				clearTimeout(AlertTimeout.current);
				AlertTimeout.current = null;
			}
			AlertTimeout.current = setTimeout(() => {
				setIsOpen(false);
				props.onAlertVisibility(false, "");
				clearTimeout(AlertTimeout.current);
				AlertTimeout.current = null;
			}, 3000);
		} else {
			setMapError(true);
		}
	}, [props]);

	return (
		<motion.div
			id='alert_holder'
			animate={isOpen ? "open" : "closed"}
			variants={variants}
			initial={{ opacity: 0, scale: 0.5 }}
			transition={{ duration: 0.3 }}
			onClick={() => props.onAlertVisibility(false, "")}>
			<Alert
				variant='filled'
				className='alert-body'
				severity={props.alertDetails.severity}
				color={props.alertDetails.severity === "success" && "warning"}>
				{props.alertDetails.message}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{MapError && (
					<span
						className='alert-close-btn'
						onClick={toggleAlertClose}>
						<CloseIcon />
					</span>
				)}
			</Alert>
		</motion.div>
	);
};

export default MessageAlert;
