import { useEffect, useState, useContext, useRef } from "react";
import Header from "./components/Header";
import BookingStatus from "./components/BookingStatus";
import MainBody from "./components/MainBody";
import MapBody from "./components/MapBody";
import BottomNavigation from "./components/BottomNavigation";

import MapIcon from "@mui/icons-material/Map";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import "./css/home.css";

import GetReservationList from "../../functions/GetReservationList";
import TokenCheck from "../../functions/TokenCheck";
import DecryptData from "../../functions/DecryptData";

import { renderTheme } from "../../App";

const Home = (props) => {
	const [BodyVisible, setBodyVisible] = useState("Main");
	// const [UserPosition, setUserPosition] = useState();
	const [UserMarkerVisibility, setUserMarkerVisibility] = useState(false);
	const [ZoomLevel, setZoomLevel] = useState(17);
	const [ActiveCategory, setActiveCategory] = useState("");
	const [MarkerCoordinates, setMarkerCoordinates] = useState(0);

	// RENDER THEME
	const { RenderType, StatusChange } = useContext(renderTheme);
	const [CategoryMerchantList, setCategoryMerchantList] = useState("");
	const loadingRef = useRef(null);

	const toggleActiveCategory = (category) => {
		setActiveCategory(category);
	};

	const toggleCategoryMerchantList = (MerchantList) => {
		setCategoryMerchantList(MerchantList);
	};

	const toggleShowListMap = (Body) => {
		props.onModalVisibility(true, "modal_loading");
		setTimeout(() => {
			setBodyVisible(Body);
			props.onModalVisibility(false, "");
		}, 1000);
	};

	useEffect(() => {
		if (props.onIsMerchantToLocate.status) {
			toggleShowListMap("Map");
			setMarkerCoordinates(props.onIsMerchantToLocate.location);
			props.onMerchantToLocate(false);
		}
	}, [props.onIsMerchantToLocate.status]);

	useEffect(() => {
		if (RenderType !== undefined) {
			if (RenderType !== "application") {
				if (props.onUserLocation === undefined) {
					if (navigator.geolocation) {
						let userLocate = navigator.geolocation.watchPosition(
							function (position) {
								console.log("[ACCURACY]:", position.coords.accuracy);
								console.log("[LOCATION]:", position.coords.latitude, position.coords.longitude);
								props.onSetUserLocation({
									lat: position.coords.latitude,
									lng: position.coords.longitude,
								});
								if (loadingRef.current) {
									clearTimeout(loadingRef.current);
									loadingRef.current = null;
								}
								loadingRef.current = setTimeout(() => {
									if (position.coords.accuracy > 100) {
										setUserMarkerVisibility(false);
										props.onAlertVisibility(
											true,
											"Can't find your exact location. Try restarting your network or/then refresh the page.",
											"error"
										);
										console.log("The GPS accuracy isn't good enough");
										// showErrorSnackBar("The GPS accuracy isn't good enough");
									} else {
										setUserMarkerVisibility(true);
									}
									if (props.onWhatModal === "modal_loading") {
										props.onModalVisibility(false, "");
									}
									clearTimeout(loadingRef.current);
									loadingRef.current = null;

									navigator.geolocation.clearWatch(userLocate);
								}, 3000);
							},
							(error) => {
								props.onSetAsCurrent("");
								props.onModalVisibility(true, "modal_location");
								// props.onAlertVisibility(
								// 	true,
								// 	"Loading map error. Make sure that your location is enabled.",
								// 	"error"
								// );
								console.log("ERROR:", error.code, error.message);
							},
							{
								enableHighAccuracy: true,
								timeout: 5000,
								maximumAge: Infinity,
							}
						);
					}
				} else {
					setUserMarkerVisibility(true);
				}
			} else {
				console.log("WELCOME BITCH!");
				if (window.ReactNativeWebView) {
					window.ReactNativeWebView.postMessage(
						JSON.stringify({
							type: "WEBAPP_LOGS",
							message: "WELCOMEEEEEEEEEEEEEEEEEEEEEEEEEEEEE to PNC! -website",
						})
					);
				}
			}
		} else {
			console.log("WELCOME BITCH!");
		}
	}, [props.onUserLocation, RenderType]);

	useEffect(() => {
		props.onModalVisibility(false, "");
		console.log("CATEGORY MERCHANT LIST :::::::> ", CategoryMerchantList);
	}, [CategoryMerchantList]);

	return (
		<div id='page_home'>
			{RenderType === "website" && (
				<Header
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
				/>
			)}
			{props.onIfLoggedIn && StatusChange !== undefined && (
				<BookingStatus
					onModalVisibility={props.onModalVisibility}
					onIfLoggedIn={props.onIfLoggedIn}
					onWhatRenderType={RenderType}
				/>
			)}

			{BodyVisible === "Main" ? (
				<MainBody
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
					onWhatActiveCategory={ActiveCategory}
					onUserPosition={props.onUserLocation}
					onCategoryMerchantList={CategoryMerchantList}
					onSetCatMerchantList={toggleCategoryMerchantList}
					onMerchantSelection={props.onMerchantSelection}
					onIsFilterEnabled={props.onIsFilterEnabled}
					onFilteredData={props.onFilteredData}
					onWhatModal={props.onWhatModal}
				/>
			) : (
				<MapBody
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
					onUserPosition={props.onUserLocation}
					onUserMarker={UserMarkerVisibility}
					onMerchantMarker={MarkerCoordinates}
					onMerchantSelection={props.onMerchantSelection}
					onWhatModal={props.onWhatModal}
					onCategoryMerchantList={CategoryMerchantList}
					onFilteredData={props.onFilteredData}
				/>
			)}
			{props.onIfLoggedIn !== undefined && (
				<BottomNavigation
					onIfLoggedIn={props.onIfLoggedIn}
					onSetWhatShowing={toggleShowListMap}
					onSetActiveCategory={toggleActiveCategory}
					onModalVisibility={props.onModalVisibility}
					onIsShowing={BodyVisible}
					onIfSelected={props.onIfSelected}
					onSetSelected={props.onSetSelected}
					onUserPosition={props.onUserLocation}
					onSetCatMerchantList={toggleCategoryMerchantList}
				/>
			)}
		</div>
	);
};

export default Home;
