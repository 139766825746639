const ActivateCharger = async (accessToken, locationID, chargerID, type, latitude, longitude, currentTime, currentDate, connectorID) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const raw = JSON.stringify({
        "current_time": currentTime,
        "current_date": currentDate,
        "location_id": locationID,
        "evse_uid": chargerID,
        "type": type,
        "lat": latitude,
        "lng": longitude,
        "connector_id": connectorID
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };


    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/charge_now`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized/Invalid Token.");
            } else if (response.status === 400) {
                return response.json();
            } else {
                console.log(`Server error: ${response.status}`);
            }
        } else {
            return response.json();
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export default ActivateCharger;