import DecryptData from "./DecryptData";
import TokenCheck from "./TokenCheck";

const TryFilterLocation = async (type, Latitude, Longitude, filterItems) => {

    const filterType = type === "default" ? "filter_locations" : "filter_locations_with_favorites";


    const ChooseFilter = async (authHeader) => {
        const myHeaders = new Headers();

        myHeaders.append("Authorization", authHeader);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const graphql = JSON.stringify({
            query: `query Filter_locations {\r\n    ${filterType}(\r\n        lat: ${Latitude}\r\n        lng: ${Longitude}\r\n        facilities: ${filterItems.facilities !== undefined ? filterItems.facilities : null}\r\n        capabilities: ${filterItems.capabilities !== undefined ? filterItems.capabilities : null}\r\n        payment_types: ${filterItems.payment_types !== undefined ? filterItems.payment_types : null}\r\n        parking_types: ${filterItems.parking_types !== undefined ? filterItems.parking_types : null}\r\n        connector_types: ${filterItems.connector_types !== undefined ? filterItems.connector_types : null}\r\n        power_types: ${filterItems.power_types !== undefined ? filterItems.power_types : null}\r\n    ) {\r\n        id\r\n        publish\r\n        name\r\n        address\r\n        address_lat\r\n        address_lng\r\n        city\r\n        distance\r\n        evses {\r\n            uid\r\n            evse_id\r\n            serial_number\r\n            meter_type\r\n            status\r\n            cpo_location_id\r\n            current_ws_connection_id\r\n            server_id\r\n            date_created\r\n        }\r\n        facilities {\r\n            id\r\n            code\r\n            description\r\n        }\r\n    }\r\n}\r\n`,
            variables: {}
        })
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: graphql,
            redirect: "follow"
        };

        let response;

        try {
            response = await fetch(
                `${process.env.REACT_APP_API_ADDRESS}/booking_merchants/graphql`,
                requestOptions
            );

            return response.json();

        } catch (error) {
            console.log("Network error or other unexpected issue:", error);
        }
    }

    if (type === "default") {
        return ChooseFilter(`Basic ${process.env.REACT_APP_API_KEY}`)
    } else {
        const saved_refreshToken = localStorage.getItem("refresh_token");
        if (saved_refreshToken !== null) {
            let refreshToken = DecryptData("refresh_token");
            let request = TokenCheck(refreshToken);

            return request.then((data) => {
                if (data.status === "PASSED") {
                    let savedToken = localStorage.getItem("access_token");

                    if (savedToken !== null) {
                        let reqToken;
                        if (data.action === "REFRESHED") {
                            reqToken = data.access_token;
                        } else {
                            reqToken = DecryptData("access_token");
                        }

                        return ChooseFilter(`Bearer ${reqToken}`)
                    }
                }
            });
        }
    }





}

export default TryFilterLocation;