import React, { useEffect, useState, useRef, useContext } from "react";
import { motion } from "framer-motion";
import "../css/modal.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import StationListAccordion from "./subcomponents/StationListAccordion";
import ExploreIcon from "@mui/icons-material/Explore";
import { Chip, Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";
import SetAsFav from "../../../functions/SetAsFav";
import DelAsFav from "../../../functions/DelAsFav";
import GetFavMerchants from "../../../functions/GetFavMerchants";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import { renderTheme } from "../../../App";
import { render } from "@testing-library/react";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const ModalMerchant = (props) => {
	//RENDER THEME
	const { IsSubModalClicked, isMobile, AlertVisible } = useContext(renderTheme);
	const [isOpen, setIsOpen] = useState(false);
	const [FavoriteMerchant, setFavoriteMerchant] = useState(false);
	const [SelectedMerchantDetails, setSelectedMerchantDetails] = useState();
	const [AmenitiesFacilities, setAmenitiesFacilities] = useState();
	const [ParkingTypes, setParkingTypes] = useState();
	const [ParkingRestrictions, setParkingRestrictions] = useState();
	const [IsLoaded, setIsLoaded] = useState(false);
	const [IsMerchantInfoVisible, setIsMerchantInfoVisible] = useState(isMobile ? false : true);
	const modalRef = useRef(null);

	useEffect(() => {
		if (props.onSelectedMerchant) {
			setIsLoaded(true);
			if (props.onSelectedMerchant.favorite === "true") {
				setFavoriteMerchant(true);
			}
			setSelectedMerchantDetails(props.onSelectedMerchant);
		}
	}, [props.onSelectedMerchant]);

	useEffect(() => {
		if (SelectedMerchantDetails !== undefined) {
			console.log(SelectedMerchantDetails);
			if (SelectedMerchantDetails.facilities !== undefined) {
				setAmenitiesFacilities(SelectedMerchantDetails.facilities);
			}

			if (SelectedMerchantDetails.parking_types !== undefined) {
				setParkingTypes(SelectedMerchantDetails.parking_types);
			}

			if (SelectedMerchantDetails.parking_restrictions !== undefined) {
				setParkingRestrictions(SelectedMerchantDetails.parking_restrictions);
			}
		}
	}, [SelectedMerchantDetails]);

	useEffect(() => {
		console.log(AmenitiesFacilities);
	}, [AmenitiesFacilities]);

	useEffect(() => {
		console.log(props.onIsSubmodalClicked);
	}, [props]);

	// useEffect(() => {
	// 	if (props.merchantDetails) {
	// 		if (props.merchantDetails.amenities.length > 0) {
	// 			setAreCarousel(true);
	// 		}
	// 	}
	// }, [props.merchantDetails.amenities]);

	function importAll(r) {
		let images = {};
		r.keys().forEach((item, index) => {
			images[item.replace("./", "")] = r(item);
		});
		return images;
	}

	const getImage = importAll(require.context("../../../assets", false, /\.(png|jpe?g|svg)$/));

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};

	const toggleFavoriteButton = () => {
		const saved_refreshToken = localStorage.getItem("refresh_token");
		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			let TCRequest = TokenCheck(refreshToken);
			TCRequest.then((data) => {
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");
					if (savedToken !== null) {
						let reqToken;
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}

						if (FavoriteMerchant === false && props.onIfLoggedIn) {
							let request = SetAsFav(reqToken, SelectedMerchantDetails.merchantID);
							request.then((res) => {
								if (res.data.errors === undefined) {
									if (res.data.addToFavoriteLocation.status === 200) {
										// props.onSetIsModifiedFav(true);
										setFavoriteMerchant(true);
										ReRequestMerchants(reqToken);
									}
								} else {
									console.log("Got some error/s.");
								}
							});
						} else {
							let request = DelAsFav(reqToken, SelectedMerchantDetails.merchantID);
							request.then((res) => {
								if (res.data.errors === undefined) {
									if (res.data.removeFromFavoriteLocation.status === 200) {
										// props.onSetIsModifiedFav(false);
										setFavoriteMerchant(false);
										ReRequestMerchants(reqToken);
									}
								} else {
									console.log("Got some error/s.");
								}
							});
						}
					}
				}
			});
		}
	};

	const ReRequestMerchants = (reqToken) => {
		if (props.onWhatCategory === "Recents_Favorite") {
			props.onSetItemCount(-1);
			// setIsCategoryAvailable(false);
			props.onSetNearbyMerchantChange(true);
		} else {
			if (props.onActiveCategory === "Favorite") {
				let request = GetFavMerchants(reqToken, props.onUserPosition.lng, props.onUserPosition.lat);
				request.then((res) => {
					props.onSetCatMerchantList();
					if (res.data.favorite_locations.length > 0) {
						props.onSetCatMerchantList(res.data.favorite_locations);
					}
				});
			}
		}
	};

	// const toggleFavoriteButton = () => {
	// 	if (FavoriteMerchant === false) {
	// 		setFavoriteMerchant(true);
	// 	} else {
	// 		setFavoriteMerchant(false);
	// 	}
	// };

	const toggleEnlargeImage = () => {
		props.onSubModalVisibility(true, "submodal_enlarged_image");
	};

	const toggleShowReviews = () => {
		props.onSubModalVisibility(true, "submodal_review_list");
	};

	const toggleMerchantInfoVisible = () => {
		if (IsMerchantInfoVisible) {
			setIsMerchantInfoVisible(false);
		} else {
			setIsMerchantInfoVisible(true);
		}
	};

	const handleClickOutside = (event, submodal) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setTimeout(() => {
				if (submodal === undefined && !AlertVisible) {
					toggleModalClose();
				}
			}, 125);
		}
	};

	useEffect(() => {
		setIsOpen(true);
		const listener = (e) => handleClickOutside(e, props.onWhatSubModal);

		document.addEventListener("mousedown", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
		};
	}, [props.onWhatSubModal]);

	return (
		<>
			{IsLoaded && (
				<div id='modal_merchant'>
					<motion.div
						className='modal-card card-merchant'
						initial={{ scale: 0 }}
						animate={isOpen ? "open" : "closed"}
						variants={variants}
						transition={{ duration: 0.3 }}
						ref={modalRef}>
						<div className='modal-header'>
							<span className='header-close merchant'>
								<ArrowBackIosNewIcon
									className='modal-close-btn'
									onClick={toggleModalClose}
								/>
								&nbsp;
								<div className='description-merchant-name-rate'>
									<span className='merchant-name'>{SelectedMerchantDetails ? SelectedMerchantDetails.name : "-"}</span>
								</div>
							</span>
							<span className='header-blank'>&nbsp;</span>
							<span className='header-action-button-group d-flex gap-2'>
								<ExploreIcon />
								<MapIcon
									onClick={() => {
										props.onMerchantToLocate(true, {
											lat: SelectedMerchantDetails.address_lat,
											lng: SelectedMerchantDetails.address_lng,
										});
									}}
								/>
								{/* <img
									src={getImage["locate_map.png"]}
									className='map-marker-btn'
									onClick={() => {
										props.onMerchantToLocate(true, {
											lat: SelectedMerchantDetails.address_lat,
											lng: SelectedMerchantDetails.address_lng,
										});
									}}
									title='Locate in map.'
								/> */}
								{FavoriteMerchant === true ? (
									<FavoriteIcon
										className='btn-favorite-active'
										onClick={toggleFavoriteButton}
									/>
								) : (
									<FavoriteTwoToneIcon onClick={toggleFavoriteButton} />
								)}
							</span>
						</div>
						<div className='card-merchant-content'>
							<div className='col-lg-6 col-12 merchant-station-list'>
								<span className='station-list-label'>Charging Station List</span>
								<StationListAccordion
									onModalVisibility={props.onModalClose}
									onSubModalVisibility={props.onSubModalVisibility}
									onMerchantID={SelectedMerchantDetails.merchantID}
									onStationList={SelectedMerchantDetails.stations}
								/>
							</div>
							{IsMerchantInfoVisible && (
								<span className='modal-merchant-separator'>
									<hr />
								</span>
							)}

							{IsMerchantInfoVisible && (
								<div className='col-lg-6 col-12 merchant-info'>
									<div className='merchant-card-lg'>
										{/* <OpenInFullIcon
											className='image-fullscreen-btn'
											fontSize='medium'
											onClick={toggleEnlargeImage}
										/> */}
										<div className='modal-merchant-image-holder gap-2'>
											<div className='col-12'>
												<img
													src={getImage["ParkNcharge_Logo.png"]}
													className='card-image-item'
												/>
											</div>
											{/* <div className='col-12'>
												<img
													src={getImage["ThreeEcom_Image_002.jpg"]}
													className='card-image-item'
												/>
											</div>
											<div className='col-12'>
												<img
													src={getImage["ThreeEcom_Image_003.jpg"]}
													className='card-image-item'
												/>
											</div>
											<div className='col-12'>
												<img
													src={getImage["ThreeEcom_Image_004.jpg"]}
													className='card-image-item'
												/>
											</div> */}
										</div>
										<div className='modal-merchant-title'>
											<span className='slot-list-header'>About the Merchant</span>
										</div>
										<div className='modal-merchant-description-holder'>
											<div className='container card-description-holder'>
												<span className='modal-subdescription address'>
													<span style={{ fontWeight: "bold" }}>Address:</span>{" "}
													<span>{SelectedMerchantDetails.address}</span>
												</span>
												{AmenitiesFacilities !== undefined && (
													<span className='modal-subdescription'>
														<span style={{ fontWeight: "bold" }}>Amenities and Nearby Establishments:</span>{" "}
														<span>
															{AmenitiesFacilities.map((data) => {
																return (
																	<Chip
																		label={data.description}
																		className='m-1'
																	/>
																);
															})}
														</span>
													</span>
												)}
												{ParkingTypes !== undefined && (
													<span className='modal-subdescription'>
														<span style={{ fontWeight: "bold" }}>Parking Types:</span>{" "}
														<span>
															{ParkingTypes.map((data) => {
																return (
																	<Chip
																		label={data.description}
																		className='m-1'
																	/>
																);
															})}
														</span>
													</span>
												)}
											</div>
										</div>
									</div>
									{/* <div className="merchant-promo">
                            <div className="reviews-header">
                                <span className="review-list-label">Promo</span>
                                <span><span className="text-decoration-line-through text-secondary fs-6">₱500</span>&nbsp;<span className="fs-5 fw-bold">₱450</span></span>
                            </div>
                        </div> */}
									<div
										className='merchant-reviews'
										// onClick={() => {
										// 	toggleShowReviews();
										// }}
									>
										<div className='reviews-header'>
											<span className='review-list-label'>Reviews</span>
											<span className='merchant-rating-reviews'>
												Coming soon...
												{/* <GradeIcon style={{ fontSize: "1.25rem" }} />
											<GradeIcon style={{ fontSize: "1.25rem" }} />
											<GradeIcon style={{ fontSize: "1.25rem" }} />
											<GradeIcon style={{ fontSize: "1.25rem" }} />
											<StarHalfIcon style={{ fontSize: "1.25rem" }} /> &nbsp;<span className='fw-bolder'>4.1</span>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<ArrowForwardIosIcon style={{ fill: "#C1C1C1" }} /> */}
											</span>
										</div>
									</div>
								</div>
							)}
							{isMobile && (
								<div className='d-flex align-items-center justify-content-center my-3'>
									<Button
										onClick={toggleMerchantInfoVisible}
										variant='text'
										size='small'
										sx={{ textTransform: "none" }}
										className='d-flex align-items-center justify-content-center'>
										{IsMerchantInfoVisible ? "Hide" : "Show"} Merchant Details
										<ExpandCircleDownIcon
											fontSize='medium'
											color='primary'
											sx={{
												transform: IsMerchantInfoVisible && "rotate(180deg)",
											}}
										/>
									</Button>
								</div>
							)}
						</div>
					</motion.div>
				</div>
			)}
		</>
	);
};

export default ModalMerchant;
