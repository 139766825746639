import { useEffect, useState, useContext } from "react";
import "../css/mainBody.css";
import Category from "./sub_components/Category";

import GetNearbyMerchants from "../../../functions/GetNearbyMerchants";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";
import { renderTheme } from "../../../App";

const MainBody = (props) => {
	const [MerchantList, setMerchantList] = useState();
	const [AccessToken, setAccessToken] = useState();
	const [IsModifiedFav, setIsModifiedFav] = useState(false);
	const [NearbyMerchantChange, setNearbyMerchantChange] = useState(false);
	const [UserPosition, setUserPosition] = useState();
	const [IsFilterEnabled, setIsFilterEnabled] = useState();
	const [IsLoaded, setIsLoaded] = useState(false);

	// RENDER THEME
	const { RenderType } = useContext(renderTheme);

	useEffect(() => {
		if (props.onUserPosition !== undefined && props.onIfLoggedIn !== undefined) {
			if (RenderType === "application") {
				if (window.ReactNativeWebView) {
					window.ReactNativeWebView.postMessage(
						JSON.stringify({
							type: "WEBAPP_LOGS",
							message: "NOTICE: OPEN IN APPLICATION -website",
						})
					);
				}
				const saved_refreshToken = localStorage.getItem("refresh_token");
				// alert("[refresh_token]:", saved_refreshToken);
				if (saved_refreshToken !== null) {
					let refreshToken = DecryptData("refresh_token");

					let request = TokenCheck(refreshToken);
					request.then((data) => {
						// alert(JSON.stringify(data));
						if (data.status === "PASSED") {
							let savedToken = localStorage.getItem("access_token");

							if (savedToken !== null) {
								let reqToken;
								if (data.action === "REFRESHED") {
									reqToken = data.access_token;
								} else {
									reqToken = DecryptData("access_token");
								}
								setAccessToken(reqToken);
								console.log("[ACCESS TOKEN] ====================> ", reqToken);
								setIsLoaded(false);
								if (props.onWhatModal === "modal_loading") {
									props.onModalVisibility(false, "");
								}
							}
						}
					});
				}
			} else {
				if (RenderType === "website") {
					if (props.onIfLoggedIn !== undefined) {
						if (props.onIfLoggedIn === false) {
							let request = GetNearbyMerchants(
								"Basic " + process.env.REACT_APP_API_KEY,
								props.onUserPosition.lng,
								props.onUserPosition.lat,
								"guest_user"
							);
							if (!IsLoaded) {
								props.onModalVisibility(true, "modal_loading");
							} else {
								props.onModalVisibility(false, "");
							}

							console.log(request);
							request.then((res) => {
								setMerchantList(null);
								if (res.errors === undefined) {
									setIsLoaded(true);
									setMerchantList(res.data.locations);
									props.onModalVisibility(false, "");
								} else {
									console.log("Got some error/s.");
								}
							});
						} else {
							console.log("[Is_Logged_In]", props.onIfLoggedIn);
							const saved_refreshToken = localStorage.getItem("refresh_token");
							if (saved_refreshToken !== null) {
								let refreshToken = DecryptData("refresh_token");
								let request = TokenCheck(refreshToken);
								request.then((data) => {
									if (data.status === "PASSED") {
										let savedToken = localStorage.getItem("access_token");

										if (savedToken !== null) {
											let reqToken;
											if (data.action === "REFRESHED") {
												reqToken = data.access_token;
											} else {
												reqToken = DecryptData("access_token");
											}
											setAccessToken(reqToken);
											console.log("[ACCESS TOKEN] ====================> ", reqToken);
											setIsLoaded(false);
											if (props.onWhatModal === "modal_loading") {
												props.onModalVisibility(false, "");
											}
										}
									}
								});
							} else {
								alert("[ERROR]: Refresh Token not found.");
							}
						}
					}
				}
			}
		}
	}, [props.onIfLoggedIn, props.onUserPosition]);

	useEffect(() => {
		if (props.onIsFilterEnabled !== undefined) {
			setIsFilterEnabled(props.onIsFilterEnabled);
		}
	}, [props.onIsFilterEnabled]);

	useEffect(() => {
		// alert(AccessToken);
		if (AccessToken !== undefined) {
			let request = GetNearbyMerchants(
				"Bearer " + AccessToken,
				props.onUserPosition.lng,
				props.onUserPosition.lat,
				"registered_user"
			);

			if (!IsLoaded) {
				props.onModalVisibility(true, "modal_loading");
			}

			request.then((res) => {
				setMerchantList(null);
				if (res.errors === undefined) {
					setIsLoaded(true);
					setMerchantList(res.data.location_with_favorites);
					props.onModalVisibility(false, "");
				} else {
					console.log("Got some error/s.");
				}
			});
		}
	}, [AccessToken]);

	useEffect(() => {
		if (NearbyMerchantChange) {
			const saved_refreshToken = localStorage.getItem("refresh_token");
			if (saved_refreshToken !== null) {
				let refreshToken = DecryptData("refresh_token");
				let request1 = TokenCheck(refreshToken);
				request1.then((data) => {
					if (data.status === "PASSED") {
						let savedToken = localStorage.getItem("access_token");
						if (savedToken !== null) {
							let reqToken;
							if (data.action === "REFRESHED") {
								reqToken = data.access_token;
							} else {
								reqToken = DecryptData("access_token");
							}

							setMerchantList();
							let request2 = GetNearbyMerchants(
								"Bearer " + reqToken,
								props.onUserPosition.lng,
								props.onUserPosition.lat,
								"registered_user"
							);

							request2.then((res) => {
								setMerchantList(null);
								if (res.errors === undefined) {
									setMerchantList(res.data.location_with_favorites);
									setNearbyMerchantChange(false);
									props.onModalVisibility(false, "");
								} else {
									console.log("Got some error/s.");
								}
							});
						}
					}
				});
			}
		}
	}, [NearbyMerchantChange]);

	const toggleIsModifiedFav = (bool) => {
		setIsModifiedFav(bool);
	};

	const toggleNearbyChange = (bool) => {
		setNearbyMerchantChange(bool);
	};

	return (
		<div id='home_mainBody'>
			{IsFilterEnabled ? (
				<Category
					onWhatTitle='Filter'
					onWhatActiveCategory='Filter'
					onIfLoggedIn={props.onIfLoggedIn}
					onModalVisibility={props.onModalVisibility}
					onItemCount={props.onFilteredData ? props.onFilteredData.length : 0}
					onFilteredData={props.onFilteredData}
					onUserPosition={props.onUserPosition}
					onMerchantSelection={props.onMerchantSelection}
					onSetCatMerchantList={props.onSetCatMerchantList}
					onIsModifiedFav={IsModifiedFav}
					onSetIsModifiedFav={toggleIsModifiedFav}
				/>
			) : (
				<>
					{props.onIfLoggedIn &&
						(props.onWhatActiveCategory === "Favorite" || props.onWhatActiveCategory === "Recents") && (
							<Category
								onWhatTitle='Recents_Favorite'
								onWhatActiveCategory={props.onWhatActiveCategory}
								onIfLoggedIn={props.onIfLoggedIn}
								onModalVisibility={props.onModalVisibility}
								onItemCount={props.onCategoryMerchantList ? props.onCategoryMerchantList.length : 0}
								onCatMerchantList={props.onCategoryMerchantList}
								onSetCatMerchantList={props.onSetCatMerchantList}
								onUserPosition={props.onUserPosition}
								onIsModifiedFav={IsModifiedFav}
								onSetIsModifiedFav={toggleIsModifiedFav}
								onSetNearbyMerchantChange={toggleNearbyChange}
								onMerchantSelection={props.onMerchantSelection}
							/>
						)}
					<Category
						onWhatTitle='Nearby'
						onIfLoggedIn={props.onIfLoggedIn}
						onModalVisibility={props.onModalVisibility}
						onNearbyMerchantList={MerchantList}
						onItemCount={MerchantList ? MerchantList.length : 0}
						onSetCatMerchantList={props.onSetCatMerchantList}
						onUserPosition={props.onUserPosition}
						onIsModifiedFav={IsModifiedFav}
						onSetIsModifiedFav={toggleIsModifiedFav}
						onWhatActiveCategory={props.onWhatActiveCategory}
						onMerchantSelection={props.onMerchantSelection}
					/>
				</>
			)}
		</div>
	);
};

export default MainBody;
