import { useEffect, useState, useContext, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { motion } from "framer-motion";
import "../css/modal.css";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Tooltip, Typography } from "@mui/material";
import EvStationIcon from "@mui/icons-material/EvStation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ReportIcon from "@mui/icons-material/Report";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";

import { renderTheme } from "../../../App";
import ValidateTimeslotReservation from "../../../functions/ValidateTimeslotReservation";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";
import UseReservation from "../../../functions/UseReservation";
import TryCancelReservation from "../../../functions/TryCancelReservation";
import subtractOneSecond from "../../../functions/SubtractOneSecond";

const variants = {
	open: { y: "0%" },
	closed: { y: "100%" },
};

const ModalChargingReservationDetails = (props) => {
	const [ChargingReservationList, setChargingReservationList] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [ChargerStatus, setChargerStatus] = useState("NOT_READY");
	const [ModalStep, setModalStep] = useState("DEFAULT");
	const [LiveChargingTimer, setLiveChargingTimer] = useState("-");
	const intervalIDRef = useRef(null);

	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	// RENDER THEME
	const { ChargingReservationDetails, toggleStatusChange, LiveChargingDetails, ChargeStep, setChargeStep } =
		useContext(renderTheme);

	useEffect(() => {
		setIsOpen(true);
	}, []);

	useEffect(() => {
		console.log("[Booking_Details]:", ChargingReservationDetails);
		if (ChargingReservationDetails !== undefined && ChargingReservationDetails.reservations.length > 0) {
			if (ChargingReservationDetails.reservations[0].status === "ACTIVE") {
				let reservation_date = ChargingReservationDetails.reservations[0].grace_period.split(" ");
				let currentDate = GetCurrentDate();
				let currentTime = getCurrentTimeWithSeconds();
				let request = ValidateTimeslotReservation(
					ChargingReservationDetails.reservations[0].evse_timeslot_id,
					currentTime,
					currentDate,
					reservation_date[0]
				);
				request.then((data) => {
					if (data.message === "INVALID_TO_ACTIVATE") {
						setChargerStatus("NOT_READY");
					} else {
						setChargerStatus("READY");
					}
				});
			}
			setChargingReservationList(ChargingReservationDetails);
		}
	}, [ChargingReservationDetails]);

	const toggleLiveChargingTimer = (time) => {
		setLiveChargingTimer(time);
	};

	useEffect(() => {
		console.log(LiveChargingDetails);
		if (LiveChargingDetails !== undefined && LiveChargingDetails.remaining_time !== undefined) {
			const { formattedTime } = subtractOneSecond(
				LiveChargingDetails.remaining_time,
				intervalIDRef,
				toggleLiveChargingTimer
			);
			console.log(formattedTime);
		}
	}, [LiveChargingDetails]);

	useEffect(() => {
		console.log("[Live Timer]:", LiveChargingTimer);
	}, [LiveChargingTimer]);

	useEffect(() => {
		console.log(ChargeStep);
	}, [ChargeStep]);

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};

	const toggleUseReservation = (ReservationID) => {
		const saved_refreshToken = localStorage.getItem("refresh_token");
		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			let request = TokenCheck(refreshToken);
			request.then((data) => {
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");

					if (savedToken !== null) {
						let reqToken;
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}
						let activate_request = UseReservation(reqToken, ReservationID);
						activate_request.then((act_req_data) => {
							if (act_req_data.result.status === "SUCCESS") {
								// setIsActivated(true);
								setChargeStep("WAITING");
								console.log(ChargeStep);
								toggleStatusChange("USER_CHARGING");
								props.onAlertVisibility(true, "Charger activated! Connect the plug into your EV now.", "info");
							}
						});
					}
				}
			});
		}
	};

	const toggleReservationCancel = (ReservationID, chargerID, timeslotID, connectorID) => {
		const saved_refreshToken = localStorage.getItem("refresh_token");
		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			let request = TokenCheck(refreshToken);
			request.then((data) => {
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");

					if (savedToken !== null) {
						let reqToken;
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}
						let resRequest = TryCancelReservation(reqToken, ReservationID, chargerID, timeslotID, connectorID);

						resRequest.then((res) => {
							if (res !== undefined) {
								if (res.status === 200) {
									toggleStatusChange();
									props.onModalClose(false, undefined);
									props.onAlertVisibility(true, "Timeslot reservation cancelled successfully!", "error");
								}
							}
						});
					}
				}
			});
		}
	};

	const getCurrentTimeWithSeconds = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		const seconds = now.getSeconds().toString().padStart(2, "0");

		return `${hours}:${minutes}:${seconds}`;
	};

	const GetCurrentDate = () => {
		const dateObject = new Date();

		// Get date components
		const year = dateObject.getFullYear();
		const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
		const day = dateObject.getDate().toString().padStart(2, "0");

		// Formatted date
		const formattedDate = `${year}-${month}-${day}`;

		return formattedDate;
	};

	function formatDateAndTime(dateTimeString) {
		// Create a Date object from the dateTimeString
		const dateTime = new Date(dateTimeString);

		const month = monthNames[dateTime.getMonth()];
		const day = dateTime.getDate();
		const year = dateTime.getFullYear();

		// Format the date
		const formattedDate = `${month} ${day}, ${year}`;

		// Extract the time components
		let hours = dateTime.getHours();
		const minutes = dateTime.getMinutes();
		const ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12 || 12; // Convert to 12-hour format

		// Format the time
		const formattedTime = `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;

		return { date: formattedDate, time: formattedTime };
	}

	const TimeConvert = (timeString) => {
		console.log(timeString);
		// Extract hours, minutes, and seconds
		const [hours, minutes] = timeString.split(":");
		// Convert hours to 12-hour format
		const formattedHours = hours % 12 || 12;
		// Determine if it's AM or PM
		const period = hours < 12 ? "AM" : "PM";

		// Create the formatted time string without seconds
		const formattedTimeString = `${formattedHours}:${minutes} ${period}`;

		return formattedTimeString;
	};

	const ChargingStepDisplay = () => {
		return (
			<AccordionDetails sx={{ paddingBottom: "32px" }}>
				{ChargeStep === "WAITING" ? (
					<div className='status-details'>
						<EvStationIcon sx={{ width: "48px", height: "48px" }} />
						<Typography
							variant='h6'
							sx={{ textAlign: "center" }}>
							Please plug the connector to your EV to start charging.
						</Typography>
						<Typography
							variant='subtitle2'
							sx={{ color: "red", textAlign: "center" }}>
							(If connector is already plugged, unplug it then plug it again.)
						</Typography>
					</div>
				) : ChargeStep === "PLUGGED-IN" ? (
					<div className='status-details'>
						<CheckCircleIcon sx={{ width: "48px", height: "48px" }} />
						<Typography
							variant='h6'
							sx={{ textAlign: "center" }}>
							Plugged In!
						</Typography>
					</div>
				) : ChargeStep === "INITIALIZATION" ? (
					<div className='status-details'>
						<HistoryToggleOffIcon sx={{ width: "48px", height: "48px" }} />
						<Typography
							variant='h6'
							sx={{ textAlign: "center" }}>
							Fetching live data, please wait...
						</Typography>
					</div>
				) : (
					<div className='charging-details'>
						{ChargeStep === "CHARGING" && (
							<>
								<div>
									<span className='fw-bold'>Charging Current Value:&nbsp;</span>
									<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.current_amp + " Amps" : "-"}</span>
								</div>
								<div>
									<span className='fw-bold'>Power Delivered:&nbsp;</span>
									<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.kwh_used + " KW-H" : "-"}</span>
								</div>
								<div>
									<span className='fw-bold'>Start Time:&nbsp;</span>
									<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.start_datetime : "-"}</span>
								</div>
								<div>
									<span className='fw-bold'>End Time:&nbsp;</span>
									<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.stop_datetime : "-"}</span>
								</div>
								<div>
									<span className='fw-bold'>Max Duration Left:&nbsp;</span>
									<span>{LiveChargingTimer !== "-" ? LiveChargingTimer + " hrs" : "-"}</span>
								</div>
							</>
						)}

						{(ChargeStep === "OVERSTAYING" || ChargeStep === "COMPLETE") && (
							<>
								<div>
									<span className='fw-bold'>Power Used:&nbsp;</span>
									<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.kwh_used + " KW-H" : "-"}</span>
								</div>
								<div>
									<span className='fw-bold'>Time Used:&nbsp;</span>
									<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.time_used + " hrs" : "-"}</span>
								</div>
								<div>
									<span className='fw-bold'>Total Points Used:&nbsp;</span>
									<span>
										{LiveChargingDetails !== undefined && LiveChargingDetails.price !== null
											? LiveChargingDetails.price
											: "-"}
									</span>
								</div>
							</>
						)}
						{ChargeStep === "CHARGING" && (
							<div>
								<span className='fw-bold'>Points Consumed:&nbsp;</span>
								<span>{LiveChargingDetails !== undefined ? LiveChargingDetails.current_cost : "-"}</span>
							</div>
						)}

						<div>
							<span className='fw-bold'>Remaining Points:&nbsp;</span>
							<span>
								{LiveChargingDetails !== undefined && LiveChargingDetails.remaining_balance !== null
									? LiveChargingDetails.remaining_balance
									: "-"}
							</span>
						</div>
						<div
							style={{
								display: "flex",
								// alignSelf: "flex-end",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "32px",
							}}>
							{ChargeStep !== undefined &&
								(ChargeStep === "CHARGING" ? (
									<>
										<motion.div
											animate={{
												scale: [1, 1.5, 1, 1.5, 1, 1.5, 1.25, 1.125],
											}}
											transition={{
												duration: 3,
												ease: "easeInOut",
												times: [0, 0.2, 0.5, 0.8, 1],
												repeat: Infinity,
												repeatDelay: 0,
											}}>
											<OfflineBoltIcon
												sx={{
													width: "48px",
													height: "48px",
													color: "#1976d2",
													backgroundColor: "#00ff2a",
													borderRadius: "50%",
												}}
											/>
										</motion.div>
										<Typography
											className='mt-3 fw-bold'
											variant='subtitle1'
											color={"#1976d2"}>
											Charging
										</Typography>
									</>
								) : ChargeStep === "OVERSTAYING" ? (
									<>
										<ReportIcon
											sx={{
												width: "48px",
												height: "48px",
												color: "black",
												backgroundColor: "red",
												borderRadius: "50%",
											}}
										/>
										<Typography
											className='fw-bold'
											variant='subtitle1'
											color={"red"}>
											Overstaying, please unplug the connector.
										</Typography>
									</>
								) : (
									<>
										<TaskAltIcon
											sx={{
												width: "48px",
												height: "48px",
												color: "#00ff2a",
												// backgroundColor: "blue",
												// borderRadius: "50%",
											}}
										/>
										<Typography
											className='fw-bold'
											variant='subtitle1'
											color={"success"}>
											Charging Complete!
										</Typography>
									</>
								))}
						</div>
					</div>
				)}
			</AccordionDetails>
		);
	};

	return (
		<div id='modal_cr_details'>
			<motion.div
				className='modal-card card-charging-reservation-details'
				initial={{ y: "100%" }}
				// animate={{ x: 100 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-header'>
					<span className='header-blank'>&nbsp;</span>
					<span>Charging and Reservation Status</span>
					<span>
						<CloseIcon
							className='modal-close-btn'
							onClick={toggleModalClose}
						/>
					</span>
				</div>
				<div
					className={
						ModalStep === "DEFAULT"
							? "modal-content content-charging-reservation-details"
							: "modal-content content-charging-reservation-details cancel"
					}>
					{ChargingReservationList !== undefined && ChargingReservationList.reservations.length > 0 ? (
						ChargingReservationList.reservations.map((data, index) => {
							// Example usage
							const dateTimeString = data.grace_period;
							const { date, time } = formatDateAndTime(dateTimeString);
							const StartTime = TimeConvert(data.start);
							const EndTime = TimeConvert(data.end);

							return (
								<>
									{ModalStep === "DEFAULT" ? (
										<Accordion defaultExpanded={index === 0}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls='panel3-content'
												id='panel3-header'>
												#{index + 1}&nbsp;
												{data.status === "ACTIVE"
													? "Reservation Details"
													: ChargeStep === "OVERSTAYING" || ChargeStep === "COMPLETE"
													? "Charging Transaction Summary"
													: "Charging Details"}
											</AccordionSummary>
											{data.status === "VIRTUAL_ACTIVE" ? (
												<ChargingStepDisplay />
											) : (
												<>
													<div className='px-4 pb-4'>
														<div>
															{/* Station: PHPNCE0000000001 Reserved Date: March 1, 2024 Reserved Time: 6:00 AM - 2:00 PM */}
															<span className='fw-bold'>Station:&nbsp;</span>
															<span>{data.station}</span>
														</div>
														<div>
															<span className='fw-bold'>Reserved Date:&nbsp;</span>
															<span>{date}</span>
														</div>
														<div>
															<span className='fw-bold'>Reserved Time:&nbsp;</span>
															<span>
																{StartTime} - {EndTime}
															</span>
														</div>
													</div>

													<AccordionActions sx={{ flexDirection: "column-reverse", gap: 2 }}>
														<Button
															color='error'
															onClick={() => setModalStep("CANCELLATION")}>
															Cancel Reservation
														</Button>
														<Tooltip
															title={ChargerStatus === "NOT_READY" && "Reserved time has not been met yet."}
															placement='bottom-start'
															enterTouchDelay={0}>
															<span>
																<Button
																	disabled={ChargerStatus === "NOT_READY" ? true : false}
																	variant='contained'
																	onClick={() => toggleUseReservation(data.id)}>
																	Activate Charger
																</Button>
															</span>
														</Tooltip>
														{/* <Button
													variant='contained'
													disabled={ChargerStatus === "NOT_READY"}>
													Activate Charger
												</Button> */}
													</AccordionActions>
												</>
											)}
										</Accordion>
									) : (
										<>
											{data.status === "ACTIVE" && (
												<>
													<div className='submodal-reserve-confirm text-center mb-4'>
														<span>Are you sure you want to cancel your reservation?</span>
													</div>

													<div className='submodal-btn-group-to-cancel'>
														<Button
															variant='text'
															sx={{ fontSize: ".75rem" }}
															onClick={() => setModalStep("DEFAULT")}>
															No
														</Button>
														<Button
															color='error'
															variant='contained'
															onClick={() =>
																toggleReservationCancel(
																	data.id,
																	data.evse_uid,
																	data.evse_timeslot_id,
																	data.connector_id
																)
															}
															sx={{ marginBottom: 1, width: "max-content", display: "flex", alignItems: "center" }}>
															Yes
														</Button>
													</div>
												</>
											)}
										</>
									)}
								</>
							);
						})
					) : (
						<ChargingStepDisplay />
					)}
				</div>
			</motion.div>
		</div>
	);
};

export default ModalChargingReservationDetails;
