import "./css/submodal.css";
import SubModalTransHistory from "./components/submodal_TransHistory";
import SubModalChangePass from "./components/submodal_ChangePass";
import SubModalPaymentMethod from "./components/submodal_PaymentMethod";
import SubModalResConfirm from "./components/submodal_ReservationConfirmation";
import SubModalReviewList from "./components/submodal_ReviewList";
import SubModalEnlargedImage from "./components/submodal_EnlargedImage";
import SubModalLogout from "./components/submodal_Logout";
import SubModalTCPP from "./components/submodal_TCPP";
import SubModalContactUs from "./components/submodal_ContactUs";
import SubModalChargeNow from "./components/submodal_ChargeNow";

const SubModal = (props) => {
	return (
		<div id='system_submodal'>
			{props.onWhatSubModal === "submodal_trans_history" ? (
				<SubModalTransHistory onSubModalClose={props.onSubModalVisibility} />
			) : props.onWhatSubModal === "submodal_change_pass" ? (
				<SubModalChangePass onSubModalClose={props.onSubModalVisibility} />
			) : props.onWhatSubModal === "submodal_payment_method" ? (
				<SubModalPaymentMethod
					onSubModalClose={props.onSubModalVisibility}
					onPaymentMethod={props.onPaymentMethod}
					onAlertVisibility={props.onAlertVisibility}
				/>
			) : props.onWhatSubModal === "submodal_review_list" ? (
				<SubModalReviewList onSubModalClose={props.onSubModalVisibility} />
			) : props.onWhatSubModal === "submodal_enlarged_image" ? (
				<SubModalEnlargedImage onSubModalClose={props.onSubModalVisibility} />
			) : props.onWhatSubModal === "submodal_logout" ? (
				<SubModalLogout
					onSubModalClose={props.onSubModalVisibility}
					onModalVisibility={props.onModalVisibility}
					onAlertVisibility={props.onAlertVisibility}
					onIsLoggedIn={props.onIsLoggedIn}
				/>
			) : props.onWhatSubModal === "submodal_terms_condition_privacy_policy" ? (
				<SubModalTCPP
					onSubModalClose={props.onSubModalVisibility}
					onWhatType={props.onWhatType}
				/>
			) : props.onWhatSubModal === "submodal_contact_us" ? (
				<SubModalContactUs onSubModalClose={props.onSubModalVisibility} />
			) : props.onWhatSubModal === "submodal_charge_now" ? (
				<SubModalChargeNow
					onToggleSubModal={props.onSubModalVisibility}
					onWhatSlotData={props.onWhatSlotData}
					onAlertVisibility={props.onAlertVisibility}
					onModalVisibility={props.onModalVisibility}
					onSubModalClose={props.onSubModalVisibility}
				/>
			) : (
				<SubModalResConfirm
					onModalVisibility={props.onModalVisibility}
					onSubModalClose={props.onSubModalVisibility}
					onAlertVisibility={props.onAlertVisibility}
					onWhatSlotData={props.onWhatSlotData}
					onWhatReservationData={props.onWhatReservationData}
					onExpand={props.onExpand}
					onRefreshSlot={props.onRefreshSlot}
				/>
			)}
		</div>
	);
};
export default SubModal;
