import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import React, { useEffect, useState, useRef, useContext } from "react";
import { motion } from "framer-motion";
import MessengerLogo from "../../../assets/messenger_logo.png";
import CallLogo from "../../../assets/phone_logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "react-bootstrap";
import "../css/modal.css";

import { renderTheme } from "../../../App";

import TryLogout from "../../../functions/TryLogout";
import DecryptData from "../../../functions/DecryptData";

const variants = {
	open: { opacity: 1, x: "0%" },
	closed: { opacity: "0%", x: "100%" },
	// open: { x: "0%", opacity: 1 },
	// closed: { x: "100%", opacity: 0 },
};

const ModalSideMenu = (props) => {
	//RENDER THEME
	const { UserDetails } = useContext(renderTheme);
	const [UserData, setUserData] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const modalRef = useRef(null);

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};
	const toggleLogout = (event, res) => {
		props.onSubModalVisibility(true, "submodal_logout");
	};

	const toggleTransHistory = () => {
		props.onSubModalVisibility(true, "submodal_trans_history");
	};
	const toggleChangePass = () => {
		props.onSubModalVisibility(true, "submodal_change_pass");
	};
	const toggleContactUs = () => {
		props.onSubModalVisibility(true, "submodal_contact_us");
	};
	// const togglePaymentMethod = (whatMethod) => {
	// 	props.onSubModalVisibility(true, "submodal_payment_method", whatMethod);
	// };

	const handleClickOutside = (event, submodal) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setTimeout(() => {
				if (submodal === undefined) {
					toggleModalClose();
				}
			}, 125);
		}
	};

	useEffect(() => {
		setIsOpen(true);

		const listener = (e) => handleClickOutside(e, props.onWhatSubModal);

		document.addEventListener("mousedown", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
		};
	}, [props.onWhatSubModal]);

	useEffect(() => {
		if (UserDetails !== undefined) {
			setUserData(UserDetails);
			console.log(UserDetails);
		}
	}, [UserDetails]);

	function importAll(r) {
		let images = {};
		r.keys().forEach((item, index) => {
			images[item.replace("./", "")] = r(item);
		});
		return images;
	}

	const getImage = importAll(require.context("../../../assets", false, /\.(png|jpe?g|svg)$/));

	return (
		<div id='modal_sidemenu'>
			<motion.div
				className='modal-card card-sidemenu'
				initial={{ opacity: 0, x: "100%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.25 }}
				ref={modalRef}>
				<div className='modal-header'>
					<span className='sidemenu-welcome'>
						Hi!,&nbsp;<span id='sidemenu_username'>{UserData !== undefined ? UserData.name : "-"}</span>.
					</span>
					<span>&nbsp;</span>
					<span>
						<KeyboardTabIcon
							className='modal-close-btn'
							onClick={toggleModalClose}
						/>
					</span>
				</div>
				<div className='sidemenu-option-list'>
					<div className='sidemenu-user-card'>
						<div className='usercard_rfid_balance'>
							<span className='balance-label'>RFID Balance Points:</span>
							<span className='balance-price'>{UserData !== undefined ? UserData.balance : "-"}</span>
						</div>
						<div className='usercard-topup'>
							<button
								className='btn top-up-btn'
								onClick={() => props.onSubModalVisibility(true, "submodal_payment_method")}>
								Top Up <AddCircleIcon />
							</button>
						</div>
					</div>
					<div className='sidemenu-settings'>
						<div
							className='settings-options'
							id='settings_transaction_history'>
							<span onClick={toggleTransHistory}>View Transaction History</span>
						</div>
						<div
							className='settings-options'
							id='settings_change_password'>
							<span onClick={toggleChangePass}>Change Password</span>
						</div>

						<div
							className='settings-options'
							id='settings_contact_us'>
							<span onClick={toggleContactUs}>Contact Us</span>
						</div>
						<div
							disabled
							className='settings-options'
							id='settings_how_tos'>
							<span>How To's</span>
						</div>
					</div>
					<div className='sidemenu-misc'>
						<span
							className='misc-btn-logout'
							onClick={() => props.onSubModalVisibility(true, "submodal_logout")}>
							Logout
						</span>
						<span
							className='misc-btn-termscondition'
							onClick={() =>
								props.onSubModalVisibility(
									true,
									"submodal_terms_condition_privacy_policy",
									undefined,
									undefined,
									undefined,
									undefined,
									undefined,
									"viewing"
								)
							}>
							Terms and Condition
						</span>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default ModalSideMenu;
