import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../css/submodal.css";

import SupportMessage from "../../../assets/support_messages.png";
import SupportCall from "../../../assets/support_call.png";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalContactUs = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};
	useEffect(() => {
		setIsOpen(true);
	}, []);

	return (
		<div id='submodal_contactus'>
			<motion.div
				className='submodal-card card-contactus'
				initial={{ scale: 0, translateY: "-15%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					<span className='header-blank'>&nbsp;</span>
					<span>How can we assist you today?</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				<div
					className='p-3'
					style={{ fontSize: ".95rem" }}>
					<span>Choose how you'd like to connect with us:</span>
				</div>
				<div className='px-3 pb-3 support-btn-container'>
					<div className='support-type-holder'>
						<div className='type-btn'>
							<img src={SupportMessage} />
							<h6 style={{ marginTop: "8px", textAlign: "center", whiteSpace: "nowrap" }}>Message Us</h6>
						</div>
						<span style={{ fontSize: ".85rem", padding: "4px" }}>
							Have a question or feedback? Send us a message, and our team will get back to you shortly.
						</span>
					</div>
					<div className='support-type-holder'>
						<div className='type-btn'>
							<img src={SupportCall} />
							<h6 style={{ marginTop: "8px", textAlign: "center", whiteSpace: "nowrap" }}>Call Us</h6>
						</div>

						<span style={{ fontSize: ".85rem", padding: "4px" }}>
							Need immediate assistance? Give us a call, and we'll be happy to help.
						</span>
					</div>
				</div>

				{/* <div className='submodal-form gap-2'>
					<TextField
						id='txt_old_password'
						type='password'
						className='col-8'
						label='Old Password'
						variant='outlined'
					/>
					<TextField
						id='txt_new_password'
						type='password'
						className='col-8'
						label='New Password'
						variant='outlined'
					/>
					<TextField
						id='txt_confirm_password'
						type='password'
						className='col-8'
						label='Confirm Password'
						variant='outlined'
					/>
					<Button variant='contained'>Save</Button>
				</div> */}
			</motion.div>
		</div>
	);
};

export default SubModalContactUs;
