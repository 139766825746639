const ChangePassword = async (access_token, old_password, new_password, confirm_password) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);

    const raw = JSON.stringify({
        old_password,
        new_password,
        confirm_password
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/login/api/auth/v1/change-old-password`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                console.log("Unauthorized: Token invalid.");
            } else {
                console.log(`Server error: ${response.status}`);
            }
            return response.json();
        } else {
            return response.json();
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default ChangePassword;