import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import validator from "validator";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Chip,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import "../css/submodal.css";

import ChangePassword from "../../../functions/ChangePassword";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalChangePass = (props) => {
	const [OldPassword, setOldPassword] = useState();
	const [NewPassword, setNewPassword] = useState();
	const [ConfirmPassword, setConfirmPassword] = useState();

	const [OldPassVisible, setOldPassVisible] = useState(false);
	const [NewPassVisible, setNewPassVisible] = useState(false);
	const [ConfirmPassVisible, setConfirmPassVisible] = useState(false);

	const [OldPassErrors, setOldPassErrors] = useState([]);
	const [NewPassErrors, setNewPassErrors] = useState([]);
	const [ConfirmPassErrors, setConfirmPassErrors] = useState([]);

	const [ChangeRequestResult, setChangeRequestResult] = useState();
	const [IsLoading, setIsLoading] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};

	const handlePasswordChange = (e, whatPass) => {
		let tmp_pass_errors;
		if (whatPass === "OLD") {
			tmp_pass_errors = [...OldPassErrors];
		} else if (whatPass === "NEW") {
			tmp_pass_errors = [...NewPassErrors];
		} else {
			tmp_pass_errors = [...ConfirmPassErrors];
		}
		const input = e.target.value;

		// LENGTH VALIDATION
		const length_validation = validator.isLength(input, { min: 6 });
		if (length_validation === false && input.length > 0) {
			if (!tmp_pass_errors.includes("ERROR_LENGTH")) {
				tmp_pass_errors.push("ERROR_LENGTH");
			}
		} else {
			if (tmp_pass_errors.includes("ERROR_LENGTH")) {
				tmp_pass_errors = tmp_pass_errors.filter((item) => item !== "ERROR_LENGTH");
			}
		}

		// ALPHA NUMERIC VALIDATION
		const alpha_numeric_validation = validator.isAlphanumeric(input);
		if (alpha_numeric_validation === false && input.length > 0) {
			if (!tmp_pass_errors.includes("ERROR_ALPHA_NUM")) {
				tmp_pass_errors.push("ERROR_ALPHA_NUM");
			}
		} else {
			if (tmp_pass_errors.includes("ERROR_ALPHA_NUM")) {
				tmp_pass_errors = tmp_pass_errors.filter((item) => item !== "ERROR_ALPHA_NUM");
			}
		}

		if (alpha_numeric_validation === true && length_validation === true) {
			tmp_pass_errors = "IS_VALID";
		}

		if (whatPass === "OLD") {
			setOldPassErrors(tmp_pass_errors);
			setOldPassword(e.target.value);
		} else if (whatPass === "NEW") {
			setNewPassErrors(tmp_pass_errors);
			setNewPassword(e.target.value);
		} else {
			setConfirmPassErrors(tmp_pass_errors);
			setConfirmPassword(e.target.value);
		}
	};

	const handleMouseDownPassword = (event) => event.preventDefault();
	const handleClickShowOldPass = () => setOldPassVisible((show) => !show);
	const handleClickShowNewPass = () => setNewPassVisible((show) => !show);
	const handleClickShowConfirmPass = () => setConfirmPassVisible((show) => !show);

	const toggleSavePassword = () => {
		setChangeRequestResult();
		const saved_refreshToken = localStorage.getItem("refresh_token");
		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			setIsLoading(true);
			let request = TokenCheck(refreshToken);
			request.then((data) => {
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");

					if (savedToken !== null) {
						let reqToken;
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}
						let saveRequest = ChangePassword(reqToken, OldPassword, NewPassword, ConfirmPassword);
						saveRequest.then((res) => {
							setIsLoading(false);
							if (res.message === "SUCCESS") {
								setOldPassword("");
								setNewPassword("");
								setConfirmPassword("");
								setOldPassVisible(false);
								setNewPassVisible(false);
								setConfirmPassVisible(false);
								setOldPassErrors([]);
								setNewPassErrors([]);
								setConfirmPassErrors([]);
							}
							setChangeRequestResult(res.message);
						});
					}
				}
			});
		}
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	// useEffect(() => {
	// 	OldPassErrors.length > 0 && console.log("[OldPassErrors]:", OldPassErrors);
	// 	NewPassErrors.length > 0 && console.log("[NewPassErrors]:", NewPassErrors);
	// 	ConfirmPassErrors.length > 0 && console.log("[ConfirmPassErrors]:", ConfirmPassErrors);
	// }, [OldPassErrors, NewPassErrors, ConfirmPassErrors]);

	return (
		<div id='submodal_changepass'>
			<motion.div
				className='submodal-card card-changepass'
				initial={{ scale: 0, translateY: "-15%" }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					<span className='header-blank'>&nbsp;</span>
					<span>Change Password</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				<div className='submodal-description p-3'>
					<span>
						The new password should have a minimum of six (6) alphanumeric characters only, without special characters.
					</span>
				</div>
				<div className='submodal-form gap-2'>
					<FormControl
						className='col-8'
						variant='outlined'>
						<InputLabel htmlFor='outlined-adornment-password'>Old Password</InputLabel>
						<OutlinedInput
							id='outlined-adornment-password'
							type={OldPassVisible ? "text" : "password"}
							onChange={(e) => handlePasswordChange(e, "OLD")}
							value={OldPassword}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowOldPass}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{OldPassVisible ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							label='Old Password'
						/>
					</FormControl>
					<FormControl
						className='col-8'
						variant='outlined'>
						<InputLabel htmlFor='outlined-adornment-password'>New Password</InputLabel>
						<OutlinedInput
							id='outlined-adornment-password'
							type={NewPassVisible ? "text" : "password"}
							onChange={(e) => handlePasswordChange(e, "NEW")}
							value={NewPassword}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowNewPass}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{NewPassVisible ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							label='New Password'
						/>
						{NewPassErrors !== undefined && NewPassErrors !== "IS_VALID" && (
							<FormHelperText
								error
								id='accountId-error'>
								{NewPassErrors.map((data) => {
									return (
										<>
											&#x2022;&nbsp;
											{data === "ERROR_LENGTH" ? "Minimum 6 characters not met." : "Special character/s not allowed."}
											&nbsp;
										</>
									);
								})}
							</FormHelperText>
						)}
					</FormControl>
					<FormControl
						className='col-8'
						variant='outlined'>
						<InputLabel htmlFor='outlined-adornment-password'>Confirm Password</InputLabel>
						<OutlinedInput
							id='outlined-adornment-password'
							type={ConfirmPassVisible ? "text" : "password"}
							onChange={(e) => handlePasswordChange(e, "CONFIRM")}
							value={ConfirmPassword}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowConfirmPass}
										onMouseDown={handleMouseDownPassword}
										edge='end'>
										{ConfirmPassVisible ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							label='Confirm Password'
						/>
						{ConfirmPassErrors !== undefined && ConfirmPassErrors !== "IS_VALID" && (
							<FormHelperText
								error
								id='accountId-error'>
								{ConfirmPassErrors.map((data) => {
									return (
										<>
											&#x2022;&nbsp;
											{data === "ERROR_LENGTH" ? "Minimum 6 characters not met." : "Special character/s not allowed."}
											&nbsp;
										</>
									);
								})}
							</FormHelperText>
						)}
					</FormControl>
					{ChangeRequestResult !== undefined && ChangeRequestResult !== "SUCCESS" && (
						<Chip
							color='error'
							label={
								ChangeRequestResult === "INCORRECT_OLD_PASSWORD"
									? "Old password is incorrect."
									: "Password does not match."
							}
						/>
					)}
					<Tooltip
						title={
							(NewPassErrors !== "IS_VALID" || ConfirmPassErrors !== "IS_VALID") && "Password requirements not met."
						}>
						<span>
							<Button
								disabled={
									(OldPassword !== undefined && OldPassword.length < 1) ||
									(NewPassword !== undefined && NewPassword.length < 1) ||
									(ConfirmPassword !== undefined && ConfirmPassword.length < 1) ||
									(NewPassErrors !== undefined && NewPassErrors !== "IS_VALID") ||
									(ConfirmPassErrors !== undefined && ConfirmPassErrors !== "IS_VALID") ||
									IsLoading
								}
								variant='contained'
								onClick={toggleSavePassword}>
								{IsLoading ? (
									<CircularProgress sx={{ width: "20px!important", height: "20px!important", color: "white" }} />
								) : (
									"Save"
								)}
							</Button>
						</span>
					</Tooltip>
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalChangePass;
