import { useEffect, useState, useContext } from "react";

import { motion } from "framer-motion";
import "../css/modal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import OTPField from "./subcomponents/OTPField";

import FP_SendOTP from "../../../functions/FP_SendOTP";
import FP_VerifyOTP from "../../../functions/FP_VerifiyOTP";
import EncryptData from "../../../functions/EncryptData";
import ResetPassword from "../../../functions/ResetPassword";

import { renderTheme } from "../../../App";

const variants = {
	open: { opacity: 1, y: "-10%" },
	closed: { opacity: 0, y: "-100%" },
};

const ModalForgotPassword = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [Email, setEmail] = useState("");
	const [OnWhatStep, setOnWhatStep] = useState("step1");

	const [IsLoading, setIsLoading] = useState("");

	const [PassCountErr, setPassCountErr] = useState(false);
	const [ConfirmPassCountErr, setConfirmPassCountErr] = useState(false);
	const [ErrorPass, setErrorPass] = useState(false);
	const [ErrorVerify, setErrorVerify] = useState("");
	const [Password, setPassword] = useState("");
	const [ShowPassword, setShowPassword] = useState(false);
	const [ConfirmPassword, setConfirmPassword] = useState("");
	const [ResendDisabled, setResendDisabled] = useState(false);

	const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);
	const [otp, setOtp] = useState("");
	const [UserID, setUserID] = useState("");
	const [SendOTPError, setSendOTPError] = useState();

	const [CountTimer, setCountTimer] = useState({
		minutes: 0,
		seconds: 0,
	});
	const [isRunning, setIsRunning] = useState(false);

	// RENDER THEME
	const { RenderType } = useContext(renderTheme);

	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		"label + &": {
			marginTop: theme.spacing(3),
		},
		"& .MuiInputBase-input": {
			borderRadius: 4,
			position: "relative",
			// backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
			border: "1px solid",
			borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
			fontSize: 16,
			width: "100%",
			padding: "10px 12px",
			transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"&:focus": {
				boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.main,
			},
		},
	}));

	useEffect(() => {
		let timer;

		if (isRunning) {
			timer = setInterval(() => {
				if (CountTimer.minutes === 0 && CountTimer.seconds === 0) {
					clearInterval(timer);
					setIsRunning(false);
					// Handle timer completion (e.g., show a message, trigger an action)
				} else {
					setCountTimer((prevTime) => {
						if (prevTime.seconds === 0) {
							return {
								minutes: prevTime.minutes - 1,
								seconds: 59,
							};
						} else {
							return {
								minutes: prevTime.minutes,
								seconds: prevTime.seconds - 1,
							};
						}
					});
				}
			}, 1000);
		}

		// Clean up the interval on component unmount or when isRunning becomes false
		return () => clearInterval(timer);
	}, [CountTimer, isRunning]);

	const formatTime = (value) => (value < 10 ? `0${value}` : value);

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	useEffect(() => {
		console.log(UserID);
	}, [UserID]);

	const toggleReset = (event, type) => {
		setIsLoading(type);
		if (Email.length > 0) {
			let request = FP_SendOTP(Email);
			request.then((res) => {
				if (res.status === 200) {
					setUserID(res.data.USER_ID);
					setIsLoading("");
					setOnWhatStep("step2");
				} else {
					if (res === "USER_NOT_FOUND") {
						setIsLoading("");
						setSendOTPError(res);
					}
				}
			});
		} else {
			setTimeout(() => {
				setIsLoading("");
				setSendOTPError("FIELD_BLANK");
			}, 500);
		}
		// props.onModalClose(true, "modal_loading");
	};

	const toggleResend = (event, type) => {
		setIsLoading(type);
		setErrorVerify("");
		// props.onModalClose(true, "modal_loading");
		let request = FP_SendOTP(Email);
		request.then((res) => {
			if (res.status === 200) {
				setUserID(res.data.USER_ID);
				setIsLoading("");
				setResendDisabled(true);
				setCountTimer({
					minutes: 2,
					seconds: 0,
				});
				setIsRunning(true);
			}
		});
	};

	const toggleVerify = () => {
		setIsLoading("verify");
		let request = FP_VerifyOTP(UserID, otp);
		request.then((res) => {
			if (res.status === 200) {
				setErrorPass("");
				setOnWhatStep("step3");
			} else {
				if (res.data === "OTP_IS_INCORRECT") {
					setErrorVerify("incorrect");
				} else if (res.data === "INVALID") {
					setErrorVerify("max_attempts");
				} else {
					setErrorVerify("expired");
				}
			}
			setIsLoading("");
		});
	};

	const toggleSave = () => {
		if (Password !== ConfirmPassword) {
			setErrorPass(true);
			setTimeout(() => {
				setErrorPass(false);
			}, 3000);
		} else {
			setIsLoading("save");
			let request = ResetPassword(UserID, Password);
			request.then((res) => {
				if (res.status === 200) {
					setIsLoading("");
					props.onModalClose(false, "");
					props.onAlertVisibility(true, "Password successfully changed!", "success");
				}
			});
		}
	};

	const handleEmailInput = (e) => {
		setEmail(e.target.value);
	};

	const handlePasswordInput = (e) => {
		setPassword(e.target.value);
	};
	const handlePassInputBlur = (e) => {
		let pass = e.target.value;
		if (pass.length < 8) {
			setPassCountErr(true);
		} else {
			setPassCountErr(false);
		}
	};

	const handleConfirmPassInputBlur = (e) => {
		let pass = e.target.value;
		if (pass.length < 8) {
			setConfirmPassCountErr(true);
		} else {
			setConfirmPassCountErr(false);
		}
	};

	const handleConfirmPasswordInput = (e) => {
		setConfirmPassword(e.target.value);
	};

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<div id='modal_login'>
			<motion.div
				className='modal-card card-login'
				initial={{ opacity: 0, y: "-100%" }}
				// animate={{ x: 100 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-header'>
					<span className='header-blank'>&nbsp;</span>
					<span>
						{OnWhatStep === "step1" ? "Forgot Password" : OnWhatStep === "step2" ? "Verify OTP" : "Reset Password"}
					</span>
					<span className='header-close'>
						<CloseIcon
							className='modal-close-btn'
							onClick={toggleModalClose}
						/>
					</span>
				</div>
				{/* STEP 1: "FORGOT PASSWORD" */}
				{OnWhatStep === "step1" ? (
					<div className='modal-content content-forgot-password'>
						<div className='form-input'>
							{/* <FormControl variant='standard'>
								<InputLabel
									shrink
									htmlFor='form_txt_email'>
									Email
								</InputLabel>
								<BootstrapInput
									error={SendOTPError}
									value={Email}
									helperText={
										SendOTPError === "USER_NOT_FOUND"
											? "Email not found."
											: SendOTPError === "FIELD_BLANK"
											? "Email can't be left blank."
											: ""
									}
									size='small'
									id='form_txt_email'
									onChange={handleEmailInput}
									onFocus={() => setSendOTPError()}
								/>
							</FormControl> */}
							<TextField
								error={SendOTPError}
								label='Email'
								value={Email}
								helperText={
									SendOTPError === "USER_NOT_FOUND"
										? "Email not found."
										: SendOTPError === "FIELD_BLANK"
										? "Email can't be left blank."
										: ""
								}
								size='small'
								id='form_txt_email'
								onChange={handleEmailInput}
								onFocus={() => setSendOTPError()}
							/>
							{/* <label htmlFor='form_txt_email'>Email</label>
							<input
								type='email'
								className='form-control'
								value={Email}
								onChange={handleEmailInput}
								id='form_txt_email'
							/> */}
						</div>
						<div>
							<button
								className='my-2 btn btn-primary'
								type='button'
								onClick={(event) => toggleReset(event, "reset")}>
								{IsLoading === "reset" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : (
									"Reset"
								)}
							</button>
						</div>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Button
								style={{ textTransform: "none", color: "#ff8f00" }}
								onClick={() => props.onModalClose(true, "modal_login")}>
								Back
							</Button>
						</div>
					</div>
				) : // STEP 2: "VERIFY OTP"
				OnWhatStep === "step2" ? (
					<div className='modal-content content-forgot-password'>
						<OTPField
							otp={otp}
							setOtp={setOtp}
						/>
						{ErrorVerify && (
							<FormHelperText sx={{ color: "red", marginTop: "0.5rem" }}>
								{ErrorVerify === "expired"
									? "OTP Expired."
									: ErrorVerify === "incorrect"
									? "Incorrect OTP."
									: "Maximum of 3 attempts has been reached. Try resending OTP."}
							</FormHelperText>
						)}
						<div className='mt-2'>
							<button
								className='my-2 btn btn-primary'
								type='button'
								onClick={toggleVerify}>
								{IsLoading === "verify" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : (
									"Verify"
								)}
							</button>
						</div>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Button
								style={{ textTransform: "none", color: "#ff8f00" }}
								onClick={(event) => toggleResend(event, "resend")}
								disabled={isRunning}>
								{IsLoading === "resend" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : isRunning ? (
									`Resend (${formatTime(CountTimer.minutes)}:${formatTime(CountTimer.seconds)})`
								) : (
									"Resend"
								)}
							</Button>
						</div>
					</div>
				) : (
					// STEP 3: SET NEW PASSWORD
					<div className='modal-content content-forgot-password'>
						<FormControl
							sx={{ m: 1, width: "65%" }}
							variant='outlined'>
							<InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
							<OutlinedInput
								id='outlined-adornment-password'
								type={ShowPassword ? "text" : "password"}
								value={Password}
								onChange={handlePasswordInput}
								onBlur={handlePassInputBlur}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'>
											{!ShowPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label='Password'
							/>
							{PassCountErr && (
								<FormHelperText sx={{ color: "red" }}>Password should be a minimum of 8 characters.</FormHelperText>
							)}
						</FormControl>
						<FormControl
							sx={{ m: 1, width: "65%" }}
							variant='outlined'>
							<InputLabel htmlFor='outlined-adornment-password'>Confirm Password</InputLabel>
							<OutlinedInput
								id='outlined-adornment-password'
								type={ShowConfirmPassword ? "text" : "password"}
								value={ConfirmPassword}
								onChange={handleConfirmPasswordInput}
								onBlur={handleConfirmPassInputBlur}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowConfirmPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'>
											{!ShowConfirmPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label='Confirm Password'
							/>
							{ConfirmPassCountErr && (
								<FormHelperText sx={{ color: "red" }}>
									Confirm Password should be a minimum of 8 characters
								</FormHelperText>
							)}
						</FormControl>
						{ErrorPass && <FormHelperText sx={{ color: "red" }}>Passwords don't match.</FormHelperText>}
						<div className='mt-2'>
							<button
								className='my-2 btn btn-primary'
								type='button'
								onClick={toggleSave}>
								{IsLoading === "save" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : (
									"Save"
								)}
							</button>
						</div>
					</div>
				)}
			</motion.div>
		</div>
	);
};

export default ModalForgotPassword;
