import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import Button from "@mui/material/Button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import "../css/bookingstatus.css";

import { renderTheme } from "../../../App";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";
import GetReservationList from "../../../functions/GetReservationList";

const Menu = (props) => {
	const [Time_current, setTime_current] = useState(0);
	const [BookingDetails, setBookingDetails] = useState();
	const [SlotTime, setSlotTime] = useState("-");

	const toggleModalReservationDetails = (e) => {
		const saved_refreshToken = localStorage.getItem("refresh_token");
		if (saved_refreshToken !== null) {
			let refreshToken = DecryptData("refresh_token");
			let request = TokenCheck(refreshToken);
			request.then((data) => {
				if (data.status === "PASSED") {
					let savedToken = localStorage.getItem("access_token");

					if (savedToken !== null) {
						let reqToken;
						if (data.action === "REFRESHED") {
							reqToken = data.access_token;
						} else {
							reqToken = DecryptData("access_token");
						}
						let request = GetReservationList(reqToken);
						console.log(request);
						request.then((res) => {
							console.log("[Reservation_List]:", res);
							if (res !== undefined) {
								setChargingReservationDetails(res.data);
							}
						});
					}
				}
			});
		}
		props.onModalVisibility(true, "modal_charging_reservation_details");
	};

	// RENDER THEME
	const { isMobile, StatusChange, ChargingReservationDetails, setChargingReservationDetails, LiveChargingDetails } =
		useContext(renderTheme);

	useEffect(() => {
		let current = new Date(Time_current);
		let Time_reservation = new Date("Jun 08 2023 12:00:00");
		let current_ToTime = current.getTime();
		let reservation_ToTime = Time_reservation.getTime();
		let countdown_time = reservation_ToTime - current_ToTime;
		let rawTime = new Date(current.setTime(countdown_time));

		// setTimer(new Date(rawTime).toISOString().slice(11, 19));
		// testSetTime.getHours(),testSetTime.getMinutes(),testSetTime.getSeconds()
		setTimeout(() => {
			setTime_current(new Date());
		}, 1000);
	}, [Time_current]);

	useEffect(() => {
		setTime_current(new Date());
	}, []);

	useEffect(() => {
		console.log("[Booking_Details]:", ChargingReservationDetails);
		if (ChargingReservationDetails !== undefined && ChargingReservationDetails.is_charging === 1) {
			const slot_Time =
				toTime(ChargingReservationDetails.timeslot.start) + " - " + toTime(ChargingReservationDetails.timeslot.end);
			setSlotTime(slot_Time);
			setBookingDetails({
				status: "CHARGING",
				location: ChargingReservationDetails.location,
			});
		} else {
			if (ChargingReservationDetails !== undefined && ChargingReservationDetails.reservations.length > 0) {
				const slot_Time =
					toTime(ChargingReservationDetails.reservations[0].start) +
					" - " +
					toTime(ChargingReservationDetails.reservations[0].end);
				setSlotTime(slot_Time);
				setBookingDetails(ChargingReservationDetails.reservations[0]);
			}
		}
	}, [ChargingReservationDetails]);

	// useEffect(() => {
	// 	console.log(LiveChargingDetails);
	// }, [LiveChargingDetails]);

	const toTime = (time) => {
		let whatTime = time.replaceAll(":00", "");
		let ap = whatTime >= 12 ? "PM" : "AM";
		let hours = whatTime % 12;

		hours = hours ? hours : 12;
		hours = hours.toString().padStart(2, "");
		let mergeTime = hours + " " + ap;
		return mergeTime;
	};

	return (
		<motion.div
			initial={{ y: -5, opacity: 0 }}
			animate={{ y: 0, opacity: 1 }}
			exit={{ y: -5, opacity: 0 }}
			transition={{ duration: 0.4 }}
			className={
				StatusChange === "USER_RESERVATION"
					? "status-reservation"
					: StatusChange === "USER_OVERSTAYING"
					? "status-overstaying"
					: StatusChange === "grace_period"
					? "status-grace-period"
					: "status-charging"
			}
			id='home_booking_status'
			style={{ top: props.onWhatRenderType === "application" && 0 }}>
			<div className='booking-status-content'>
				<div className='booking-status-details'>
					<div className='status-desc'>
						<span
							className='fw-bold'
							style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "white" }}>
							Charging/Reservation Details:
						</span>
						<div>
							<span
								className='fw-bold'
								style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "orange" }}>
								Status:&nbsp;
							</span>
							<span style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "white" }}>
								{BookingDetails !== undefined && BookingDetails.status === "ACTIVE"
									? "Reserved"
									: (BookingDetails !== undefined && BookingDetails.status === "VIRTUAL_ACTIVE") ||
									  (BookingDetails !== undefined && BookingDetails.status === "CHARGING")
									? "Charging"
									: "-"}
							</span>
						</div>
						<div>
							<span
								className='fw-bold'
								style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "orange" }}>
								Location:&nbsp;
							</span>
							<span style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "white" }}>
								{BookingDetails !== undefined ? BookingDetails.location : "-"}
							</span>
						</div>
						<div>
							<span
								className='fw-bold'
								style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "orange" }}>
								Timeslot:&nbsp;
							</span>
							<span style={{ color: StatusChange === "USER_RESERVATION" ? "black" : "white" }}>{SlotTime}</span>
						</div>
					</div>
					<div
						className={
							StatusChange === "USER_RESERVATION"
								? "booking-status-symbol status-reservation"
								: StatusChange === "grace_period"
								? "booking-status-symbol status-grace-period"
								: "booking-status-symbol status-charging"
						}>
						{StatusChange === "USER_RESERVATION" ? (
							<BeenhereIcon />
						) : StatusChange === "grace_period" ? (
							<ReportRoundedIcon />
						) : (
							<OfflineBoltOutlinedIcon />
						)}
					</div>
				</div>
				<div
					className={`d-flex align-items-end justify-content-center pb-md-1 booking-status-symbol-holder ${
						StatusChange === "USER_RESERVATION" ? "reservation" : "charging"
					}`}>
					{isMobile ? (
						<Button
							sx={{ borderRadius: 0, width: "100%" }}
							onClick={toggleModalReservationDetails}>
							<ArrowRightIcon sx={{ height: "12.5vh", color: "white", width: "100%" }} />
						</Button>
					) : (
						<Button
							onClick={toggleModalReservationDetails}
							variant='text'
							sx={{ color: StatusChange === "USER_RESERVATION" ? "black" : "white" }}
							endIcon={<ArrowRightIcon />}>
							View details
						</Button>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default Menu;
