import React, { useState } from "react";
import OtpInput from "react-otp-input";

const OTPField = (props) => {
	

	return (
		<OtpInput
			value={props.otp}
			onChange={props.setOtp}
			numInputs={6}
			renderSeparator={<span>&nbsp;</span>}
			renderInput={(props) => <input {...props} />}
			inputStyle={{ fontSize: "32px", width: "64px", height: "80px", borderRadius: "8px", borderColor: "#1a6fff" }}
			containerStyle={{ width: "100%", display: "flex", justifyContent: "center" }}
			inputType='tel'
		/>
	);
};

export default OTPField;
