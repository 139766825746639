import { useState, useEffect } from "react";
import TransHistoryTable from "./subcomponents/TransHistory_Table";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import "../css/submodal.css";
import GetTransactionHistory from "../../../functions/GetTransactionHistory";

import TransHistory_MobileTable from "./subcomponents/TransHistory_MobileTable";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalTransHistory = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [FromDateType, setFromDateType] = useState("text");
	const [ToDateType, setToDateType] = useState("text");

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};

	useEffect(() => {
		setIsOpen(true);
	}, []);

	return (
		<div id='submodal_transhistory'>
			<motion.div
				className='submodal-card card-transhistory'
				initial={{ scale: 0 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					<span className='header-blank'>&nbsp;</span>
					<span>Transaction History</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				{/* <div className='submodal-filter gap-2'>
					<div className='col-lg-4 col-5'>
						<input
							type={FromDateType}
							className='form-control'
							placeholder='From'
							onFocus={() => setFromDateType("date")}
							onBlur={() => setFromDateType("text")}
						/>
					</div>
					<div className='col-lg-4 col-5 '>
						<input
							type={ToDateType}
							className='form-control'
							placeholder='To'
							onFocus={() => setToDateType("date")}
							onBlur={() => setToDateType("text")}
						/>
					</div>
				</div> */}
				<TransHistory_MobileTable />
				{/* <TransHistoryTable /> */}
			</motion.div>
		</div>
	);
};

export default SubModalTransHistory;
