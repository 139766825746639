import { useContext, useEffect, useState } from "react";
import Logo_ParkNcharge from "../../../assets/ParkNcharge_Logo.png";
import PNC_Logo from "../../../assets/PNC_Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../css/header.css";
import "../../../index.css";
import { Button } from "@mui/material";

import { renderTheme } from "../../../App";

const Header = (props) => {
	const { isMobile } = useContext(renderTheme);

	useEffect(() => {
		console.log(isMobile);
	}, []);

	const toggleModalOpen = () => {
		if (props.onIfLoggedIn === true) {
			props.onModalVisibility(true, "modal_sidemenu");
			console.log("BEEN HERE.");
		} else {
			props.onModalVisibility(true, "modal_login");
		}
	};

	return (
		<div id='home_header'>
			<div id='header_banner'>
				<img
					src={PNC_Logo}
					id='header_logo'
				/>
				{/* <div id="header_title">
                    <span className="title-string">Park<span className="title-N">N</span>charge</span>
                    <span className="subtitle-string">Building the Electric Vehicle Communities</span>
                </div> */}
			</div>
			{props.onIfLoggedIn || isMobile ? (
				<div
					id='header_menu'
					onClick={toggleModalOpen}>
					<MenuIcon />
					<AccountCircleIcon />
				</div>
			) : (
				<div>
					<Button onClick={() => props.onModalVisibility(true, "modal_signup")}>Register</Button>
					<Button onClick={() => props.onModalVisibility(true, "modal_login")}>Login</Button>
				</div>
			)}
		</div>
	);
};

export default Header;
