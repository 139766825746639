import { io } from 'socket.io-client';
// import jwt from 'jsonwebtoken'
import sign from 'jwt-encode';

const ChargerSocket = (evse_uid, connectorID) => {
    // const URL = process.env.NODE_ENV === 'production' ? undefined : `ws://192.46.227.227:4006?ev_charger_id=${evse_uid}`;
    // const URL = `wss://v2-server-parkncharge.sysnetph.com?ev_charger_id=${evse_uid}`;
    // const URL = process.env.NODE_ENV === 'production' ? undefined : 'wss://socket-server.sysnetph.com/authenticate';
    const URL = 'wss://socket-server.sysnetph.com/authenticate';

    const socket = io(URL, {
        transports: ['websocket', 'polling', 'flashsocket'], autoConnect: false, 'forceNew': true, auth: {
            token: sign(
                { ev_charger_id: evse_uid + "-" + connectorID },
                process.env.REACT_APP_WEB_SOCKET_SERVER_KEY
            ),
        }
    });

    return socket;
}


export default ChargerSocket;