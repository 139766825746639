import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import "../css/submodal.css";
import Carousel from "react-bootstrap/Carousel";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

function importAll(r) {
	let images = {};
	r.keys().forEach((item, index) => {
		images[item.replace("./", "")] = r(item);
	});
	return images;
}

const getImage = importAll(require.context("../../../assets", false, /\.(png|jpe?g|svg)$/));

const SubModalEnlargedImage = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};
	useEffect(() => {
		setIsOpen(true);
	}, []);

	return (
		<div id='submodal_enlargedimage'>
			<motion.div
				className='submodal-card-enlarged-image bg-dark'
				initial={{ scale: 0 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
				transition={{ duration: 0.3 }}>
				<div className='enlarged-image-close'>
					<span className='header-blank'>&nbsp;</span>
					<span className='header-blank'>&nbsp;</span>
					<span className='header-close'>
						<CloseIcon
							className='submodal-close-btn'
							onClick={toggleSubModalClose}
						/>
					</span>
				</div>
				<div className='image-list-carousel'>
					<Carousel
						activeIndex={index}
						onSelect={handleSelect}
						interval={null}
						className='bg-dark'>
						<Carousel.Item>
							<img
								className='d-block w-100 h-75'
								src={getImage["Sample_Location_001.jpg"]}
								alt='First slide'
							/>
							<Carousel.Caption className='w-100 bg-dark'>
								<h3>First slide Image</h3>
								<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								className='d-block w-100 h-75'
								src={getImage["Sample_Location_002.jpg"]}
								alt='Second slide'
							/>

							<Carousel.Caption className='w-100'>
								<h3>Second slide Image</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								className='d-block w-100 h-75'
								src={getImage["Sample_Location_003.jpg"]}
								alt='Third slide'
							/>

							<Carousel.Caption className='w-100'>
								<h3>Third slide Image</h3>
								<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								className='d-block w-100 h-75'
								src={getImage["Sample_Location_004.jpg"]}
								alt='Fourth slide'
							/>

							<Carousel.Caption className='w-100'>
								<h3>Fourth slide Image</h3>
								<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								className='d-block w-100 h-75'
								src={getImage["Sample_Location_005.jpg"]}
								alt='Fifth slide'
							/>

							<Carousel.Caption className='w-100'>
								<h3>Fifth slide Image</h3>
								<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
							</Carousel.Caption>
						</Carousel.Item>
					</Carousel>
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalEnlargedImage;
