const UseReservation = async (accessToken, reservationID) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + accessToken);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/booking_reservation/api/v1/slots/reservations/activate/${reservationID}`,
            requestOptions
        );

        return response.json();
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default UseReservation;