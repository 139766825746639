import { useState, useEffect, useContext } from "react";
import "../css/bottomnavigation.css";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HistoryIcon from "@mui/icons-material/History";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Popover from "@mui/material/Popover";
import MapIcon from "@mui/icons-material/Map";
import LowPriorityIcon from "@mui/icons-material/LowPriority";

import GetFavMerchants from "../../../functions/GetFavMerchants";
import DecryptData from "../../../functions/DecryptData";
import TokenCheck from "../../../functions/TokenCheck";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Badge from "@mui/material/Badge";
import { Box } from "@mui/material";

import { renderTheme } from "../../../App";

const BottomNavigation = (props) => {
	const { isMobile } = useContext(renderTheme);
	const [ButtonActive, setButtonActive] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [UserLocation, setUserLocation] = useState();

	useEffect(() => {
		if (props.onUserPosition) {
			setUserLocation({
				lng: props.onUserPosition.lng,
				lat: props.onUserPosition.lat,
			});
		}
	}, [props.onUserPosition]);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const scrollToTop = () => {
		console.log("Scrolling to top");
		document.documentElement.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const toggleFavoritesButton = () => {
		props.onSetCatMerchantList("");
		// props.onSetWhatShowing("Main");
		if (ButtonActive !== "Favorites") {
			props.onSetActiveCategory("Favorite");
			const saved_refreshToken = localStorage.getItem("refresh_token");

			if (saved_refreshToken !== null) {
				let refreshToken = DecryptData("refresh_token");
				let TCRequest = TokenCheck(refreshToken);
				TCRequest.then((data) => {
					if (data.status === "PASSED") {
						let savedToken = localStorage.getItem("access_token");
						if (savedToken !== null) {
							let reqToken;
							if (data.action === "REFRESHED") {
								reqToken = data.access_token;
							} else {
								reqToken = DecryptData("access_token");
							}

							let request = GetFavMerchants(reqToken, UserLocation.lng, UserLocation.lat);
							request.then((res) => {
								const targetElement = document.getElementById("page_home"); // Change to your actual element ID
								if (targetElement) {
									targetElement.scrollIntoView({ behavior: "smooth" });
								}
								props.onSetCatMerchantList(res.data.favorite_locations);
								props.onModalVisibility(false, "");
								setButtonActive("Favorites");
							});
						}
					}
				});
			}
		} else {
			props.onSetActiveCategory("");
			setButtonActive("");
		}
	};

	const toggleRecentsButton = () => {
		// props.onSetWhatShowing("Main");
		setButtonActive("Recents");
		const targetElement = document.getElementById("page_home"); // Change to your actual element ID
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: "smooth" });
		}

		props.onSetCatMerchantList();
		props.onModalVisibility(false, "");
		props.onSetActiveCategory("Recents");
		// setTimeout(() => {}, 250);
	};
	const toggleListMapButton = () => {
		if (props.onIsShowing === "Map") {
			props.onSetWhatShowing("Main");
		} else {
			props.onSetWhatShowing("Map");
		}
	};

	const toggleFilterButton = () => {
		// setButtonActive("Filter");
		props.onModalVisibility(true, "modal_choosefilter");
	};

	const toggleQRScanner = () => {
		if (isMobile) {
			props.onModalVisibility(true, "modal_qrscanner");
		}
	};

	useEffect(() => {
		if (props.onIfSelected) {
			if (ButtonActive !== "Favorites" && ButtonActive !== "Recents") {
				setButtonActive("");
			}
			props.onSetSelected(false);
		}
	}, [props.onIfSelected]);

	return (
		<div id='home_bottom_nav'>
			<div
				id='bottom_nav_holder'
				style={{ justifyContent: props.onIfLoggedIn === false && "center" }}>
				{props.onIfLoggedIn && (
					<div
						className='nav-item-group left'
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
						aria-owns={open ? "mouse-over-popover" : undefined}
						aria-haspopup='true'>
						{/* <div className="nav-arrow left"><ArrowLeftIcon /></div> */}
						<div className='item-btn-group'>
							<div
								className={
									(ButtonActive === "Favorites" ? "nav-item-btn col-5 active" : "nav-item-btn col-5") +
									(!props.onIfLoggedIn ? " disabled" : "")
								}
								onClick={toggleFavoritesButton}>
								<FavoriteBorderIcon />
								{ButtonActive === "Favorites" && isMobile ? <></> : <span>Favorites</span>}
							</div>
							<div
								className={
									(ButtonActive === "Recents" ? "nav-item-btn col-5 active" : "nav-item-btn col-5") +
									(!props.onIfLoggedIn ? " disabled" : "")
								}
								onClick={toggleRecentsButton}>
								<HistoryIcon />
								{ButtonActive === "Recents" && isMobile ? <></> : <span>Recents</span>}
							</div>
						</div>
						{!props.onIfLoggedIn && (
							<Popover
								id='mouse-over-popover'
								sx={{
									pointerEvents: "none",
									padding: "1%",
								}}
								open={open}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "center",
									horizontal: "center",
								}}
								onClose={handlePopoverClose}
								disableRestoreFocus>
								Feature only available if you're logged in.
							</Popover>
						)}
					</div>
				)}

				{props.onIfLoggedIn && (
					<div id='scan_qr_holder'>
						<span
							className={isMobile ? "bottom-nav-item" : "bottom-nav-item disabled"}
							id='nav_scan_qr'
							onClick={toggleQRScanner}>
							<QrCodeScannerIcon />
						</span>
					</div>
				)}
				<div className={props.onIfLoggedIn ? "nav-item-group right" : "nav-item-group"}>
					{/* <div className="nav-arrow right"><ArrowRightIcon /></div> */}
					<div className='item-btn-group'>
						<div
							className={ButtonActive === "Filter" ? "nav-item-btn col-5 active" : "nav-item-btn col-5"}
							onClick={toggleFilterButton}>
							<Badge
								// badgeContent={4}
								color='error'>
								<Box sx={{ padding: "4px 12px" }}>
									<PlaylistAddCheckIcon />
									{ButtonActive === "Filter" && isMobile ? <></> : <span>Filter</span>}
								</Box>
							</Badge>
						</div>
						<div
							style={{ backgroundColor: "#ff8f00", borderRadius: "18px" }}
							className={"nav-item-btn col-5"}
							onClick={toggleListMapButton}>
							<FormControlLabel
								labelPlacement='top'
								control={
									<Switch
										size='small'
										color='default'
										checked={props.onIsShowing === "Map" ? true : false}
									/>
								}
								sx={{
									// marginRight: "unset!important",
									".MuiFormControlLabel-label": {
										fontWeight: "bold!important",
									},
								}}
								label={props.onIsShowing === "Map" ? "Map" : "List"}
							/>
							{/* {props.onIsShowing === "Map" ? (
								<>
									<LowPriorityIcon sx={{ transform: "rotate(180deg)" }} />
									<span>List</span>
								</>
							) : (
								<>
									<MapIcon />
									<span>Map</span>
								</>
							)} */}
						</div>
					</div>
				</div>
			</div>
			{props.onIfLoggedIn && <div id='bottom_nav_patch'></div>}
		</div>
	);
};

export default BottomNavigation;
