const subtractOneSecond = (startTime, intID = undefined, setLiveChargingTimer) => {
    // Parse the HH:mm:ss string into a Date object (using today’s date)
    const [hours, minutes, seconds] = startTime.split(':').map(Number);
    let currentTime = new Date();
    currentTime.setHours(hours, minutes, seconds, 0); // Set time based on startTime

    let formattedTime = currentTime
        .toLocaleTimeString('en-GB', { hour12: false }); // Initialize formatted time
    if (intID.current !== undefined) {
        clearInterval(intID.current)
    }
    intID.current = setInterval(() => {
        currentTime = new Date(currentTime.getTime() - 1000); // Subtract 1 second (1000 ms)

        // Format the currentTime back to HH:mm:ss
        formattedTime = currentTime
            .toLocaleTimeString('en-GB', { hour12: false }); // Format in 24-hour format (HH:mm:ss)

        setLiveChargingTimer(formattedTime)
        // console.log(formattedTime); // Log the formatted time
    }, 1000);

    let intervalID = intID.current;

    // Return both the formatted time and the intID
    return { formattedTime, intervalID };
};


export default subtractOneSecond;