import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import "../css/submodal.css";
import { renderTheme } from "../../../App";

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const SubModalTCPP = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	// RENDER THEME
	const { RenderType, IsTCPPChecked, setIsTCPPChecked } = useContext(renderTheme);

	const toggleSubModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onSubModalClose(false, undefined);
		}, 250);
	};
	useEffect(() => {
		setIsOpen(true);
	}, []);

	return (
		<div id='submodal_tcpp'>
			<motion.div
				className='submodal-card card-tcpp'
				initial={{ scale: 0 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='submodal-header default'>
					{props.onWhatType === "viewing" ? (
						<span className='header-close'>
							<CloseIcon
								className='submodal-close-btn'
								onClick={toggleSubModalClose}
							/>
						</span>
					) : (
						<span className='header-blank'>&nbsp;</span>
					)}
					<span style={{ textAlign: "center" }}>PARKNCHARGE APP &amp; WEBSITE TERMS AND CONDITIONS</span>

					<span className='header-blank'>&nbsp;</span>
				</div>
				<div className='p-4 submodal-scrollable-view'>
					<span className='tcpp-header'>READ CAREFULLY BEFORE ACCEPTING OUR TERMS AND CONDITION / PRIVACY POLICY.</span>
					<span>
						<br />
						<br />
						By accessing and using the ParkNcharge Mobile App and Website (parkncharge.ph), referred to as App &amp;
						Website, owned and operated by SysNet Integrators, Inc. (the &quot;Company&quot;), You agree to be bound by
						the terms and conditions set forth below. If You do not agree to by bound by this Agreement, do not
						understand the Agreement, or if You need more time to review and consider this Agreement, please leave the
						App &amp; Website immediately. The Company only agrees to provide use of the App &amp; Website and Services
						to You if You assent to this Agreement.
					</span>
					<span className='tcpp-header'>
						<br />
						<br />
						1. DEFINITIONS
					</span>
					<span>
						<br />
						<br />
						The parties referred to in this Agreement shall be defined as follows:
						<br />
						<br />
						&nbsp;&nbsp;&nbsp;a. Company, Us, We: The Company, as the creator, operator, and publisher of the App &amp;
						Website, makes the App &amp; Website and certain Services on it, available to users. SysNet Integrators,
						Inc., Company, We, Us, Our, Ours and other first person pronouns will refer to the Company, as well as all
						employees and affiliates of the Company.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; b. You, the User, the Client: You, as the user of the App &amp; Website will be referred
						to throughout this Agreement with second-person pronouns such as You, Your, Yours, or as User or Client.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; c. Parties: Collectively, the parties to this Agreement (the Company and You) will be
						referred to as Parties.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						2. AGE RESTRICTION
					</span>
					<span>
						<br />
						<br />
						You must be at least Eighteen (18) years of age to use this App &amp; Website or any Services contained
						herein. By using this App &amp; Website, You represent and warrant that You are at least 18 years of age and
						may legally agree to this Agreement. The Company assumes no responsibility or liability for any
						misrepresentation of Your age.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						3. INTELLECTUAL PROPERTY
					</span>
					<span>
						<br />
						<br />
						The App &amp; Website, including all content features in the App &amp; Website including all span, graphics,
						images, logos, trademarks, and the link (the &quot;Content&quot;), and Services provided by the Company are
						the property of the Company. You agree that that the Company owns all right, title, interest in and to the
						Content and that You will not use the Content for any unlawful or infringing purpose.
						<br />
						<br />
						Subject to this Agreement, the Company grants You a non-exclusive, limited, non- transferable, and revocable
						license to use the Content solely in connection with Your use of the App &amp; Website and Services. The
						Content may not be used for any other purpose, and this license terminates upon Your cessation of the use of
						the App &amp; Website or Services or at the termination of this Agreement.
						<br />
						<br />
						You agree not to reproduce or distribute the Content in any way, including electronically or via
						registration of any new trademarks, trade names, service marks, or Uniform Resource Locators (URLs), without
						express written permission from the Company.
					</span>
					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						4. USER ACCOUNTS AND OBLIGATIONS
					</span>
					<span>
						<br />
						<br />
						Some content on the App &amp; Website may only be accessed by the User by registering with Us. When You do
						so, You will choose a user identifier, which may be Your email address or another term, as well as a
						password. You may also be required to provide personal information, including, but not limited to, Your
						name. You are responsible for ensuring the accuracy of this information.
						<br />
						<br />
						You agree to change Your password from time to time. You also agree to keep Your user identifier and
						password confidential and that You will not share such identifying information with any third party. If You
						discover that your identifying information has been compromised, You agree to notify Us immediately in
						writing. Email notification will suffice.
						<br />
						<br />
						You are responsible for maintaining the safety and security of Your identifying information as well as
						keeping Us informed of any changes to Your identifying information. Providing false or inaccurate
						information or using the App &amp; Website or Services to further fraud or unlawful activity is grounds for
						immediate termination of this Agreement.
						<br />
						<br />
						You agree that You are solely responsible for all acts or omissions that occur under Your identifying
						information or password, including the content of any transmissions using the App &amp; Website or Service.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						5. ACCEPTABLE USE
					</span>
					<span>
						<br />
						<br />
						As a condition of Your use of the App &amp; Website or Services, You agree not to use the App &amp; Website
						or Service for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the
						App &amp; Website or Services in any way that could damage the App &amp; Website, Services, or general
						business of the Company.
						<br />
						<br />
						You further agree not to use the App &amp; Website or Services to:
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; a. Harass, abuse, or threaten others or otherwise violate any person&#39;s legal rights;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; b. Violate any intellectual property rights of the Company or any third party;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; c. Perpetrate any fraud;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; d. Engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; e. Attempt to gain unauthorized access to the App &amp; Website or Service, other
						accounts, computer systems or networks connected with the App &amp; Website or Service;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; f. Transmit or upload any content or material that contains viruses, trojan horses,
						ransomware, or other harmful or deleterious programs or software;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; g. Publish or distribute any obscene or defamatory material;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; h. Publish or distribute any material that incites violence, date, or discrimination
						towards any group;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; i. Unlawfully gather information about others, including email addresses;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; j. Interfere with another user&#39;s use and enjoyment of the App &amp; Website or
						Service or any similar App &amp; Website or Service.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						6. AFFILIATE MARKETING AND ADVERTISING
					</span>
					<span>
						<br />
						<br />
						The Company, through the App &amp; Website and Services, may engage in affiliate marketing whereby the
						Company receives a commission on or percentage of the sale of goods or services on or through the App &amp;
						Website. The Company may also accept advertising and sponsorships from commercial businesses or receive
						other forms of advertising compensation.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						7. THIRD PARTY LINKS
					</span>
					<span>
						<br />
						<br />
						You acknowledge that We may, from time to time, include links or references to other App &amp; Websites,
						other content, or other materials (&quot;Third Party Links&quot;), none of which are controlled by Us.
						<br />
						<br />
						Third Party Links are provided for Your information only and We do not make any representations, warranties,
						or guarantees as to the accuracy, completeness, performance, reliability, timeliness, quality, or
						suitability for a particular purpose of these Third-Party Links. We do not endorse, approve, or support
						these Third-Party Links.
						<br />
						<br />
						You use the Third-Party Links at Your own risk.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						8. SALE OF GOODS/SERVICES
					</span>

					<span>
						<br />
						<br />
						We may sell Goods or Services on the App &amp; Website. If this occurs, then some specific exclusions of
						liability will apply, as described in the &quot;Exclusion of Liability&quot; Clause.
						<br />
						<br />
						Please refer to Our additional terms and conditions for sale of goods and/or terms and conditions for sale
						of services as applicable.
						<br />
						<br />
						From time to time, the Company may post promotional offers for Goods and Services on the App &amp; Website.
						The frequency of such offers, as well as their terms and conditions are determined by the Company. The user
						is aware that the number of promotional offers for Goods and Services is limited.
						<br />
						<br />
						The Company does not guarantee or promise to Users that:
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; a. the purchase of any promotional Goods and Services on the App &amp; Website is any way
						beneficial for the User and/or third parties; and
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; b. the cost of promotional Goods and Services is necessarily lower than their usual cost
						on the App &amp; Website or on other third-party App &amp; Websites.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						9. EXCLUSION OF LIABILITY
					</span>
					<span>
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; a. The App &amp; Website and Service, and its Content, are provided for general
						information only and may change at any time without prior notice.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; b. You accept and acknowledge that the App &amp; Website, Service, Goods, or Services may
						contain mistakes, errors, and inaccuracies.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; c. Your use of the App &amp; Website, Content, and information or documentation that We
						may provide to You in connection with Your use of the Goods, Services, or Products including documentation,
						data, and information developed by Us or owned by Us, and other materials which may assist in Your use of
						Goods or Services or App &amp; Website (collectively, the &quot;Materials&quot;), is entirely at Your risk.
						It is Your responsibility to make sure that any Goods, Services, Materials, Content, or other information
						available through the App &amp; Website or Service suits Your particular purpose.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; d. Neither We, nor any third parties provide any guarantees or warranties regarding the
						accuracy, completeness, performance, reliability, timeliness, quality, merchantability, safety, legality, or
						suitability for a particular purpose of the App &amp; Website, Goods, or Services.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; e. To the maximum extent permitted by law, We hereby expressly exclude all warranties,
						guarantees, representations, or terms (whether express or implied) except for those expressly set out in
						these Agreements.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; f. To the maximum extent permitted by law, we hereby expressly exclude any liability in
						relation to the accuracy, completeness, performance, reliability, timeliness, quality, merchantability,
						safety, legality, or suitability for a particular purpose of the App &amp; Website, Goods, or Services.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; g. To the maximum extent permitted by law, we hereby expressly exclude any liability in
						relation to loss of data, interruption to Your business or any damages which are incidental to or arise from
						such loss of data or interruption to business.
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; h. To the maximum extent permitted by law, We will not be liable for any damage, loss,
						cost, or expense including legal costs and expenses, whether direct or indirect, incurred by You in
						connection with Your use of the App &amp; Website, Goods, or Services.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						10. PAYMENT/SHIPPING/DELIVERY
					</span>

					<span>
						<br />
						<br />
						You agree to ensure payment for any items You may purchase from Us, and You acknowledge and affirm that
						prices are subject to change. You agree to provide Us with a valid email and a valid billing information.
						When purchasing a physical good, You also agree to provide us with a valid shipping address. When purchasing
						a Good or Service, We reserve the right to reject or cancel an order for any reason, including errors or
						omissions in the information that You provide to us. If We do so after payment has been processed, We will
						issue a refund to You in the amount of the purchase price. We may also request additional information from
						You prior to confirming a sale, and We reserve the right to place any additional restrictions on the sale of
						any of Our products. You agree to ensure payment for any items You may purchase from Us, and You acknowledge
						and affirm that prices are subject to change. You agree to monitor Your method of payment.
						<br />
						<br />
						The Company accepts the following methods of payment through the App &amp; Website: Maya, GCash and
						accredited Credit Cards
						<br />
						<br />
						If payment will be by card, you must be fully entitled to use the card or account.
						<br />
						<br />
						Shipment costs and dates, if there is any, are subject to change and may be different from the costs and
						dates that You are quoted due to unforeseen circumstances.
						<br />
						<br />
						You acknowledge and accept that there are inherent risks with downloading any digital Goods. Please contact
						Us using the details at the end of this Agreement if you experience technical problems regarding delivery of
						digital Goods.
						<br />
						<br />
						We take no responsibility for Goods that are lost or damaged during delivery. If Goods are damaged during
						delivery, please contact Us using the details at the end of this Agreement. We may choose in Our sole
						discretion whether or not to replace Goods which are lost or damaged during delivery.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						11. ASSUMPTION OF RISK
					</span>

					<span>
						<br />
						<br />
						The App &amp; Website and Services are provided for communication purposes only. You acknowledge and agree
						that any information posted on Our App &amp; Website is not intended to be legal advice, medical advice, or
						financial advice, and no fiduciary relationship has been created between You and the Company.
						<br />
						<br />
						You further agree that Your purchase of any products on the App &amp; Website is at Your own risk. The
						Company does not assume responsibility or liability for any advice or other information given on the App
						&amp; Website.
						<br />
						<br />
						We shall not be liable for any damage to any computer, equipment, software, data, or other information
						caused by Your access or use of the App &amp; Website or Service. We shall likewise not be liable for any
						action of third parties.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						12. PRIVACY
					</span>
					<span>
						<br />
						<br />
						Through Your Use of the App &amp; Website and Services, You may provide Us with certain information. By
						using the App &amp; Website or the Services, You authorize the Company to use Your information in the
						Philippines and any other country where We may operate.
						<br />
						<br />
						We take Our privacy obligations very seriously. Please refer to Our privacy policy
						(https://sysnetph.wixsite.com/sysnet/privacy- policy) for further information about what information We
						collect, how We use it and store it, and Your rights in relation to it.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						13. COOKIES
					</span>
					<span>
						<br />
						<br />
						This App &amp; Website uses the following Cookies: None
					</span>
					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						14. INDEMNITY
					</span>
					<span>
						<br />
						<br />
						You agree to defend and indemnify the Company and any of its affiliated (if applicable) and hold Us harmless
						against and legal claims and demands, including reasonable attorney&#39;s fees, which may arise from or
						relate to Your use or misuse of the App &amp; Website or Services, Your breach of this Agreement, or Your
						conduct or actions.
						<br />
						<br />
						You agree that the Company shall be able to select its own legal counsel and may participate in its own
						defense, if the Company wishes.
					</span>
					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						15. OTHER ACTION
					</span>
					<span>
						<br />
						<br />
						We reserve the right to take any of the following actions in Our sole discretion:
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; a. Determine whether or not You have breached this Agreement;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; b. Record any correspondence that occurs in public sections of the App &amp; Website or
						Service;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; c. Review any allegations about breaches of these App &amp; Website or Service, and
						determine in Our sole discretion whether to take any action in response to those alleged breaches, including
						removal of any Content in relation to those alleged breaches;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; d. Determine in our sole discretion whether to terminate Your or another user&#39;s
						access to any particular section or sections of the App &amp; Website or Service.
					</span>
					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						16. SPAM POLICY
					</span>
					<span>
						<br />
						<br />
						You are strictly prohibited from using the App &amp; Website or any of the Company&#39;s Services for spam
						activities, including gathering email address and personal information from others or sending any mass
						commercial emails.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						17. REVERSE ENGINEERING &amp; SECURITY
					</span>

					<span>
						<br />
						<br />
						You agree not to undertake any of the following actions:
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; a. Reverse engineer, or attempt to reverse engineer or disassemble any code or software
						from or on the App &amp; Website or Services;
						<br />
						<br />
						&nbsp;&nbsp;&nbsp; b. Violate the security of the App &amp; Website or Services through any unauthorized
						access, circumvention of encryption or other security tools, data mining, or interference to any host, user,
						or network.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						18. MODIFICATION &amp; SERVICE INTERRUPTIONS
					</span>

					<span>
						<br />
						<br />
						The Company may, in Our sole discretion, vary, alter, amend, change, or update the App &amp; Website or
						Service and/or its Content at any time. The Company may need to interrupt Your access to the App &amp;
						Website to implement any change or to perform maintenance or emergency services on a scheduled or
						unscheduled basis. You agree that Your access to the App &amp; Website may be affected by unanticipated or
						unscheduled downtime, for any reason, but that the Company shall have no liability for any damage or loss
						caused as a result of such downtime.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						19. VARIATION OF TERMS
					</span>

					<span>
						<br />
						<br />
						The Company may, from time to time and at any time without notice to You, modify this Agreement. You agree
						that the Company has the right to modify this Agreement or revise anything contained herein. You further
						agree that all modifications to this Agreement and are in full force and effect immediately upon posting on
						the App &amp; Website and that the modifications or variations will replace any prior version of this
						Agreement, unless prior versions are specifically referred to or incorporated into the latest modification
						or variation of this Agreement.
						<br />
						<br />
						You agree to routinely monitor this Agreement and refer to the Effective date at the bottom part of this
						Agreement to note modifications or variations. You further agree to clear Your cache when doing so to avoid
						accessing an older version of this Agreement.
						<br />
						<br />
						Your continued use of the App &amp; Website after any modifications to this Agreement is a manifestation of
						Your continued assent to this Agreement.
						<br />
						<br />
						In the event that You fail to monitor any modifications to or variations of this Agreement, You agree that
						such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						20. ENTIRE AGREEMENT
					</span>

					<span>
						<br />
						<br />
						This Agreement constitutes the entire understanding between the Parties with respect to any and all use of
						this App &amp; Website. The Agreement supersedes and replaces all prior or contemporaneous agreements or
						understandings, written or oral, regarding the use of this App &amp; Website.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						21. APPLICABLE LAW
					</span>

					<span>
						<br />
						<br />
						This Agreement and Your use of the App &amp; Website and all non-contractual relationships arising out of
						Your use shall be governed and construed in accordance with the laws of the Philippines.
						<br />
						<br />
						In case of any dispute or litigation, the Parties agree to submit to the jurisdiction of the Philippines
						courts.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						22. ASSIGNMENT
					</span>

					<span>
						<br />
						<br />
						This Agreement, or the rights granted hereunder, may not be assigned, sold, leased, or otherwise transferred
						in whole or in part by You. Should this Agreement, or the rights granted hereunder, be assigned, sold,
						leased, or otherwise transferred by the Company, the rights and liabilities of the Company will bind and
						inure to any assignees, administrators, successors, and executors.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						23. SEVERABILITY
					</span>

					<span>
						<br />
						<br />
						If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent
						arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such
						condition, the remainder of this Agreement shall continue in full force.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						24. NO WAIVER
					</span>

					<span>
						<br />
						<br />
						Our failure to enforce any provision of this Agreement shall not constitute a waiver of any future
						enforcement of that provision or of any other provision.
						<br />
						<br />
						No waiver shall be deemed to have been made unless expressed in writing and signed by Us. Waiver of any part
						or sub-part of this Agreement will not constitute a waiver of any other part or sub-part or of the same part
						or sub-part on a future date.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						25. HEADINGS
					</span>

					<span>
						<br />
						<br />
						Headings of parts and sub-parts under this Agreement are for convenience and organization only. Headings
						shall not affect the meaning of any provisions of this Agreement.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						26. CONTACT US
					</span>

					<span>
						<br />
						<br />
						You can contact us about this Agreement using the following details: +63288111530 or call us through browser
						by clicking this link: &nbsp;
						<a
							href='https://opis.link/sysnet'
							target='_blank'>
							opis.link/sysnet
						</a>
						.
					</span>

					<span className='tcpp-header'>
						<br />
						<br />
						<br />
						27. EFFECTIVE DATE
					</span>

					<span>
						<br />
						<br />
						This Agreement will become effective on 1 January 2023.
						<br />
						<br />
					</span>
					{props.onWhatType !== "viewing" && (
						<span style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
							<Button
								variant='contained'
								onClick={() => {
									setIsTCPPChecked(true);
									props.onSubModalClose(false, undefined);
								}}
								style={{ width: "max-content" }}>
								I AGREE
							</Button>
							<Button
								variant='text'
								color='error'
								onClick={() => {
									setIsTCPPChecked(false);
									props.onSubModalClose(false, undefined);
								}}
								style={{ width: "min-content" }}>
								DISAGREE
							</Button>
						</span>
					)}
				</div>
			</motion.div>
		</div>
	);
};

export default SubModalTCPP;
