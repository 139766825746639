import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { motion } from "framer-motion";
import "../css/modal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography, Box, Alert, List, ListItem } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";

import TrySignUp from "../../../functions/TrySignUp";
import Reg_VerifyOTP from "../../../functions/Reg_VerifyOTP";
import EncryptData from "../../../functions/EncryptData";

import OTPField from "./subcomponents/OTPField";

import { renderTheme } from "../../../App";

const variants = {
	open: { opacity: 1, y: "-10%" },
	closed: { opacity: 0, y: "-100%" },
};

const ModalSignup = (props) => {
	const [OnWhatStep, setOnWhatStep] = useState("step1");
	const [ErrorVerify, setErrorVerify] = useState("");
	const [UserID, setUserID] = useState("");
	const [OTP, setOTP] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [Firstname, setFirstname] = useState("");
	const [Lastname, setLastname] = useState("");
	const [Address, setAddress] = useState("");
	const [Username, setUsername] = useState("");
	const [Email, setEmail] = useState("");
	const [MobileNumber, setMobileNumber] = useState("");
	const [PlateNumber, setPlateNumber] = useState("");
	const [VehicleBrand, setVehicleBrand] = useState("");
	const [VehicleModel, setVehicleModel] = useState("");

	const [RegistrationError, setRegistrationError] = useState("clear");
	const [FirstNameError, setFirstNameError] = useState("");
	const [LastNameError, setLastNameError] = useState("");
	const [MobileNumberError, setMobileNumberError] = useState("");
	const [EmailError, setEmailError] = useState("");
	const [IsLoading, setIsLoading] = useState("");

	const [CountTimer, setCountTimer] = useState({
		minutes: 0,
		seconds: 0,
	});
	const [isRunning, setIsRunning] = useState(false);

	// RENDER THEME
	const { RenderType, IsTCPPChecked, setIsTCPPChecked } = useContext(renderTheme);

	useEffect(() => {
		let timer;

		if (isRunning) {
			timer = setInterval(() => {
				if (CountTimer.minutes === 0 && CountTimer.seconds === 0) {
					clearInterval(timer);
					setIsRunning(false);
					// Handle timer completion (e.g., show a message, trigger an action)
				} else {
					setCountTimer((prevTime) => {
						if (prevTime.seconds === 0) {
							return {
								minutes: prevTime.minutes - 1,
								seconds: 59,
							};
						} else {
							return {
								minutes: prevTime.minutes,
								seconds: prevTime.seconds - 1,
							};
						}
					});
				}
			}, 1000);
		}

		// Clean up the interval on component unmount or when isRunning becomes false
		return () => clearInterval(timer);
	}, [CountTimer, isRunning]);

	const formatTime = (value) => (value < 10 ? `0${value}` : value);

	// const toggleResend = (event, type) => {
	// 	setIsLoading(type);
	// 	setErrorVerify("");
	// 	// props.onModalClose(true, "modal_loading");
	// 	let request = FP_SendOTP(BasicToken, Email);
	// 	request.then((res) => {
	// 		if (res.status === 200) {
	// 			setUserID(res.data[0].USER_ID);
	// 			setIsLoading("");
	// 			setResendDisabled(true);
	// 			setCountTimer({
	// 				minutes: 2,
	// 				seconds: 0,
	// 			});
	// 			setIsRunning(true);
	// 		}
	// 	});
	// };

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
		}, 250);
	};

	const validateName = (value) => {
		// const pattern = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
		// console.log('zxc', pattern.test(value))
		if (_.isEmpty(value.trim())) {
			return "empty_string";
		} else if (value.trim().length < 3) {
			return "below_min_string";
		}
		// else if (!pattern.test(value)) {
		// 	return ErrorMessage.ERROR_SPECIAL_CHAR_NUM;
		// }
		return "";
	};

	const validateEmail = (value) => {
		let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
		if (_.isEmpty(value.trim())) {
			return "empty_string";
		} else if (reg.test(value) === false) {
			return "invalid_email";
		}
		return "";
	};

	const validateNumber = (value) => {
		let reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
		if (_.isEmpty(value.trim())) {
			return "empty_string";
		} else if (reg.test(value) === false) {
			return "invalid_number";
		} else if (value.length < 11) {
			return "below_min_number";
		}
		return "";
	};

	const toggleSignUp = (event, type) => {
		setIsLoading(type);

		const fnError = validateName(Firstname);
		setFirstNameError(fnError);

		const lnError = validateName(Lastname);
		setLastNameError(lnError);

		const mobileNumberError = validateNumber(MobileNumber);
		setMobileNumberError(mobileNumberError);

		const emailError = validateEmail(Email);
		setEmailError(emailError);

		const noError = _.isEmpty(fnError) && _.isEmpty(lnError) && _.isEmpty(mobileNumberError) && _.isEmpty(emailError);
		console.log(noError);
		console.log(IsTCPPChecked);

		if (noError && IsTCPPChecked) {
			let request = TrySignUp(
				Firstname,
				Lastname,
				Address,
				MobileNumber,
				Email,
				Username,
				PlateNumber,
				VehicleBrand,
				VehicleModel
			);
			request.then((res) => {
				if (res.status === 200) {
					setIsLoading("");
					setUserID(res.data.user_id);
					setOnWhatStep("step2");
				} else {
					setIsLoading("");
				}
			});
		} else {
			if (!IsTCPPChecked) {
				props.onAlertVisibility(true, "Please accept Terms and Conditions and Privacy Policy", "error");
			}
			setIsLoading("");
		}

		// props.onModalClose(true, "modal_loading");
	};

	const toggleVerifyOTP = (event, type) => {
		setIsLoading(type);
		let request = Reg_VerifyOTP(UserID, OTP);
		request.then((res) => {
			if (res.status === 200) {
				setIsLoading("");
				setOnWhatStep("step3");
			} else {
				setIsLoading("");
			}
		});
	};

	useEffect(() => {
		const errors = {
			FN: FirstNameError,
			LN: LastNameError,
			MNum: MobileNumberError,
			Email: EmailError,
		};

		const filteredErrors = Object.keys(errors).reduce((acc, key) => {
			if (errors[key] !== "") {
				acc[key] = errors[key];
			}
			return acc;
		}, {});

		// Check if the filteredErrors object is empty
		if (Object.keys(filteredErrors).length > 0) {
			setRegistrationError(filteredErrors);
		} else {
			setRegistrationError(null); // or set an empty object, depending on your use case
		}

		console.log("FIRST_NAME ERROR:", FirstNameError);
		console.log("LAST_NAME ERROR:", LastNameError);
		console.log("MOBILE_NUMBER ERROR:", MobileNumberError);
		console.log("EMAIL ERROR:", EmailError);
	}, [FirstNameError, LastNameError, MobileNumberError, EmailError]);

	useEffect(() => {
		console.log("[RegERROR]:", RegistrationError);
	}, [RegistrationError]);

	useEffect(() => {
		setIsOpen(true);
	}, []);

	const handleFirstnameInput = (e) => {
		setFirstname(e.target.value);
	};

	const handleLastnameInput = (e) => {
		setLastname(e.target.value);
	};
	const handleAddressInput = (e) => {
		setAddress(e.target.value);
	};
	const handleEmailInput = (e) => {
		setEmail(e.target.value);
	};
	const handleMobileNumberInput = (e) => {
		setMobileNumber(e.target.value);
	};
	const handlePlateNumberInput = (e) => {
		setPlateNumber(e.target.value);
	};
	const handleVehicleBrandInput = (e) => {
		setVehicleBrand(e.target.value);
	};
	const handleVehicleModelInput = (e) => {
		setVehicleModel(e.target.value);
	};
	const handleUsernameInput = (e) => {
		setUsername(e.target.value);
	};

	return (
		<div id='modal_signup'>
			<motion.div
				className={OnWhatStep === "step1" ? "modal-card card-signup" : "modal-card card-otp"}
				// initial={{ opacity: 0, y: "-100%" }}
				// animate={{ x: 100 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-header'>
					<span className='header-blank'>&nbsp;</span>
					<span> {OnWhatStep === "step1" ? "Signup" : OnWhatStep === "step2" ? "Verify OTP" : "Signup Complete"}</span>
					<span className='header-close'>
						<CloseIcon
							className='modal-close-btn'
							onClick={() => {
								setIsTCPPChecked(false);
								toggleModalClose();
							}}
						/>
					</span>
				</div>
				{OnWhatStep === "step1" ? (
					<div className='modal-content content-signup'>
						{RegistrationError !== "clear" && RegistrationError !== null && (
							<Box
								sx={{
									marginTop: {
										xs: "144px",
										lg: "unset",
									},
									width: "100%",
									marginBottom: "16px",
									display: "flex",
									justifyContent: "center",
								}}>
								<Alert
									sx={{
										width: {
											xs: "100%",
											lg: "40%",
										},
										display: "flex",
										"& .MuiAlert-message": {
											flexDirection: "column",
										},
									}}
									severity='error'
									icon={false}>
									<Typography variant='subtitle2'>Error List:</Typography>
									<List
										sx={{
											listStyleType: "disc",
											listStylePosition: "inside",
											paddingX: "16px",
										}}>
										{RegistrationError.FN && (
											<ListItem sx={{ display: "list-item", padding: "unset" }}>
												{RegistrationError.FN === "empty_string"
													? "First name can't be left blank."
													: "First name already in use."}
											</ListItem>
										)}
										{RegistrationError.LN && (
											<ListItem sx={{ display: "list-item", padding: "unset" }}>
												{RegistrationError.LN === "empty_string"
													? "Last name can't be left blank."
													: "Last name already in use."}
											</ListItem>
										)}
										{RegistrationError.Email && (
											<ListItem sx={{ display: "list-item", padding: "unset" }}>
												{RegistrationError.Email === "empty_string"
													? "Email can't be left blank."
													: RegistrationError.Email === "invalid_email"
													? "Please set a valid Email Address"
													: "Email already in use."}
											</ListItem>
										)}
										{RegistrationError.MNum && (
											<ListItem sx={{ display: "list-item", padding: "unset" }}>
												{RegistrationError.MNum === "empty_string"
													? "Mobile Number can't be left blank."
													: RegistrationError.MNum === "invalid_number"
													? "Please set a valid Mobile Number"
													: "Mobile Number already in use."}
											</ListItem>
										)}
										<ListItem sx={{ display: "list-item", padding: "unset" }}>Plate number already exists.</ListItem>
									</List>
								</Alert>
							</Box>
						)}
						<div className='signup-form'>
							<div className='form-part'>
								<Typography
									variant='h6'
									sx={{ alignSelf: "flex-start" }}>
									Account Holder Details
								</Typography>
								<div className='form-input'>
									<label htmlFor='form_txt_firstname'>First Name</label>
									<input
										type='text'
										className='form-control'
										value={Firstname}
										onChange={handleFirstnameInput}
										id='form_txt_firstname'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_lastname'>Last Name</label>
									<input
										type='text'
										className='form-control'
										value={Lastname}
										onChange={handleLastnameInput}
										id='form_txt_lastname'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_address'>Address</label>
									<input
										type='text'
										className='form-control'
										value={Address}
										onChange={handleAddressInput}
										id='form_txt_address'
									/>
								</div>

								<div className='form-input'>
									<label htmlFor='form_txt_mobile_number'>Mobile Number</label>
									<input
										type='tel'
										className='form-control'
										value={MobileNumber}
										onChange={handleMobileNumberInput}
										id='form_txt_mobile_number'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_email'>Email</label>
									<input
										type='email'
										className='form-control'
										value={Email}
										onChange={handleEmailInput}
										id='form_txt_email'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_username'>Username</label>
									<input
										type='text'
										className='form-control'
										value={Username}
										onChange={handleUsernameInput}
										id='form_txt_username'
									/>
								</div>
							</div>

							<div className='form-part'>
								<Typography
									variant='h6'
									sx={{ alignSelf: "flex-start" }}>
									Vehicle Details
								</Typography>
								<div className='form-input'>
									<label htmlFor='form_txt_plate_number'>Plate Number</label>
									<input
										type='text'
										placeholder='ABZ 1234'
										className='form-control'
										value={PlateNumber}
										onChange={handlePlateNumberInput}
										id='form_txt_plate_number'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_vehicle_brand'>Brand</label>
									<input
										type='text'
										placeholder='e.g. BYD, Tesla, Ford'
										className='form-control'
										value={VehicleBrand}
										onChange={handleVehicleBrandInput}
										id='form_txt_vehicle_brand'
									/>
								</div>
								<div className='form-input'>
									<label htmlFor='form_txt_vehicle_model'>Model</label>
									<input
										type='text'
										placeholder='e.g. Sealion 6 DM-i, Y, 2024 Mustang Mach-e'
										className='form-control'
										value={VehicleModel}
										onChange={handleVehicleModelInput}
										id='form_txt_vehicle_model'
									/>
								</div>
								<div
									style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
									className='mt-5'>
									<FormControlLabel
										sx={{ fontSize: "8px" }}
										control={<Checkbox checked={IsTCPPChecked} />}
										onChange={() => {
											let wasChecked = false;
											if (IsTCPPChecked) {
												wasChecked = true;
												setIsTCPPChecked(false);
											} else {
												wasChecked = false;
											}
											if (wasChecked === false) {
												props.onSubModalVisibility(true, "submodal_terms_condition_privacy_policy");
											} else {
												props.onSubModalVisibility(false, undefined);
											}
										}}
										label='Terms and Conditions / Privacy Policy'
									/>
									<button
										className='my-2 btn btn-primary'
										type='button'
										onClick={(event) => toggleSignUp(event, "signup1")}>
										{IsLoading === "signup1" ? (
											<CircularProgress
												color='inherit'
												size={16}
											/>
										) : (
											"Sign Up"
										)}
									</button>
								</div>
								<div
									className='addon-btn-group'
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Typography
										component={"caption"}
										sx={{ whiteSpace: "nowrap" }}>
										Already have an account?
									</Typography>
									<Button
										style={{ textTransform: "none", color: "#ff8f00" }}
										onClick={() => {
											setIsTCPPChecked(false);
											props.onModalClose(true, "modal_login");
										}}>
										Log In
									</Button>
								</div>
							</div>
						</div>
					</div>
				) : OnWhatStep === "step2" ? (
					<div className='modal-content content-signup-otp'>
						<OTPField
							otp={OTP}
							setOtp={setOTP}
						/>
						{ErrorVerify && (
							<FormHelperText sx={{ color: "red", marginTop: "0.5rem" }}>
								{ErrorVerify === "expired"
									? "OTP Expired."
									: ErrorVerify === "incorrect"
									? "Incorrect OTP."
									: "Maximum of 3 attempts has been reached. Try resending OTP."}
							</FormHelperText>
						)}
						<div className='mt-2'>
							<button
								className='my-2 btn btn-primary'
								type='button'
								onClick={(event) => toggleVerifyOTP((event, "signup2"))}>
								{IsLoading === "signup2" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : (
									"Verify OTP"
								)}
							</button>
						</div>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Button
								style={{ textTransform: "none", color: "#ff8f00" }}
								// onClick={(event) => toggleResend(event, "resend")}
								disabled={isRunning}>
								{IsLoading === "resend" ? (
									<CircularProgress
										color='inherit'
										size={16}
									/>
								) : isRunning ? (
									`Resend (${formatTime(CountTimer.minutes)}:${formatTime(CountTimer.seconds)})`
								) : (
									"Resend"
								)}
							</Button>
						</div>
					</div>
				) : (
					<div className='modal-content content-signup-complete'>
						<Typography variant='subtitle1'>Account created and is now active. Try logging in your account.</Typography>
						<Button
							className='mt-3'
							onClick={() => props.onModalClose(true, "modal_login")}>
							Go To Login
						</Button>
					</div>
				)}
			</motion.div>
		</div>
	);
};

export default ModalSignup;
