import CryptoJS from 'crypto-js';

const DecryptData = (name) => {
    let SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    const encrypted = localStorage.getItem(name);
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    
    return JSON.parse(decrypted);
}

export default DecryptData;