import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import FilterOption from "./subcomponents/FilterOption";
import "../css/modal.css";
import TryFilterLocation from "../../../functions/TryFilterLocation";
import Checkbox from "@mui/material/Checkbox";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { renderTheme } from "../../../App";

import GetFilterOptions from "../../../functions/GetFilterOptions";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const variants = {
	open: { scale: 1 },
	closed: { scale: 0 },
};

const ModalChooseFilter = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [FilterOptions, setFilterOptions] = useState();
	const [ResultCount, setResultCount] = useState();
	const [SelectedFilters, setSelectedFilters] = useState();
	const [FilterArray, setFilterArray] = useState();
	const [FilteredData, setFilteredData] = useState();
	const [IsFetching, setIsFetching] = useState();
	const [IsSavedFilterLoaded, setIsSavedFilterLoaded] = useState();
	const [IsAdvanceVisible, setIsAdvanceVisible] = useState(false);
	const [IsCleared, setIsCleared] = useState(false);

	// RENDER THEME
	const { UserPosition, toggleActiveFilters, ActiveFilters } = useContext(renderTheme);

	let filterLoad = null;

	const toggleModalClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			props.onModalClose(false, "");
			props.onSetSelected(true);
		}, 250);
	};

	const toggleAdvanceVisible = () => {
		if (IsAdvanceVisible) {
			setIsAdvanceVisible(false);
		} else {
			setIsAdvanceVisible(true);
		}
	};

	const handleShow = () => {
		props.onSetFilter(true, FilteredData);
		props.onModalClose(false, "");
		toggleActiveFilters(SelectedFilters);
	};

	const toggleFilterSelected = (key, code, action) => {
		const updatedObject = { ...SelectedFilters };
		// Get the array corresponding to the key
		let newArray = updatedObject[key] || [];

		// Perform add or remove action
		if (code === "ALL") {
			newArray = [];
			if (action === "add") {
				FilterOptions.map((item) => {
					if (item.key === key) {
						let filterGroup = item.value;
						filterGroup.map((data) => {
							newArray.push(data.code);
						});
					}
				});
			}
		} else {
			if (action === "add") {
				newArray.push(code);
			} else {
				const index = newArray.indexOf(code);
				if (index !== -1) {
					newArray.splice(index, 1);
				}
			}
		}

		// Update the state with the modified object
		updatedObject[key] = newArray;
		setSelectedFilters(updatedObject);
	};

	const toggleClearFilter = () => {
		const updatedObject = { ...SelectedFilters };
		if (FilterOptions !== undefined) {
			FilterOptions.map((data) => {
				updatedObject[data.key] = [];
			});
		}

		setSelectedFilters(updatedObject);
		toggleActiveFilters(updatedObject);
		FilterQuery(updatedObject, "clear");
	};

	useEffect(() => {
		if (IsCleared) {
			props.onSetFilter(false, FilteredData);
			setIsCleared(false);
		}
	}, [IsCleared]);

	useEffect(() => {
		let request = GetFilterOptions(process.env.REACT_APP_API_KEY);
		request.then((res) => {
			var keyValues = Object.entries(res.data).map(([key, value]) => ({ key, value }));
			setFilterOptions(keyValues);
		});
		setIsOpen(true);
	}, []);

	useEffect(() => {
		console.log("[ACTIVE_FILTERS]:", ActiveFilters);
		if (ActiveFilters !== undefined && SelectedFilters !== undefined && !IsSavedFilterLoaded) {
			for (const key in ActiveFilters) {
				if (Array.isArray(ActiveFilters[key])) {
					console.log("X");
					if (ActiveFilters[key].length > 0) {
						ActiveFilters[key].map((subdata) => {
							toggleFilterSelected(key, subdata, "add");
						});
					}
				}
			}
			setIsSavedFilterLoaded(true);
		}
	}, [ActiveFilters, IsSavedFilterLoaded]);

	useEffect(() => {
		const updatedObject = { ...SelectedFilters };
		if (FilterOptions !== undefined) {
			FilterOptions.map((data) => {
				updatedObject[data.key] = [];
			});
		}

		setSelectedFilters(updatedObject);
		setIsSavedFilterLoaded(false);
	}, [FilterOptions]);

	useEffect(() => {
		console.log("[SELECTED_FILTERS]:", SelectedFilters);
		if (SelectedFilters !== undefined && !isObjectEmpty(SelectedFilters)) {
			if (filterLoad !== null) {
				clearTimeout(filterLoad);
			}
			setIsFetching(true);
			filterLoad = setTimeout(() => {
				FilterQuery(SelectedFilters);
			}, 1000);
		}
	}, [SelectedFilters]);

	function isObjectEmpty(obj) {
		return Object.keys(obj).length === 0;
	}

	const FilterQuery = (List, type = undefined) => {
		const toFetchObj = { ...List };

		Object.entries(List).map(([key, array]) => {
			const stringArray = "[" + array.map((value) => `"${value}"`).join(", ") + "]";
			toFetchObj[key] = array.length < 1 ? null : stringArray;
		});
		let request = TryFilterLocation(
			props.onIfLoggedIn ? "with_favorite" : "default",
			UserPosition.lat,
			UserPosition.lng,
			toFetchObj
		);
		request.then((res) => {
			setResultCount(
				props.onIfLoggedIn ? res.data.filter_locations_with_favorites.length : res.data.filter_locations.length
			);
			setFilteredData(res.data);
			setIsFetching(false);
			clearTimeout(filterLoad);
			if (type === "clear") {
				setIsCleared(true);
			}
		});
	};

	return (
		<div id='modal_choosefilter'>
			<motion.div
				className='modal-card card-choosefilter'
				initial={{ scale: 0 }}
				animate={isOpen ? "open" : "closed"}
				variants={variants}
				transition={{ duration: 0.3 }}>
				<div className='modal-header'>
					<span>
						<CloseIcon
							className='modal-close-btn'
							onClick={toggleModalClose}
						/>
					</span>
					<span>Filter</span>
					<span className='header-blank'>&nbsp;</span>
				</div>
				<div className='modal-content content-choosefilter container'>
					{FilterOptions !== undefined &&
						FilterOptions.map((item, index) => {
							return (
								<FilterOption
									onFilterLabel={item.key}
									onOptionList={item.value}
									onItemSelect={toggleFilterSelected}
									onFilterSelected={SelectedFilters[item.key]}
									onIfBasic={index === 0 || index === 1 || index === 2 ? true : false}
									onIsAdvanceVisible={IsAdvanceVisible}
								/>
							);
						})}
					<div className='d-flex align-items-center justify-content-center my-1'>
						<Button
							onClick={toggleAdvanceVisible}
							variant='text'
							size='small'
							sx={{ textTransform: "none" }}
							className='d-flex flex-row align-items-center justify-content-center'>
							{IsAdvanceVisible ? "Hide" : "Show"} Advance Filters
							<ExpandCircleDownIcon
								fontSize='medium'
								color='primary'
								sx={{
									transform: IsAdvanceVisible && "rotate(180deg)",
								}}
							/>
						</Button>
					</div>
				</div>
				<div className='modal-footer'>
					<Button
						id='btn_clear_all'
						onClick={() => toggleClearFilter()}>
						Clear All
					</Button>
					<span>&nbsp;</span>
					<Button
						variant='contained'
						onClick={handleShow}
						disabled={IsFetching}>
						{IsFetching ? (
							<CircularProgress size='1.5rem' />
						) : (
							<>
								Show <span>{ResultCount > 0 && "(" + ResultCount + ")"}</span>
							</>
						)}
					</Button>
				</div>
			</motion.div>
		</div>
	);
};

export default ModalChooseFilter;
