import LoadingGIF from "../../../assets/ParkNcharge_Loading.gif";

const ModalLoading = (props) =>{
    return(
        <div id="modal_loading">
            <div className="modal-card card-loading">
                <img src={LoadingGIF} id="pnc_loading"/>
            </div>
        </div>
    )
}

export default ModalLoading;