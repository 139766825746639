import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import "../../css/submodal.css";

function createData(ref_num, description, amount, date) {
  return { ref_num, description, amount, date };
}

const rows = [
  createData("U000000001",'TOPUP - Gcash',  "+100.00", '02-23-2023'),
  createData("U000000002",'TOPUP - Gcash',  "+1500.00", '02-9-2023'),
  createData("U000000003",'CHARGE - Rufino',  "-100.00", '02-8-2023'),
  createData("U000000004",'CHARGE - Dunno',  "-200.00", '01-29-2023'),
  createData("U000000005",'TOPUP - Gcash',  "+500.00", '01-28-2023'),
];

const TransHistoryTable = () =>  {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650}} className='table-body' aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Reference Number</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.ref_num}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.ref_num}
              </TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">{row.amount}</TableCell>
              <TableCell align="left">{row.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TransHistoryTable;