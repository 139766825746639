import { useEffect, useState } from "react";
import "../../css/modal.css";
import OptionItem from "./OptionItem";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import { motion } from "framer-motion";

function valuetext(value) {
	return `${value}km`;
}

const minDistance = 10;

const marks = [
	{
		value: 0,
		label: "0",
	},
	{
		value: 50,
		label: "50",
	},
	{
		value: 100,
		label: "100",
	},
];

const FilterOption = (props) => {
	const [TotalNumberSelected, setTotalNumberSelected] = useState(0);
	const [FilterList, setFilterList] = useState();
	const [PriceRange1, setPriceRange1] = useState(0);
	const [PriceRange2, setPriceRange2] = useState(10);
	const [IsAllSelected, setIsAllSelected] = useState(false);
	// const [PriceValue, setPriceValue] = useState([20, 37]);

	const handlePriceChange = (event, newValue, activeThumb) => {
		if (!Array.isArray(newValue)) {
			return;
		}

		console.log(activeThumb);
		if (activeThumb === 0) {
			setPriceRange1(Math.min(newValue[0], PriceRange2 - minDistance));
		} else {
			setPriceRange2(Math.max(newValue[1], PriceRange1 + minDistance));
		}
	};

	const toggleAllSelection = (data) => {
		setIsAllSelected(data);
	};

	const handleInputChange = (e) => {
		let id = e.target.id;
		let value = e.target.value;

		if (id === "price_range1") {
			setPriceRange1(Math.min(value, PriceRange2 - minDistance));
		} else {
			setPriceRange2(Math.max(value, PriceRange1 + minDistance));
		}
	};

	const toggleSetSelect = (item, AllOption = undefined) => {
		if (item === "ALL") {
			if (AllOption === "SELECT") {
				props.onItemSelect(props.onFilterLabel, "ALL", "add");
				// props.onOptionList.map((data) => {
				// 	props.onItemSelect(props.onFilterLabel, data.code, "add");
				// });
				setIsAllSelected(true);
				setTotalNumberSelected(props.onOptionList.length);
			} else {
				props.onItemSelect(props.onFilterLabel, "ALL", "remove");
				setIsAllSelected(false);
				setTotalNumberSelected(0);
			}
		} else {
			const isExists = props.onFilterSelected.includes(item);
			if (isExists) {
				setTotalNumberSelected(TotalNumberSelected - 1);
				props.onItemSelect(props.onFilterLabel, item, "remove");
			} else {
				setTotalNumberSelected(TotalNumberSelected + 1);
				props.onItemSelect(props.onFilterLabel, item, "add");
			}
		}
	};

	function formatString(str) {
		return str
			.split("_")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}

	function isObjectEmpty(obj) {
		return Object.keys(obj).length === 0;
	}

	useEffect(() => {
		if (TotalNumberSelected === props.onOptionList.length) {
			setIsAllSelected(true);
		}
	}, [TotalNumberSelected]);

	useEffect(() => {
		if (props.onFilterSelected !== undefined) {
			if (props.onOptionList.length === props.onFilterSelected.length) {
				setIsAllSelected(true);
			} else {
				setIsAllSelected(false);
			}
		}
	}, [props]);

	// useEffect(() => {
	// 	console.log(isObjectEmpty(props.onFilterSelected));
	// }, [props.onFilterSelected]);

	return (
		<>
			{(props.onIsAdvanceVisible || props.onIfBasic) && (
				<motion.div
					initial={{ y: -5, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.75 }}
					className='filter-option'
					style={{ display: props.onIsAdvanceVisible || (!props.onIfBasic && "none") }}>
					<span className='option-type'>{formatString(props.onFilterLabel)}</span>
					<div className='option-list gap-2'>
						{FilterList && props.onOptionList === "Price_range" ? (
							<>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										width: "100%",
										justifyContent: "space-around",
										alignItems: "center",
									}}>
									<input
										type='number'
										className='form-control'
										style={{ width: "45%" }}
										id={"price_range1"}
										value={PriceRange1}
										onInput={handleInputChange}
									/>
									<span style={{ fontWeight: "bold" }}>-</span>
									<input
										type='number'
										className='form-control'
										style={{ width: "45%" }}
										id={"price_range2"}
										value={PriceRange2}
										max={100}
										onInput={handleInputChange}
									/>
								</div>
								<Slider
									getAriaLabel={() => "Minimum distance"}
									value={[PriceRange1, PriceRange2]}
									onChange={handlePriceChange}
									valueLabelDisplay='auto'
									getAriaValueText={valuetext}
									disableSwap
									marks={marks}
								/>
							</>
						) : (
							<>
								<OptionItem
									onFilterSelected={props.onFilterSelected}
									onOptionItemName={"All"}
									onItemCode={"ALL"}
									onIfAllIsSelected={IsAllSelected}
									onSetSelected={toggleSetSelect}
								/>
								{props.onOptionList.map((item, idx) => {
									return (
										<OptionItem
											key={idx}
											onFilterSelected={props.onFilterSelected}
											onOptionItemName={formatString(item.description)}
											onOptionItemSelection={
												props.onFilterSelected !== undefined &&
												props.onFilterSelected !== null &&
												props.onFilterSelected.includes(item.code)
													? true
													: false
											}
											onItemCode={item.code}
											onIndex={idx}
											onIfAllIsSelected={IsAllSelected}
											onTotalNumberSelected={TotalNumberSelected}
											onSetSelected={(res, item) => toggleSetSelect(res, item)}
											onToggleAll={toggleAllSelection}
										/>
									);
								})}
							</>
						)}
					</div>
				</motion.div>
			)}
		</>
	);
};

export default FilterOption;
