const TryLogin = async (username, password, setLoading) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_KEY);

    var raw = JSON.stringify({
        username: username,
        password: password,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/login/api/auth/v1/login`,
            requestOptions
        );

        if (!response.ok) {
            // Handle specific error status codes
            if (response.status === 401) {
                return ({
                    status: "Forbidden",
                    username: username,
                    password: password
                });
            } else {
                console.log(`Server error: ${response.status}`);
                setLoading(false);
            }
        } else {
            return response;
        }
    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
};

export default TryLogin;
