import ModalLogin from "./components/modal_Login";
import ModalSideMenu from "./components/modal_SideMenu";
import ModalMerchant from "./components/modal_Merchant";
import ModalChooseFilter from "./components/modal_ChooseFilter";
import ModalLoading from "./components/modal_Loading";
import ModalQRScanner from "./components/modal_QRScanner";
import ModalSignup from "./components/modal_Signup";
import ModalForgotPassword from "./components/modal_ForgotPassword";
import ModalLocationPrompt from "./components/modal_LocationPrompt";
import ModalChargingReservationDetails from "./components/modal_ChargingReservationDetails";
// import ModalBookingStatus from "./components/modal_BookingStatus";
import "./css/modal.css";

const Modal = (props) => {
	return (
		<div id='system_modal'>
			{
				props.onWhatModal === "modal_login" ? (
					<ModalLogin
						onModalClose={props.onModalVisibility}
						onIfLoggedIn={props.onIfLoggedIn}
						onIsLoggedIn={props.onIsLoggedIn}
						onAlertVisibility={props.onAlertVisibility}
					/>
				) : props.onWhatModal === "modal_sidemenu" ? (
					<ModalSideMenu
						onModalClose={props.onModalVisibility}
						onSubModalVisibility={props.onSubModalVisibility}
						onToLogOut={props.onIsLoggedIn}
						onAlertVisibility={props.onAlertVisibility}
						onWhatSubModal={props.onWhatSubModal}
					/>
				) : props.onWhatModal === "modal_merchant" ? (
					<ModalMerchant
						onModalClose={props.onModalVisibility}
						onIfLoggedIn={props.onIfLoggedIn}
						onSubModalVisibility={props.onSubModalVisibility}
						onMerchantToLocate={props.onMerchantToLocate}
						onSelectedMerchant={props.onSelectedMerchant}
						onIsSubmodalVisible={props.onIsSubmodalVisible}
						onWhatSubModal={props.onWhatSubModal}
					/>
				) : props.onWhatModal === "modal_loading" ? (
					<ModalLoading onModalClose={props.onModalVisibility} />
				) : props.onWhatModal === "modal_choosefilter" ? (
					<ModalChooseFilter
						onModalClose={props.onModalVisibility}
						onSetSelected={props.onSetSelected}
						onSetFilter={props.onSetFilter}
						onIfLoggedIn={props.onIfLoggedIn}
					/>
				) : props.onWhatModal === "modal_signup" ? (
					<ModalSignup
						onModalClose={props.onModalVisibility}
						onAlertVisibility={props.onAlertVisibility}
						onSubModalVisibility={props.onSubModalVisibility}
					/>
				) : props.onWhatModal === "modal_forgotpassword" ? (
					<ModalForgotPassword
						onModalClose={props.onModalVisibility}
						onAlertVisibility={props.onAlertVisibility}
					/>
				) : props.onWhatModal === "modal_location" ? (
					<ModalLocationPrompt
						onModalClose={props.onModalVisibility}
						onAlertVisibility={props.onAlertVisibility}
						onSetAsCurrent={props.onSetAsCurrent}
						onUserLocation={props.onUserLocation}
						onSetUserLocation={props.onSetUserLocation}
					/>
				) : props.onWhatModal === "modal_charging_reservation_details" ? (
					<ModalChargingReservationDetails
						onModalClose={props.onModalVisibility}
						onAlertVisibility={props.onAlertVisibility}
					/>
				) : (
					<ModalQRScanner
						onModalClose={props.onModalVisibility}
						onAlertVisibility={props.onAlertVisibility}
						onSubModalVisibility={props.onSubModalVisibility}
					/>
				)
				// <ModalBookingStatus onModalClose={props.onModalVisibility} onIfLoggedIn={props.onIfLoggedIn} />
			}
		</div>
	);
};

export default Modal;
