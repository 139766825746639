import React, { useEffect, useState, createContext } from "react";
import { useLocation } from 'react-router-dom';


import Home from "./pages/Home/Home";
import Modal from "./pages/Modals/Modal";
import SubModal from "./pages/SubModals/SubModal";
import MessageAlert from "./pages/Alerts/Alert";

import GetToken from "./functions/GetToken";
import GetReservationList from "./functions/GetReservationList";
import DecryptData from "./functions/DecryptData";
import TokenCheck from "./functions/TokenCheck";
import CancelExpiredReservations from "./functions/CancelExpiredReservations";
import GetUserDetails from "./functions/GetUserDetails";
import GcashPay from "./functions/GcashPay";
import MayaPay from "./functions/MayaPay";
import VerifyTransaction from "./functions/VerifyTransaction";
import GetTransactionHistory from "./functions/GetTransactionHistory";

import ChargerSocket from "./functions/Socket";

// import { socket } from "./socket";

export const renderTheme = createContext({});

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [UserDetails, setUserDetails] = useState();
  const [UserPosition, setUserPosition] = useState();
  const [CurrentPage, setCurrentPage] = useState("Home");
  const [IsLoggedIn, setIsLoggedIn] = useState();
  const [ModalVisible, setModalVisible] = useState(false);
  const [SubModalVisible, setSubModalVisible] = useState(false);
  const [AlertVisible, setAlertVisible] = useState(false);
  const [WhatModal, setWhatModal] = useState("");
  const [WhatSubModal, setWhatSubModal] = useState();
  const [PaymentMethod, setPaymentMethod] = useState("");
  const [WhatAlertMessage, setWhatAlertMessage] = useState("");
  const [AlertSeverity, setAlertSeverity] = useState("");
  const [NotSelected, setNotSelected] = useState(false);
  const [MerchantToLocate, setMerchantToLocate] = useState(false);
  const [RenderType, setRenderType] = useState();
  const [IsTCPPChecked, setIsTCPPChecked] = useState(false);
  const [MerchantStationSlotData, setMerchantStationSlotData] = useState();
  const [SlotReservationData, setSlotReservationData] = useState();
  const [SelectedMerchantDetails, setSelectedMerchantDetails] = useState();
  const [IsFilterEnabled, setIsFilterEnabled] = useState();
  const [FilteredData, setFilteredData] = useState();
  const [ActiveFilters, setActiveFilters] = useState();
  const [ChargingReservationDetails, setChargingReservationDetails] = useState();
  const [LiveChargingDetails, setLiveChargingDetails] = useState();
  const [ChargeStep, setChargeStep] = useState();
  const [PaymentResult, setPaymentResult] = useState();
  const [EvseUID, setEvseUID] = useState();


  const [RefreshSlot, setRefreshSlot] = useState(() => { });
  const [ToExpand, setToExpand] = useState(() => { });

  const [SubModalType, setSubModalType] = useState("")
  const [IsSubModalClicked, setIsSubModalClicked] = useState(false);
  const [IsRefreshed, setIsRefreshed] = useState();

  const location = useLocation();

  // SOCKET STATE
  const [StatusChange, setStatusChange] = useState();
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(socket !== null && socket.connected)


  const toggleStatusChange = (status) => {
    setStatusChange(status);
  }

  const toggleSubmodalClicked = (bool) => {
    setIsSubModalClicked(bool);
  }

  const handleSelectedMerchantDetails = (details) => {
    setSelectedMerchantDetails(details);
  }

  const toggleFilterActivity = (status, data) => {
    setIsFilterEnabled(status);
    setFilteredData(IsLoggedIn ? data.filter_locations_with_favorites : data.filter_locations);
  }

  const toggleActiveFilters = (data) => {
    setActiveFilters(data);
  }

  const toggleCurrentPage = (whatPage) => {
    setCurrentPage(whatPage)
  }

  const toggleNotSelected = (bool) => {
    setNotSelected(bool);
  }

  const toggleLogin = (event, res) => {
    setIsLoggedIn(res)
  }

  const toggleModal = (visibility, whatModal) => {
    setModalVisible(visibility);
    setWhatModal(whatModal);
  }

  const toggleSubModal = (visibility, whatSubModal, merchantStationData = undefined, reservationData = undefined, chargerFunction = undefined, toggleExpand = undefined, merchantID = undefined, addon = undefined) => {
    setSubModalVisible(visibility);
    setWhatSubModal(whatSubModal);
    if (addon !== undefined) {
      setSubModalType(addon)
    }

    if (merchantStationData !== undefined) {
      console.log(merchantStationData)
      setMerchantStationSlotData(merchantStationData);
    }

    if (reservationData !== undefined) {
      setSlotReservationData(reservationData)
    }

    // panel, evc_id, merchantID
    if (chargerFunction !== undefined && toggleExpand !== undefined && merchantID !== undefined && merchantStationData !== undefined) {
      setToExpand(() => (data) => toggleExpand(data));
      setRefreshSlot((data1, data2, data3, data4) => chargerFunction(data1, data2, data3, data4));
    }

  }

  const toggleAlert = (visibility, whatAlertMessage, AlertSeverity) => {
    setAlertVisible(visibility);
    setWhatAlertMessage(whatAlertMessage);
    setAlertSeverity(AlertSeverity);
  }

  const toggleMerchantLocate = (bool, coordinates) => {
    setMerchantToLocate({ status: bool, location: coordinates });
  }

  const togglePaymentResult = (result) => {
    setPaymentResult(result);
  }

  const toggleUserDetails = (details) => {
    setUserDetails(details);
  }

  useEffect(() => {
    console.log(ActiveFilters)
  }, [ActiveFilters])

  useEffect(() => {
    console.log("Component Mounted");
    let request = CancelExpiredReservations();
    request.then((res) => {
      console.log(res);
    });

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 768); // Adjust the threshold as needed
    };

    const handleMessage = (res) => {
      if (res.type === "APP_TO_WEB_LOCATION") {
        // alert(res.data.latitude)
        setRenderType("application");
        setIsLoggedIn(true);
        setUserPosition({
          lat: res.data.latitude,
          lng: res.data.longitude
        })
      }

    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    window.addEventListener('message', (event) => {
      if (event) {
        if (event.origin !== window.origin) {
          return;
        }

        if (window.ReactNativeWebView) {
          handleMessage(event.data)
          window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
        }
      }
    });

    let renderType = location.pathname;
    if (renderType !== "/webview") {
      if (IsLoggedIn === undefined) {
        const saved_refreshToken = localStorage.getItem('refresh_token');

        if (saved_refreshToken !== null) {
          let refreshToken = DecryptData("refresh_token");
          let request = TokenCheck(refreshToken);
          request.then((data) => {
            console.log(data)
            if (data.status === "PASSED") {
              setIsLoggedIn(true);
            } else {
              setIsLoggedIn(false);
            }
          })

        } else {
          setIsLoggedIn(false);
        }
        setRenderType("website");
      }
    }

  }, [])

  useEffect(() => {
    // Extract the transactionID from the query string
    const searchParams = new URLSearchParams(location.search);
    const transactionID = searchParams.get('transactionID');

    // Check if transactionID exists
    if (transactionID) {
      let request = VerifyTransaction(transactionID);
      request.then((res) => {
        setTimeout(() => {
          toggleModal(true, "modal_sidemenu");
          toggleSubModal(true, "submodal_payment_method");
          setPaymentResult(res.data.topup_status);
          // Get the current URL without the search parameter
          const urlWithoutParam = window.location.origin + window.location.pathname;

          // Use history.pushState() to replace the current URL without the search parameter
          window.history.pushState({}, document.title, urlWithoutParam);

        }, 500)
      })
    }
  }, [location.search]); // Execute only when location.search changes

  useEffect(() => {
    if (IsLoggedIn) {
      setTimeout(() => {
        const saved_refreshToken = localStorage.getItem("refresh_token");
        if (saved_refreshToken !== null) {
          let refreshToken = DecryptData("refresh_token");
          let request = TokenCheck(refreshToken);
          request.then((data) => {
            console.log(data)
            if (data.status === "PASSED") {
              let savedToken = localStorage.getItem("access_token");

              if (savedToken !== null) {
                let reqToken;
                if (data.action === "REFRESHED") {
                  reqToken = data.access_token;
                } else {
                  reqToken = DecryptData("access_token");
                }
                let request = GetReservationList(reqToken);
                request.then((res) => {
                  console.log("[Reservation_List]:", res);
                  if (res !== undefined) {
                    setChargingReservationDetails(res.data);
                  }
                })

                let userReq = GetUserDetails(reqToken);
                userReq.then((res) => {
                  setUserDetails(res.data)
                })
              }
            }
          });
        }
      }, 500)

    }

  }, [IsLoggedIn, StatusChange])

  useEffect(() => {
    console.log("[CHARGING_RESERVATION_DETAILS]:", ChargingReservationDetails)
    if (ChargingReservationDetails !== undefined && ChargingReservationDetails.reservations !== undefined && ChargingReservationDetails.reservations.length > 0) {
      if (ChargingReservationDetails.is_charging === 1) {
        console.log("[Y]:", ChargingReservationDetails)
        let tmp_socket = ChargerSocket(ChargingReservationDetails.evse_uid);
        setSocket(tmp_socket)
        setStatusChange("USER_CHARGING");
        if (ChargeStep === undefined) {
          setChargeStep("INITIALIZATION")
        }

      } else {
        console.log("[X]:", ChargingReservationDetails)
        if (ChargingReservationDetails.reservations.length > 0) {
          if (ChargingReservationDetails.reservations[0].status === "VIRTUAL_ACTIVE") {
            console.log("[Z]:", ChargingReservationDetails.reservations[0].evse_uid)
            let tmp_socket = ChargerSocket(ChargingReservationDetails.reservations[0].evse_uid, ChargingReservationDetails.reservations[0].connector_id);
            setSocket(tmp_socket)
            setStatusChange("USER_CHARGING");
          } else {
            setStatusChange("USER_RESERVATION")
          }
        }

      }
    } else {
      console.log("[Z]:", ChargingReservationDetails)
    }


  }, [ChargingReservationDetails])

  useEffect(() => {
    console.log("SELECTED MERCHANT: ", SelectedMerchantDetails)
  }, [SelectedMerchantDetails])

  useEffect(() => {
    if (StatusChange === "USER_CHARGING") {
      if (socket !== null) {
        socket.connect();
        console.log(ChargeStep)
        if (ChargeStep === undefined) {
          if (ChargingReservationDetails.is_charging !== 1) {
            setChargeStep("WAITING");
          }
        }
        setIsConnected(true);
      }
    }

    if (socket !== null && isConnected) {
      function handleChargerStatus(data) {
        if (ChargeStep !== "CHARGING") {
          setChargeStep("WAITING");
        }

        if (data.status === "UNPLUGGED-ONLINE" || data.status === "ONLINE") {
          setChargeStep("COMPLETE");
          setStatusChange();
          setTimeout(() => {
            socket.disconnect();
            setIsConnected(false);
          }, 1000)

        }

        if (data.status === "PLUGGED-IN" || data.status === "CHARGING") {
          setChargeStep("PLUGGED-IN");
          setTimeout(() => {
            if (data.status === "CHARGING") {
              setChargeStep("INITIALIZATION");
            }
          }, 3000)
        }

        console.log("[Charger_Status]:", data);
      };

      function handleChargingStatus(data) {
        if (setChargeStep !== "CHARGING") {
          setChargeStep("CHARGING");
        }
        setLiveChargingDetails(data);
        console.log("[Charging_Status]:", data);
      }

      function handleChargingStopDetails(data) {
        setChargeStep("OVERSTAYING")
        setStatusChange("USER_OVERSTAYING");
        setLiveChargingDetails(data);
        console.log("[Charging_Stop_Details]:", data);
      }

      console.log(socket)
      console.log("STATUS_CHANGE:", StatusChange)
      socket.on("charger-status", handleChargerStatus);
      socket.on("charging-status", handleChargingStatus);
      socket.on("charging-stop-details", handleChargingStopDetails)
      // Clean up: remove event listener when StatusChange changes
      return () => {
        socket.off("charger-status", handleChargerStatus);
        socket.off("charging-status", handleChargingStatus)
        socket.off("charging-stop-details", handleChargingStopDetails)
      };
    }
  }, [isConnected, socket, StatusChange])

  return (
    // <SlideComponent />
    <renderTheme.Provider value={{ isMobile, RenderType, IsTCPPChecked, setIsTCPPChecked, StatusChange, toggleStatusChange, ChargingReservationDetails, setChargingReservationDetails, UserPosition, toggleActiveFilters, ActiveFilters, toggleSubmodalClicked, LiveChargingDetails, UserDetails, ChargeStep, setChargeStep, AlertVisible, PaymentResult, togglePaymentResult }}>
      {AlertVisible && <MessageAlert onAlertVisibility={toggleAlert} alertDetails={{ message: WhatAlertMessage, severity: AlertSeverity }} />}

      {SubModalVisible && <SubModal onWhatSubModal={WhatSubModal} onModalVisibility={toggleModal} onSubModalVisibility={toggleSubModal} onPaymentMethod={PaymentMethod} onAlertVisibility={toggleAlert} onIsLoggedIn={toggleLogin} onWhatSlotData={MerchantStationSlotData} onWhatReservationData={SlotReservationData} onExpand={ToExpand} onRefreshSlot={RefreshSlot} onWhatType={SubModalType} />}

      {ModalVisible && <Modal onWhatModal={WhatModal} onWhatSubModal={WhatSubModal} onModalVisibility={toggleModal} onSubModalVisibility={toggleSubModal} onIfLoggedIn={IsLoggedIn} onIsLoggedIn={toggleLogin} onAlertVisibility={toggleAlert} onSetSelected={toggleNotSelected} onMerchantToLocate={toggleMerchantLocate} onSetAsCurrent={toggleCurrentPage} onUserLocation={UserPosition} onSetUserLocation={setUserPosition} onSelectedMerchant={SelectedMerchantDetails} onSetFilter={toggleFilterActivity} onIsSubmodalVisible={SubModalVisible} />}

      {CurrentPage === "Home" ? <Home onWhatModal={WhatModal} onSetAsCurrent={toggleCurrentPage} onIfLoggedIn={IsLoggedIn} onModalVisibility={toggleModal} onAlertVisibility={toggleAlert} onSetSelected={toggleNotSelected} onIfSelected={NotSelected} onMerchantToLocate={toggleMerchantLocate} onIsMerchantToLocate={MerchantToLocate} onUserLocation={UserPosition} onSetUserLocation={setUserPosition} onMerchantSelection={handleSelectedMerchantDetails} onIsFilterEnabled={IsFilterEnabled} onFilteredData={FilteredData} /> : <></>}

    </renderTheme.Provider>
  );
}

export default App;
